import React from 'react';
import { motion } from 'framer-motion';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Heading, Text, CTAButton } from '@nuvocargo/nuvo-styleguide';
import { Link } from 'react-router-dom';

import { useWindowSize } from 'core/hooks';
import Layout from 'components/common/layout/unauthenticated';
import { app } from 'routes/paths';

import { isMobile as checkIsMobile, getHeadingVariant } from '../utils';

export default function NewRoutesSuccess() {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = checkIsMobile(width);

  return (
    <Layout>
      <SuccessWrapper data-testid="signup-success">
        <motion.div
          initial={{
            opacity: 0,
            x: -200,
          }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.2, ease: [0.25, 1, 0.5, 1] }}>
          <Iconography name="truck" classname="truck" />
        </motion.div>
        <Heading
          component="div"
          variant={getHeadingVariant(width)}
          color="green"
          styles={{
            root: {
              marginTop: isMobile ? '55px' : '42px',
            },
          }}>
          {t('new-route-success-title')}
        </Heading>
        <Text
          color="steel"
          size={isMobile ? 'extraSmall' : 'large'}
          styles={{
            extraSmall: {
              margin: '16px 24px 24px 24px',
              lineHeight: '16px',
            },
            large: {
              margin: '18px 80px 24px 80px',
            },
          }}>
          {t('new-route-success-subtitle')}
        </Text>

        <CTAButton
          as={Link}
          isLink
          to={{
            pathname: app.shipments.root,
          }}
          styles={{
            primary: {
              width: '212px',
            },
          }}>
          <Text color="white">{t('back-to-shipments')}</Text>
        </CTAButton>
      </SuccessWrapper>
    </Layout>
  );
}

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  height: 90vh;
  margin: 0 auto;
  max-width: 584px;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  h1 {
    font-weight: normal;
  }
  p {
    max-width: 415px;
  }
  svg {
    width: 150px;
    height: 70px;
  }
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        svg {
          height: 50px;
        }
      }
    `}
`;
