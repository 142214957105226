import { useMemo } from 'react';
import {
  DropdownCell,
  TextCell,
} from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';

import {
  buildStrapsOptions,
  centsToDollars,
  formatMoney,
  isFumigationAccessorial,
  isStrapsAccessorial,
  yesNoDropdownOptions,
} from 'core/utils';

import { COMMODITIES_SPECIAL_REQUIREMENTS } from './utils';

const useSpecialHandlingColumns = ({ accessorials, t }) => {
  const yesNoOptions = useMemo(() => yesNoDropdownOptions(t), [t]);
  const strapsOptions = useMemo(() => buildStrapsOptions(t), [t]);

  // This was a gross copy/paste from internal-ui
  const fumigationObject = accessorials.find(
    ({ slug }) => slug === COMMODITIES_SPECIAL_REQUIREMENTS.FUMIGATION
  );
  const strapsObject = accessorials.find(
    ({ slug }) => slug === COMMODITIES_SPECIAL_REQUIREMENTS.STRAPS
  );

  const fumigationPrice = formatMoney(
    centsToDollars(fumigationObject?.schema_args?.[0])
  );
  const fumigationCellValue = `${fumigationPrice} per leg`;

  const strapsPrice = formatMoney(
    centsToDollars(strapsObject?.schema_args?.[1])
  );
  const strapsMeasure = strapsObject?.schema_args?.[0] ?? 'strap';
  const strapsCellValue = `${strapsPrice} per ${strapsMeasure}`;

  const getRequiredColumnOptions = row => {
    if (isFumigationAccessorial(row)) {
      return yesNoOptions;
    }
    if (isStrapsAccessorial(row)) {
      return strapsOptions;
    }

    return [];
  };

  const columns = useMemo(
    () => [
      {
        Header: t('general-type-text-label'),
        accessor: 'type',
        type: 'text',
        fontColor: 'darkGrey',
      },
      {
        Header: t('general-cost-text-label'),
        accessor: 'cost',
        Cell: ({ value, row, ...rest }) => {
          const { id } = row;
          return (
            <TextCell
              fontColor="steel"
              text={
                id === COMMODITIES_SPECIAL_REQUIREMENTS.FUMIGATION
                  ? fumigationCellValue
                  : strapsCellValue
              }
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-required-text-label'),
        accessor: 'required',
        Cell: ({ row, ...rest }) => {
          const options = getRequiredColumnOptions(row);
          return <DropdownCell options={options} {...rest} />;
        },
      },
    ],
    [t, fumigationCellValue, getRequiredColumnOptions, strapsCellValue]
  );

  return columns;
};

export const useSpecialHandlingTable = ({ accessorials, t }) => {
  const columns = useSpecialHandlingColumns({ accessorials, t });

  return {
    columns,
  };
};
