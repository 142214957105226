import React, { useCallback } from 'react';
import { useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { isEmpty } from 'ramda';

import { Flex, MovingTruck } from 'components/generic/kit';

import { StyledTable, TableWrapper } from './styles';

/**
 * Generic styled table that uses `react-table` under-the-hood.
 * Additional props are forwarded to a `styled.table` component.
 * @param { Object} props
 * @param { import("react-table").Column[] } props.columns Column definition using React Table convention
 * @param { Object[] } props.data Array of RouteType (see Nuvocargo's GraphQL API schema)
 * @param { React.FC } props.EmptyComponent Component used for empty state
 * @param { Boolean } props.isLoading Whether the table's data is loading or not
 * @param { (index: number) => void } props.onRowHover Callback fn for on row hover triggered
 * @see https://react-table.tanstack.com/docs/api/useTable
 */
export function GenericTable({
  columns,
  data,
  EmptyComponent,
  isLoading,
  onRowHover,
  path = '',
  isRowClickable = false,
  ...props
}) {
  const history = useHistory();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const onRowHoverHandler = useCallback(
    rowIndex => {
      onRowHover(data[rowIndex]);
    },
    [data]
  );

  const onRowClickHandler = rowPath => {
    if (isRowClickable) {
      history.push(rowPath);
    }
  };

  if (!isLoading && isEmpty(data) && Boolean(EmptyComponent)) {
    return <EmptyComponent />;
  }

  if (isLoading) {
    return (
      <Flex
        styles={{
          root: {
            width: '100%',
            height: '50vh',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}>
        <MovingTruck />
      </Flex>
    );
  }

  return (
    <TableWrapper>
      <StyledTable
        {...getTableProps()}
        {...props}
        data-testid={`${props['data-testid'] || 'generic-table'}`}>
        <thead data-testid="generic-table-thead">
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} data-testid="generic-table-tbody">
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                key={row.id}
                className={isRowClickable ? 'clickable' : ''}
                {...row.getRowProps()}
                onMouseEnter={() => onRowHoverHandler(row.index)}
                onClick={() =>
                  onRowClickHandler(`${path}/${data[row.index].id}`)
                }>
                {row.cells.map(cell => (
                  <td
                    key={`${cell.column.Cell}/${row.id}`}
                    className={clsx({
                      'clickable-cell': cell.column.isLink,
                      'shrink-to-content': cell.column.shrinkToContent,
                    })}
                    {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
}
