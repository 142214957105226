import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridColumn,
  CTAButton,
  Label,
  Icon,
  Heading,
  Link,
  Text,
  Modal,
} from '@nuvocargo/nuvo-styleguide';
import { TextField, Dropdown } from '@nuvocargo/nuvo-styleguide/forms/native';
import styled from 'styled-components';

import { useForgotPassword } from 'screens/password/forgot/hooks';
import { useUser } from 'context/user';
import { ErrorMessage } from 'components/generic/form';
import { openBanner } from 'core/hooks/use-banner';
import Layout from 'components/common/layout/authenticated';

import { useLanguageUpdate } from './hooks';

export default function Account() {
  const [isResetPasswordModal, setResetPasswordModal] = React.useState(false);
  const openResetPasswordModal = () => {
    setResetPasswordModal(true);
  };

  const closeResetPasswordModal = () => setResetPasswordModal(false);
  const { sendEmail, isLoading, error } = useForgotPassword({
    onSuccessCallback: () =>
      openBanner({
        key: 'modal-forgot-success',
        children: (
          <div style={{ display: 'flex' }}>
            <Icon name="check" color="white" />
            <Heading
              variant="h6"
              styles={{ root: { marginLeft: '10px' } }}
              color="white">
              {t('password-forgot-email-sent')}
            </Heading>
          </div>
        ),
        timeout: 3000,
      }),
  });

  const { t } = useTranslation();
  const {
    changeLanguage,
    languageValue,
    isLoadingLanguageUpdate,
    onSelectChange,
  } = useLanguageUpdate();

  const languages = [
    { label: 'Español', value: 'es', icon: { name: 'mexico' } },
    { label: 'English', value: 'en', icon: { name: 'usa' } },
  ];

  const { currentUser } = useUser();

  const getUsedLanguageOption = () => {
    let lng = 'en';
    if (languageValue && languageValue.startsWith('es')) {
      lng = 'es';
    }

    return languages.find(l => l.value === lng);
  };

  return (
    <Layout paddingTop="40px">
      <GridColumn startMd={5} md={4} sm={4}>
        <HeadingWrapper>
          <Heading variant="h3">{t('account')}</Heading>
        </HeadingWrapper>
        {currentUser && (
          <TextField
            placeholder="Enter your first name"
            value={currentUser.email}
            label={t('email')}
            disabled
          />
        )}
        <PasswordWrapper>
          <TextField
            label={t('password')}
            name="password"
            type="password"
            value="********"
            disabled
          />
          <div>
            <Link onClick={openResetPasswordModal}>
              {t('reset-password-title')}
            </Link>
            <Modal
              isOpen={isResetPasswordModal}
              closeModal={closeResetPasswordModal}
              styles={{
                root: {
                  width: '432px',
                },
              }}>
              <Text
                styles={{
                  regular: {
                    textAlign: 'center',
                  },
                }}>
                {t('reset-password-confirmation')} {currentUser?.email}.{' '}
                {t('are-you-sure')}?
              </Text>
              {error?.data?.error && (
                <ErrorMessage backgroundColor="taupe">
                  {error.data.error}
                </ErrorMessage>
              )}
              <div
                style={{
                  marginTop: '16px',
                }}>
                <CTAButton
                  onClick={async () => {
                    await sendEmail({ email: currentUser.email });
                    closeResetPasswordModal();
                  }}
                  disabled={isLoading}>
                  {t('send-reset-link-btn')}
                </CTAButton>
              </div>
            </Modal>
          </div>
        </PasswordWrapper>
        <Dropdown
          label={t('language-input-label')}
          options={languages}
          value={getUsedLanguageOption()}
          onChange={onSelectChange}
          styles={{
            root: {
              marginTop: '20px',
            },
          }}
        />

        <BtnWrapper>
          <CTAButton
            disable={isLoadingLanguageUpdate}
            onClick={changeLanguage(languageValue)}>
            {t('save-changes-btn')}
          </CTAButton>
        </BtnWrapper>
      </GridColumn>
    </Layout>
  );
}

export const BtnWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 54px;

  > button {
    margin: 0 auto;
  }
`;

const PasswordWrapper = styled.div`
  margin-top: 24px;

  & > * + * {
    margin-top: 16px;
  }
`;

export const StyledLabel = styled(Label)`
  margin-top: 24px;
  display: block;

  > div {
    margin-top: 8px;
  }
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  margin-bottom: 85px;
  text-align: center;
`;
