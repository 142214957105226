import { curry, isEmpty, path, isNil } from 'ramda';

import { formatISODate } from 'core/utils/date-fns';
import { formatUSD } from 'core/utils/lib';

export const getCurrency = invoice =>
  invoice?.balance?.total?.USD > 0 ? 'USD' : 'MXN';

export const getEmptyTableCopy = ({
  invoices,
  rawInvoices,
  filterStatus,
  t,
}) => {
  if (isEmpty(rawInvoices)) {
    return {
      title: t('all-invoices-empty-title'),
      description: t('all-invoices-empty-description'),
    };
  }

  if (isEmpty(invoices) && filterStatus === 'paid') {
    return {
      title: t('paid-invoices-empty-title'),
      description: t('paid-invoices-empty-description'),
    };
  }

  return {
    title: t('outstanding-invoices-empty-title'),
    description: t('outstanding-invoices-empty-description'),
  };
};

export const formatCSVModel = (item, isPaid) => {
  return [
    item.fileName,
    item.customerReference,
    getCsvDate(item.createdAt),
    getCsvDate(item.dueDate),
    getCsvDate(item.pickupDate),
    isPaid ? formatISODate(item.paidDate) : '-',
    formatAmount(item, ['balance', 'total']),
    formatAmount(item, ['balance', 'paid']),
    formatAmount(item, ['balance', 'due']),
    item.payTo[0],
  ];
};

const getCsvDate = date => (date !== null ? formatISODate(date) : '');

export const formatPrice = price => `$${formatUSD(price)}`;

const formatAmount = (item, values) => {
  const currency = getCurrency(item);
  const amount = path(values.concat(currency), item);

  return isNil(amount) ? '-' : formatPrice(amount);
};

export const shouldPayTo = curry((option = '', invoice) => {
  if (!invoice) {
    return false;
  }
  return invoice.payTo.some((bank = '') =>
    bank.toLowerCase().includes(option.toLowerCase())
  );
});
