import styled, { css } from 'styled-components';

const OnlyDesktop = styled.div`
  ${({ theme }) => css`
    @media only screen and ${theme.device.sm} {
      display: none;
    }
  `}
`;

export default OnlyDesktop;
