import uniqid from 'uniqid';
import { countryShortNameToFlag } from '@nuvocargo/nuvo-styleguide/lib/utils';

import { debouncePromise } from 'core/utils/lib';
import { getGeolocations } from 'core/api/geolocations';

const KEY_CODES = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CONTROL: 17,
  ALT: 18,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  LETTER_V: 86,
  META: 93,
  ZERO: 48,
  NINE: 57,
  NUMPAD_ZERO: 96,
  NUMPAD_NINE: 105,
};

const MAX_POSTAL_CODE_LENGTH = 5;

const keyIsBetweenZeroAndNine = keyCode =>
  (keyCode >= KEY_CODES.ZERO && keyCode <= KEY_CODES.NINE) ||
  (keyCode >= KEY_CODES.NUMPAD_ZERO && keyCode <= KEY_CODES.NUMPAD_NINE);

const keyIsValidForAutocompleteInput = keyCode => {
  switch (keyCode) {
    case KEY_CODES.BACKSPACE:
    case KEY_CODES.TAB:
    case KEY_CODES.ENTER:
    case KEY_CODES.SHIFT:
    case KEY_CODES.CONTROL:
    case KEY_CODES.ALT:
    case KEY_CODES.LEFT:
    case KEY_CODES.UP:
    case KEY_CODES.RIGHT:
    case KEY_CODES.DOWN:
    case KEY_CODES.META: {
      return true;
    }

    default: {
      return false;
    }
  }
};

const keyIsUserPasting = e =>
  (e.keyCode === KEY_CODES.LETTER_V && e.ctrlKey) ||
  // Mac pastes with CMD + V which is meta for browsers
  (e.keyCode === KEY_CODES.LETTER_V && e.metaKey);

export const handlePostalCodeAutoCompleteKeyDown = e => {
  if (
    (keyIsBetweenZeroAndNine(e.keyCode) &&
      e.target.value.length < MAX_POSTAL_CODE_LENGTH) ||
    keyIsValidForAutocompleteInput(e.keyCode) ||
    keyIsUserPasting(e)
  ) {
    return;
  }
  e.preventDefault();
};

export const addRoute = (values, index, arrayHelpers) => {
  const currentIndexHasAnOriginValue = !!values.routes[index]?.origin;
  const nextIndexDoesNotExist = !values.routes[index + 1];
  const currentIndexHasLoadsPerMonth = !!values.routes[index]?.loadsPerMonth;

  // We automatically add another set of fields
  // once people fill the current one out
  if (
    currentIndexHasAnOriginValue &&
    nextIndexDoesNotExist &&
    currentIndexHasLoadsPerMonth
  ) {
    arrayHelpers.push({
      id: uniqid(),
      origin: '',
      destination: '',
      loadsPerMonth: '',
    });
  }
};

const debouncedGetGeolocations = debouncePromise(getGeolocations, 350);

export const autocompleteRouteLocations = zipCode =>
  debouncedGetGeolocations({
    filterName: zipCode,
    filterType: 'zipCode',
  }).then(res =>
    res.records.map(
      ({ name: zipCode, parents: { city, state, country }, id }) => ({
        id,
        icon: {
          name: countryShortNameToFlag(country),
        },
        label: `${zipCode}, ${city}, ${state}`,
      })
    )
  );
