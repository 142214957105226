import React from 'react';
import styled from 'styled-components';
import { Heading, Text, CTAButton } from '@nuvocargo/nuvo-styleguide';

/** @component */
const EmptyStateCard = ({ header, subHeader, cta, onClick }) => {
  return (
    <>
      <StyledCard>
        <Container>
          <Heading variant="h4" color="green" component="div">
            {header}
          </Heading>
          <Text size="regular">{subHeader}</Text>
          {cta ? <CTAButton onClick={onClick}>{cta}</CTAButton> : null}
        </Container>
      </StyledCard>
    </>
  );
};

export default EmptyStateCard;

const StyledCard = styled.div`
  background: #f3f1ee;
  border: 1px solid #f0f2f2;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  margin: 5px 0;
  padding: 10px;
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 108px 0px;
  text-align: center;

  > h4:first-child {
    margin-bottom: 20px;
  }

  > button {
    margin: 24px auto 0;
  }

  @media (min-width: ${({ theme }) => theme.mediaSizes.tablet}) {
    width: 400px;

    > span {
      margin: 0 50px;
      display: block;
    }
  }
`;
