import React from 'react';
import { Text } from '@nuvocargo/nuvo-styleguide';

import { CountryFlag } from 'components/common/app/CountryFlag';
import { Stack } from 'components/generic/kit';

/**
 *
 * @param { Object } props
 * @param { 'mexico' | 'usa' | 'canada' } props.country Supported country name
 * @param { string } props.city City name
 */
export function CityWithCountryFlag({ country, city }) {
  return (
    <Stack axis="horizontal" center>
      <CountryFlag
        alt={`${country} flag icon`}
        country={country}
        marginRight="8px"
        strict
        width="16px"
      />
      <Text size="large">{city}</Text>
    </Stack>
  );
}
