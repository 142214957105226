import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text, Eyebrow } from '@nuvocargo/nuvo-styleguide';

import { formatPrice } from '../utils';

export default function BillingPayment({
  overdueBalance,
  totalBalance,
  bankDetails = [],
  currency,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex h-full flex-col items-center gap-10 md:flex-row md:gap-2">
        <div className="flex flex-col justify-center gap-6 md:basis-2/3 md:gap-12 md:pr-11 ">
          <Heading variant="h3">{t('make-a-payment')}</Heading>
          <Text color="darkGrey">{t('make-a-payment-description')}</Text>

          <div className="flex flex-col gap-5">
            <div className="flex  justify-between gap-2 pr-20 ">
              <Text size="large">
                {t('overdue-balance')} ({currency})
              </Text>
              <Text
                size="large"
                color={overdueBalance > 0 ? 'lightRed' : 'steel'}>
                {formatPrice(overdueBalance)}
              </Text>
            </div>
            <div className="flex  justify-between gap-2 pr-20 ">
              <Text size="large">
                {t('total-balance')} ({currency})
              </Text>
              <Text size="large" color="blue">
                {formatPrice(totalBalance)}
              </Text>
            </div>
          </div>
        </div>
        <div className=" rounded bg-nuvo-cream px-6 py-6 md:basis-1/3 md:py-12   ">
          <div className="flex flex-col gap-5 md:gap-10">
            <Heading variant="h6" color="green">
              {t('bank-details')}
            </Heading>
            <div className="flex flex-col gap-4 ">
              {bankDetails.map(({ label, value }) => (
                <div className="flex flex-col gap-2" key={label}>
                  <Eyebrow size="small" color="steel" text={label} />
                  <Text
                    styles={{
                      regular: {
                        whiteSpace: 'break-spaces',
                      },
                    }}
                    color="darkGrey">
                    {value}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
