import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { CTAButton, Eyebrow, Heading, Icon } from '@nuvocargo/nuvo-styleguide';
import Modal from '@nuvocargo/nuvo-styleguide/Modals/Modal';
import { countryShortNameToFlag } from '@nuvocargo/nuvo-styleguide/lib/utils';

import { ACTIONS, STEPS } from '../../../Facilities';

import { validationSchema, getInitialValues } from './components/data';
import { FormWrapper } from './components/styles';
import Contact from './components/Contact';
import Details from './components/Details';
import Procedures from './components/Procedures';

const PlaceForm = ({ placeData, dispatch, facilityType }) => {
  const { zipCode: zipCodeObject } = placeData;
  const {
    name: zipCode,
    parents: { city, country },
  } = zipCodeObject;
  const { t } = useTranslation();

  const facility = facilityType === 'origin' ? 'pickup' : 'dropoff';

  return (
    <>
      <div>
        <Eyebrow text={t(`${facility}-details`)} size="small" color="steel" />
      </div>
      <div className="flex gap-2">
        <Icon name={countryShortNameToFlag(country)} />
        <Heading variant="h5" color="green">
          {city}, {zipCode}
        </Heading>
      </div>
      <Formik
        initialValues={getInitialValues({ placeData, t })}
        validationSchema={validationSchema(t)}
        onSubmit={data => {
          dispatch({
            type: ACTIONS.SET_CONFIRMATION_STEP,
            payload: {
              modalData: {
                ...placeData,
                ...data,
              },
              from: STEPS.PLACE_FORM,
            },
          });
        }}>
        <FormWrapper>
          <Details />
          <Contact />
          <Procedures facilityType={facility} />

          <Modal.Footer>
            <CTAButton type="submit">
              {t('facility-form-button-continue')}
            </CTAButton>
          </Modal.Footer>
        </FormWrapper>
      </Formik>
    </>
  );
};

export default PlaceForm;
