export const links = [
  {
    to: '/shipments',
    title: 'Shipments',
  },
  {
    to: '/insights',
    title: 'Insights',
    labelText: 'New!',
  },
  // {
  //   to: "/account",
  //   title: "Account"
  // }
];
