import { gql } from 'graphql-request';

export const UPDATE_USER_MUTATION = gql`
  mutation customerui_updateUser($id: ID!, $userInput: UserInput!) {
    updateUser(input: { id: $id, attributes: $userInput }) {
      user {
        id
        language
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation customerui_updateUserProfile(
    $id: ID!
    $updateUserProfileInput: UserProfileInput!
  ) {
    updateUserProfile(
      input: { userId: $id, attributes: $updateUserProfileInput }
    ) {
      user {
        id
        language
      }
    }
  }
`;
