import * as flag from '@nuvocargo/nuvo-styleguide/Icons/Flags';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { formatShortDate, formatTime } from 'core/utils/date-fns';

const getFlagSrcByCountry = country => {
  if (country === 'mx') {
    return flag.mexico;
  }

  if (country === 'us') {
    return flag.usa;
  }
};

const getFlagAltByCountry = country => {
  if (country === 'mx') {
    return 'Mexico';
  }

  if (country === 'us') {
    return 'USA';
  }
};

export function RouteOverview(props) {
  const { isLoading, shipment = {} } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <>
        <div className="col-span-1 flex h-20 w-2/3 animate-pulse flex-col gap-2 lg:col-span-12">
          <div className="h-10 w-2/3 rounded bg-nuvo-gray-dark/25"></div>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2 h-2 rounded bg-nuvo-gray-dark/25"></div>
            <div className="col-span-1 h-2 rounded bg-nuvo-gray-dark/25"></div>
          </div>
        </div>

        <div className="col-span-1 grid h-52 animate-pulse grid-cols-4 gap-8 rounded bg-nuvo-gray-dark/25 p-8 lg:col-span-7" />
      </>
    );
  }

  return (
    <>
      <div className="col-span-1 flex flex-col gap-1 lg:col-span-12">
        <h3 className="text-2xl text-nuvo-green lg:text-4xl ">
          {t('single-shipment-route-title', { id: shipment.id })}
        </h3>
        <p className="text-sm text-nuvo-gray-dark/[66] lg:text-lg ">
          {t('single-shipment-route-subtitle', {
            origin: shipment.origin?.zipCode?.parents?.city,
            destination: shipment.destination?.zipCode?.parents?.city,
            reference: shipment.customerReference,
          })}
        </p>
      </div>

      {/* Route Overview */}
      <div className="col-span-1 grid auto-rows-min grid-cols-4 place-content-center gap-8 rounded bg-nuvo-white p-8 lg:col-span-7">
        <div className="col-span-4 flex items-center justify-between ">
          <h5 className="text-lg text-nuvo-green lg:text-2xl">
            {t('single-shipment-route-overview-title')}
          </h5>
          <Link
            to={`/routes/${shipment.route.id}`}
            className="text-base text-nuvo-forest hover:text-nuvo-green active:text-nuvo-green-dark ">
            {t('single-shipment-route-overview-route-link')}
          </Link>
        </div>

        {['pickup', 'delivery'].map(type => {
          const place = type === 'pickup' ? 'origin' : 'destination';
          const isETA = !!!shipment[type].actualDatetime;
          const displayDate =
            shipment[type].actualDatetime || shipment[type].estimatedDatetime;

          return (
            <div
              key={type}
              className="col-span-4 flex flex-col items-start gap-1 md:col-span-2 ">
              <p className="text-sm uppercase leading-tight tracking-wider text-nuvo-steel">
                {t(`single-shipment-route-overview-${type}-title`)}
              </p>
              <div className="col-span-4 flex items-center gap-1">
                <img
                  src={getFlagSrcByCountry(
                    shipment[place].zipCode.parents.country
                  )}
                  alt={getFlagAltByCountry(
                    shipment[place].zipCode.parents.country
                  )}
                />
                <span>{shipment[place].name}</span>
              </div>
              <p className="flex-1 text-nuvo-steel">
                {shipment[place].addressLine1}
                {shipment[place].addressLine2}
              </p>

              <div className="mt-2 flex max-w-full flex-row items-center justify-center gap-1 rounded border border-nuvo-gray-dark/10 py-1 px-2 ">
                <span className="inline-flex h-3 w-3 rounded-full bg-nuvo-forest " />
                {displayDate && (
                  <p className="h-6 truncate">
                    {isETA && t(`single-shipment-route-overview-date-eta`)}{' '}
                    {t(`single-shipment-route-overview-date`, {
                      date: formatShortDate(displayDate),
                      time: formatTime(displayDate),
                    })}
                    {shipment[type].wasEstimatedDatetimeUpdated &&
                      ` ${t('single-shipment-route-overview-date-updated')}`}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
