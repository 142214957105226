import React from 'react';
import { Icon } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

export function TrashButton(props) {
  const { disabled } = props;

  return (
    <StyledButton {...props}>
      <Icon name="trash" color={disabled ? 'darkGray' : 'forest'} />
    </StyledButton>
  );
}

const StyledButton = styled.button`
  height: 50px;
  flex: none;
  aspect-ratio: 1;
  padding: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    transform: scale(1.1);
  }
`;
StyledButton.displayName = 'StyledButton';
