import styled from 'styled-components';

export const PickupDatesTable = styled.div`
display: flex;
flex-direction: column;
margin: 36px 0;
max-width: '100%',
row-gap: '24px',
`;
PickupDatesTable.displayName = 'PickupDatesTable';

export const PickupDatesTableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ece9e7;

  &:first-child {
    padding-bottom: 18px;
  }

  > * {
    flex: 1;
  }
`;
PickupDatesTableRow.displayName = 'PickupDatesTableRow';

export const PickupDatesTableFooter = styled.footer`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin: 8px 16px 0 0;
`;
PickupDatesTableFooter.displayName = 'PickupDatesTableFooter';

export const PickupDatesTableAction = styled.button`
  flex: none;
  padding: 8px 2px 1px;
  transition: all 0.1s ease-out;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &:hover {
    border-color: ${({ theme }) => theme.colors.forest};
  }
`;
PickupDatesTableAction.displayName = 'PickupDatesTableAction';

export const FooterActionsWrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
`;
FooterActionsWrapper.displayName = 'FooterActionsWrapper';

export const CustomCalendarPopperContainer = styled.div`
  width: 320px;
  position: absolute;
`;
CustomCalendarPopperContainer.displayName = 'CustomCalendarPopperContainer';

export const RouteDetailsFixedContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkCream};
  box-sizing: border-box;
  height: 100vh;
  padding: 80px 35px;
  position: sticky;
  top: 0;
  width: calc(100% + 50px);
`;
RouteDetailsFixedContainer.displayName = 'RouteDetailsFixedContainer';

export const RouteDetailsSmallContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkCream};
  box-sizing: border-box;
  padding: 80px 35px;
`;
RouteDetailsSmallContainer.displayName = 'RouteDetailsSmallContainer';
