import React from 'react';
import styled from 'styled-components';

/**
 * Wrapper component for two rows: `top` and `bottom`.
 * Additional props are forwarded to the wrapper `div`.
 * @param { Object } props
 * @param { JSX.Element } props.bottom Component to render at the bottom
 * @param { JSX.Element } props.top Component to render at the top
 */
export function TwoRowDetails({ bottom, top, ...props }) {
  return (
    <TwoRowDetailsWrapper {...props}>
      {top}
      {bottom}
    </TwoRowDetailsWrapper>
  );
}

const TwoRowDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
TwoRowDetailsWrapper.displayName = 'TwoRowDetails';
