const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  sm: 968,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const device = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `
    (max-width: ${sizes[label]}px)
  `;
  return acc;
}, {});

const mediaSizes = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `${sizes[label]}px`;
  return acc;
}, {});

const theme = {
  colors: {
    green: '#1b473f',
    cream: '#faf8f6',
    darkCream: '#f3f1ef',
    forest: '#307460',
    lightBlue: '#ddf2ff',
    blue: '#3e60b7',
    lightRed: '#fe6b62',
    red: '#ce1126',
    darkGrey: '#404040',
    steel: '#888884',
    softSteel: '#c4c4c1',
    softGreen: '#8da29d',
    darkGreen: '#143c35',
    salmon: '#f8d7cb',
    softGrey: '#e9e9e9',
    white: 'white',
    black: '#202020',
  },
  device,
  mediaSizes,
  sizes,
};

export default theme;
