import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useAccessorials } from 'core/hooks/queries';
import NewRoute from 'components/common/layout/authenticated/new-route';
import { useNewRoutes } from 'context/routes';
import { app } from 'routes/paths';

import NewRoutesCommodityForm from '../newRouteForm/commodity/index';
import {
  createInitialValues,
  validationSchema,
} from '../newRouteForm/commodity/data';

export default function NewRoutesCommodity() {
  const { answers, goTo } = useNewRoutes();
  const { data: accessorials } = useAccessorials();
  const { t } = useTranslation();

  return (
    <NewRoute
      title={t('onboarding-commodity-title')}
      subtitle={t('onboarding-commodity-subtitle')}>
      <Formik
        initialValues={createInitialValues(answers, t)}
        validationSchema={validationSchema(t)}
        onSubmit={goTo(app.routes.new.additionalServices)}>
        {({ values }) => (
          <NewRoutesCommodityForm
            accessorials={accessorials ?? []}
            values={values}
          />
        )}
      </Formik>
    </NewRoute>
  );
}
