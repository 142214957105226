import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Text } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

/**
 * Component intended bo be used as child of `GlobalBanner`.
 * @param { Object } props
 * @param { Function } props.onClose Callback function executed when the Close button is clicked
 */
export function ErrorBanner({ onClose }) {
  const { t } = useTranslation();
  return (
    <ContentWrapper>
      <Text color="white">{t('shipment-request-confirm-error')}</Text>
      <DismissButton
        onClick={onClose}
        colorType="text"
        color="white"
        size="medium">
        <Icon name="close" color="white" />
      </DismissButton>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
ContentWrapper.displayName = 'ContentWrapper';

const DismissButton = styled.button`
  height: 35px;
  flex: none;
  aspect-ratio: 1;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    transform: scale(1.1);
  }
`;
DismissButton.displayName = 'DismissButton';
