import React from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  Heading,
  Pagination,
  SelectBox,
  ShipmentCard,
} from '@nuvocargo/nuvo-styleguide';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/native';

import { Spinner } from 'components/generic/kit';
import {
  EmptyStateCard,
  WithDataMigrationMessage,
} from 'components/common/app';
import { app } from 'routes/paths';
import { getCountryName } from 'core/utils/lib';
import { calcJourneyPercentage } from 'core/utils/shipments';

import { useShipments } from './hooks';

export function Shipments() {
  const history = useHistory();
  const { t } = useTranslation();

  const emptyStateTranslations = {
    active: t('active-shipments').toLocaleLowerCase(),
    requested: t('pending-shipments').toLocaleLowerCase(),
    completed: t('empty-state-complete-shipments').toLocaleLowerCase(),
  };

  const {
    shipments,
    pagination,
    filter,
    filters,
    isLoading,
    isFetching,
    error,
    setSearchQuery,
  } = useShipments();

  if (isLoading) {
    return (
      <div className="relative col-span-4 flex h-full items-center [@media(min-width:968px)]:col-span-12">
        <Spinner />
      </div>
    );
  }

  const filtersWithCountLabel = filters.map(f => ({
    ...f,
    label: `${f.label}${f.count ? ` (${f.count})` : ''}`,
  }));
  const selectedFilter = filtersWithCountLabel.find(i => i.value === filter);

  return (
    <div className="container relative z-0 col-span-4 mx-auto flex flex-col gap-2 p-4 lg:px-10 xl:px-20 [@media(min-width:968px)]:col-span-12 ">
      <Heading variant="h3">{t('shipments-header')}</Heading>
      <div className="my-3 flex gap-2 md:justify-between ">
        <TextField
          size="small"
          placeholder={t('overview-search-placeholder')}
          styles={{
            root: {
              flex: '1',
              maxWidth: '320px',
            },
          }}
          onChange={e => setSearchQuery(e.target.value)}
        />

        <SelectBox
          options={filtersWithCountLabel}
          value={selectedFilter}
          onChange={({ value }) => {
            pagination?.gotoPage(0);
            history.push(`/shipments/?filter=${value}`);
          }}
          size="small"
        />
      </div>

      {isFetching && (
        <div className="relative flex h-full items-center ">
          <Spinner />
        </div>
      )}

      {error && error.data && (
        <ErrorMessage backgroundColor="green">
          {error.data.message}
        </ErrorMessage>
      )}

      {shipments.length > 0 ? (
        <>
          {shipments.map((shipment, index) => (
            <Link
              to={generatePath(app.shipments.details, {
                id: shipment.id,
              })}
              key={shipment.id || index}>
              <ShipmentCard
                origin={{
                  name: shipment.origin?.name || t('unknown'),
                  city: shipment.origin?.zipCode?.parents?.city || t('unknown'),
                  country:
                    getCountryName(
                      shipment.origin?.zipCode?.parents?.country
                    ) || t('unknown'),
                }}
                destination={{
                  name: shipment.destination?.name || t('unknown'),
                  city:
                    shipment.destination?.zipCode?.parents?.city ||
                    t('unknown'),
                  country:
                    getCountryName(
                      shipment.destination?.zipCode?.parents?.country
                    ) || t('unknown'),
                }}
                progress={calcJourneyPercentage(shipment.shipmentEvents?.nodes)}
                status={{
                  name: t(
                    shipment.lastOccurredShipmentEvent?.type?.toLowerCase() ||
                      'unknown'
                  ),
                  reference: shipment.folioNumber,
                }}
              />
            </Link>
          ))}

          <WithDataMigrationMessage fab padding="24px">
            <Pagination {...pagination} />
          </WithDataMigrationMessage>
        </>
      ) : !isLoading ? (
        <EmptyStateCard
          header={`${t('no-shipments-title')} ${
            emptyStateTranslations[filter]
          }`}
          subHeader={t('no-shipments-message')}
          cta={t('new-shipment')}
          onClick={() => history.push('/shipments/new')}
        />
      ) : null}
    </div>
  );
}
