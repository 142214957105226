import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { useMapImage } from './hook';

export default function MapStaticImage({ coordinates }) {
  const { staticMapUrl, isMapLoading, mapError } = useMapImage({ coordinates });

  return (
    R.not(R.isEmpty(staticMapUrl)) &&
    !isMapLoading &&
    !mapError && <StyledMap src={staticMapUrl} />
  );
}

const StyledMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
