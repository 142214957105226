import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TableWrapper = styled.div`
  overflow-x: auto;
`;
TableWrapper.displayName = 'TableWraper';

export const StyledTable = styled.table`
  border-spacing: 0;
  padding: ${({ padding }) => padding || '0'};
  width: 100%;

  thead tr {
    border-bottom: 0;
    height: 100%;
  }

  tbody tr {
    transition: 100ms box-shadow ease-in-out;
    &.clickable {
      cursor: pointer;
      &:hover {
        ${({ theme }) => `
          background: ${theme.colors.darkCream};
        `}
    }
    &:hover {
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    }
  }

  th,
  td {
    border-bottom: 1px solid #ece9e7;
    margin: 0;
    padding: 10px;
    text-align: left;
    height: 100%;

    + .clickable-cell {
      // Link defines its own padding
      padding: 0px;
      width: 100px;
    }

    :first-child {
      border-top: 0;
    }

    :last-child {
      border-right: 0;
    }
  }

  td.shrink-to-content {
    width: 1%;
    white-space: nowrap;
  }
`;
StyledTable.displayName = 'StyledTable';

export const LinkCell = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  height: 100%;
`;
LinkCell.displayName = 'LinkCell';
