import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import OnBoarding from 'components/common/layout/unauthenticated/onboarding';
import { useOnboarding } from 'context/onboarding';
import { onboarding as onBoardingPaths } from 'routes/paths';
import NewRoutesAdditionalServicesForm from 'screens/routes/newRouteForm/additionalServices/index';
import { initialValues } from 'screens/routes/newRouteForm/additionalServices/data';

export default function Services() {
  const { t } = useTranslation();
  const { answers, goTo } = useOnboarding();

  return (
    <OnBoarding title={t('onboarding-services-title')}>
      <Formik
        initialValues={{
          ...initialValues,
          ...answers,
        }}
        onSubmit={goTo(onBoardingPaths.userInfo)}>
        <NewRoutesAdditionalServicesForm />
      </Formik>
    </OnBoarding>
  );
}
