import React from 'react';
import { Heading } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

/**
 *
 * @param { Object } props
 * @param { string } props.step
 */
export function NewShipmentHeader({ step, children }) {
  return (
    <NewShipmentHeaderWrapper data-testid="shipment-request-header">
      <Heading data-testid="shipment-request-header-step" variant="h4">
        {step}
      </Heading>

      {children}
    </NewShipmentHeaderWrapper>
  );
}

const NewShipmentHeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
NewShipmentHeaderWrapper.displayName = 'NewShipmentHeaderWrapper';
