import { Heading, Tabs } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useHistory } from 'react-router-dom';

import { useFlags } from 'core/hooks';
import Layout from 'components/common/layout/authenticated';
import { theme } from 'styles';
import { app } from 'routes/paths';
import { useUser } from 'context/user';

import { TabTypeEnum } from './utils';

const Settings = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = useUser();
  const { data } = useFlags(currentUser?.email);

  const matchesSize = useMediaQuery(`(min-width: ${theme.mediaSizes.laptop})`);

  const handleTabChange = useCallback(
    tabClicked =>
      history.push(
        `${
          tabClicked === TabTypeEnum.NOTIFICATIONS
            ? app.settings.notifications
            : app.settings.account
        }`
      ),
    [history]
  );

  const defaultPath = useMemo(
    () =>
      history.location.pathname === app.settings.notifications
        ? TabTypeEnum.NOTIFICATIONS
        : TabTypeEnum.ACCOUNT,
    [history.location.pathname]
  );

  if (!data) return null;

  const { customer_ui_show_notification_settings } = data.features;

  return (
    <Layout paddingTop="0px" ignoreGrid>
      <div
        className={
          'container mx-auto flex flex-col px-2 pb-2 nuvo-sm:px-12 nuvo-sm:pb-12'
        }>
        <div className="mx-auto ml-3 nuvo-sm:ml-0">
          <Heading variant="h3">{t('settings')}</Heading>
        </div>
        <div className="mx-auto flex h-full w-full flex-col nuvo-sm:mt-8 nuvo-sm:flex-row">
          <div className="mt-6 ml-3 nuvo-sm:ml-0">
            <Tabs.Root
              defaultValue={defaultPath}
              orientation={matchesSize ? 'vertical' : 'horizontal'}
              onValueChange={handleTabChange}>
              <Tabs.List>
                <Tabs.Trigger value="account">
                  {t('account-settings')}
                </Tabs.Trigger>
                {customer_ui_show_notification_settings.enabled && (
                  <Tabs.Trigger value="notifications">
                    {t('notifications')}
                  </Tabs.Trigger>
                )}
              </Tabs.List>
            </Tabs.Root>
          </div>
          <div className="mx-auto mt-6 h-full w-full bg-white nuvo-sm:ml-24 nuvo-sm:mt-0">
            {children}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
