import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import {
  CTAButton,
  Heading,
  Icon,
  Link,
  Modal,
} from '@nuvocargo/nuvo-styleguide';
import {
  TextField,
  Checkbox,
  Password,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import {
  MIN_LENGTH,
  SPECIAL_CHARACTERS,
  NUMBERS,
  validatePassword as initValidatePassword,
} from '@nuvocargo/nuvo-styleguide/forms/native/Password/validations';
import { Dropdown } from '@nuvocargo/nuvo-styleguide/forms/formik';

import theme from 'styles/theme';
import { ErrorMessage } from 'components/generic/form';
import { Stack } from 'components/generic/kit';
import { openBanner, closeBanner } from 'core/hooks/use-banner';
import { useUser } from 'context/user';

import { useAdditionalAppInvite, useModal } from './hooks';
import { createRoleOptions } from './data';

const validatePassword = initValidatePassword({ minLength: 8 });

export default function AdditionalAppInvite() {
  const {
    currentUser: {
      id,
      isAdmin,
      hasPassword,
      acceptedTerms,
      email,
      roles: userRoles,
      title: userTitle,
    },
  } = useUser();

  const { t, i18n } = useTranslation();
  const rolesOptions = React.useMemo(() => createRoleOptions(t), [t]);

  const neededFields = {
    password: !hasPassword,
    roles: !userRoles?.length,
    title: !userTitle,
    acceptTerms: !acceptedTerms,
  };

  const { isModalOpen, isSuccessful, showSuccess } = useModal({
    // Don't show the modal when the user is admin
    initialState: !isAdmin && (!hasPassword || !acceptedTerms),
  });

  const { initialValues, completeProfile, validations, isLoading, error } =
    useAdditionalAppInvite({
      neededFields: neededFields,
      validatePassword,
      showSuccess,
      t,
    });

  const title = React.useMemo(() => {
    if (hasPassword && userRoles && userTitle)
      return t('addittional-app-invite-title-with-acceptterms-only');
    return t('addittional-app-invite-title');
  }, [t, userRoles, userTitle, hasPassword]);

  React.useEffect(() => {
    const ProfileCompleted = () => (
      <>
        <div className="side">
          <Icon name="check" color="white" alt="Success" />
          <Heading
            variant="h6"
            styles={{ root: { marginLeft: '10px' } }}
            color="white">
            {t('profile-completed')}
          </Heading>
        </div>
        <div className="side">
          <Icon
            name="close"
            color="lightBlue"
            alt="Close"
            style={{ cursor: 'pointer' }}
            onClick={() => closeBanner('profile-completed')}
          />
        </div>
      </>
    );
    if (isSuccessful) {
      openBanner({
        key: 'profile-completed',
        children: ProfileCompleted,
      });
      return () => closeBanner('profile-completed');
    }
  }, [isSuccessful]);

  return (
    <Modal
      isOpen={isModalOpen}
      styles={{
        root: {
          boxSizing: 'border-box',
          width: '608px',
          padding: '64px',
          [`@media only screen and ${theme.device.sm}`]: {
            padding: '32px 16px',
          },
        },
      }}>
      <>
        <Heading
          variant="h5"
          styles={{
            root: {
              maxWidth: '480px',
              textAlign: 'center',
              marginBottom: '24px',
            },
          }}>
          {title}
        </Heading>
        <Formik
          initialValues={{ ...initialValues, email: email }}
          validationSchema={validations}
          onSubmit={async values => {
            const submitValues = {
              id,
              ...values,
              ...(values.roles
                ? { roles: values.roles.map(r => r.value) }
                : {}),
            };
            await completeProfile(submitValues);
            showSuccess();
          }}>
          {({ values }) => (
            <Form style={{ width: '100%' }}>
              <Stack space={24}>
                <TextField
                  name="email"
                  label={t('email')}
                  type="email"
                  disabled
                />

                {neededFields.password && (
                  <div>
                    <Password
                      name="password"
                      label={t('create-password')}
                      useInternalValidation
                      overrideValidationMessages={{
                        [MIN_LENGTH]: t('password-validation-min-length'),
                        [SPECIAL_CHARACTERS]: t(
                          'password-validation-special-characters'
                        ),
                        [NUMBERS]: t('password-validation-number'),
                      }}
                    />
                  </div>
                )}
                {neededFields.roles && (
                  <Dropdown
                    isMulti
                    name="roles"
                    label={t('select-your-role')}
                    placeholder={t('select-your-role-placeholder')}
                    options={rolesOptions}
                  />
                )}
                {neededFields.title && (
                  <TextField
                    name="title"
                    label={t('your-title')}
                    placeholder={t('your-title-placeholder')}
                    type="text"
                  />
                )}
                <Checkbox
                  name="acceptTerms"
                  label={
                    <Trans i18nKey="onbaording-terms-agreement">
                      I agree to the Nuvocargo{' '}
                      <Link
                        href={`${process.env.REACT_APP_LANDING_PAGE_URL}/${i18n.language}/terms-and-conditions#terms-of-use`}
                        type="inline"
                        color="steel"
                        target="_blank"
                        styles={{ small: { fontSize: '14px' } }}>
                        Terms of Use
                      </Link>{' '}
                      and{' '}
                      <Link
                        href={`${process.env.REACT_APP_LANDING_PAGE_URL}/${i18n.language}/terms-and-conditions#shipper-terms`}
                        type="inline"
                        color="steel"
                        target="_blank"
                        styles={{ small: { fontSize: '14px' } }}>
                        Shipper Terms
                      </Link>
                    </Trans>
                  }
                />
                {error && error.data && (
                  <ErrorMessage backgroundColor="taupe">
                    {error.data.message}
                  </ErrorMessage>
                )}
              </Stack>
              <CTAButton
                styles={{
                  primary: { margin: '32px auto 0px' },
                  disabledStyle: { margin: '32px auto 0px' },
                }}
                disabled={!values?.acceptTerms || isLoading}>
                {t('complete-your-profile')}
              </CTAButton>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
}
