import { curry, mergeDeepLeft } from 'ramda';
import React, { createContext, useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

export const Context = createContext();

export const Provider = ({ children }) => {
  const [answers, setAnswers] = useState({});
  const history = useHistory();
  const value = useMemo(() => {
    const goTo = curry((route, newAnswers) => {
      setAnswers(mergeDeepLeft(newAnswers));
      history.push({
        pathname: route,
        search: history.location.search,
      });
    });
    return {
      answers,
      goTo,
      resetNewRoutesProcess: () => setAnswers({}),
    };
  }, [answers, history]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useNewRoutes() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(`useNewRoutes must be used within a NewRoute`);
  }
  return context;
}
