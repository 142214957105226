import { useLocation } from 'react-router-dom';
import QueryString, { stringify } from 'qs';

/**
 * Parses query parameters as an object
 */
export function useQueryParams() {
  const { search } = useLocation();

  const query = QueryString.parse(search, { ignoreQueryPrefix: true });
  return { ...query };
}

/**
 * Retrieves query parameters enconded as string
 */
export function useQueryParamsString() {
  const qp = useQueryParams();
  return stringify(qp);
}
