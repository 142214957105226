import React from 'react';
import styled from 'styled-components';
import { CTAButton } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

export default function ErrorPage({ onClick }) {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Main>
          <div style={{ marginTop: '60px', textAlign: 'center' }}>
            <StyledHeader>{t('global-app-error-heading')}</StyledHeader>
          </div>
          <div style={{ marginTop: '60px', textAlign: 'center' }}>
            {t('global-app-error-team-has-been-notified')}
          </div>
          <div
            style={{
              marginTop: '60px',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <CTAButton onClick={onClick}>
              {t('global-app-error-cta-report')}
            </CTAButton>
          </div>
        </Main>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 100%;
  grid-template-areas: 'Header' 'Main';
  @media only screen and (max-device-width: 768px) {
  }
`;

const StyledHeader = styled.span`
  color: ${({ theme }) => theme.colors.green};
  font-family: 'value-sans-regular', sans-serif;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  padding-top: 30px;
  text-align: center;
`;

const Main = styled.div`
  grid-area: Main;
  margin: 0 auto;
  width: 445px;
  ${({ theme }) => `
  @media only screen and ${theme.device.tablet} {
    width: 100%;
  }
  `}
`;
