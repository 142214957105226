import * as yup from 'yup';

import { buildStrapsOptions, yesNoDropdownOptions } from 'core/utils';

import { COMMODITIES_SPECIAL_REQUIREMENTS } from './utils';

export const createInitialValues = (answers, t) => ({
  category: {
    value: '',
  },
  name: '',
  valuePerLoad: {
    value: 100000,
  },
  weight: {
    value: 20000,
    unit: 'kg',
  },
  specialHandling: [
    {
      id: COMMODITIES_SPECIAL_REQUIREMENTS.FUMIGATION,
      type: t('fumigation'),
      cost: '100',
      required: yesNoDropdownOptions(t)[0],
    },
    {
      id: COMMODITIES_SPECIAL_REQUIREMENTS.STRAPS,
      type: t('straps_needed'),
      cost: '50',
      required: buildStrapsOptions(t)[0],
    },
  ],
  fumigation: yesNoDropdownOptions(t)[0],
  straps: buildStrapsOptions(t)[0],
  showShipmentValue: false,
  notes: '',
  ...answers,
});

export const createOptions = t => [
  { label: t('commodity-food-perishable'), value: 'Food (perishable)' },
  { label: t('commodity-food-non-perishable'), value: 'Food (non-perishable)' },
  {
    label: t('commodity-industrial-material'),
    value: 'Industrial material, equipment or parts',
  },
  {
    label: t('commodity-construction-materials'),
    value: 'Construction materials',
  },
  { label: t('commodity-chemicals-hazmat'), value: 'Chemicals (hazmat)' },
  {
    label: t('commodity-chemicals-non-hazmat'),
    value: 'Chemicals (non-hazmat)',
  },
  { label: t('commodity-alcohol-and-spirits'), value: 'Alcohol and Spirits' },
  { label: t('commodity-electronics'), value: 'Electronics' },
  { label: t('commodity-automotive'), value: 'Automative' },
  { label: t('commodity-other'), value: 'Other' },
];

export const validationSchema = t =>
  yup.object().shape({
    category: yup.object().shape({
      value: yup.string().required(t('required-error')),
    }),
    name: yup.string().required(t('required-error')),
    weight: yup.object().shape({
      value: yup
        .number()
        .integer(t('weight-must-have-no-decimals'))
        .positive(t('weight-must-be-positive'))
        .typeError(t('validation-weight-number'))
        .required(t('required-error')),
    }),
    showShipmentValue: yup.bool(),

    valuePerLoad: yup.object().when('showShipmentValue', {
      is: true,
      then: yup.object().shape({
        value: yup
          .number()
          .integer(t('value-must-have-no-decimals'))
          .positive(t('value-must-be-positive'))
          .min(100000, t('value-gte-100k'))
          .label(t('value-per-load'))
          .required(t('required-error')),
      }),
    }),
    fumigation: yup.object().shape({
      value: yup.string().required(t('required-error')),
    }),
    hazmat: yup.bool(),
    straps: yup.object().shape({
      value: yup.string().required(t('required-error')),
    }),
    notes: yup.string(),
  });
