import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as FullStory from '@fullstory/browser';
import { generatePath, useHistory } from 'react-router-dom';
import { CTAButton, Table, useNuvoTable } from '@nuvocargo/nuvo-styleguide';
import { CSVLink } from 'react-csv';

import { SHIPPER_API_VERSION } from 'core/utils/constants';
import { WithDataMigrationMessage } from 'components/common/app';
import { app } from 'routes/paths';

import createColumns from './createColumns';
import { useCSV } from './hooks';

const API_URL = process.env.REACT_APP_API_URL;

const BillingTable = ({
  currency,
  invoiceCompanyOptions,
  data,
  isLoading,
  invoiceCompany,
  page,
  pageSize,
  setCurrency,
  setInvoiceCompany,
  setPage,
  setPageSize,
  setStatus,
  status,
  searchFor,
  setSearchFor,
  totalItems,
}) => {
  const {
    setFilter,
    setPageSize: tableSetPageSize,
    goToPage,
    contextState,
  } = useNuvoTable();

  const { csvData } = useCSV({
    status,
    invoices: data,
  });

  const [isFirstRender, setIsFirstRender] = useState(true);
  const { t } = useTranslation();
  const columns = createColumns({ t, invoiceCompanyOptions });
  const history = useHistory();

  /**
   * Sync the URL state with the values the table is storing internally.
   * During the first render we sync the values from our URL into table state state.
   * After the first render, we sync any changes in table state to URL params.
   */
  useEffect(() => {
    const {
      filter: {
        searchFor: filterSearchFor,
        currency: filterCurrency,
        status: filterStatus,
        invoiceCompany: filterInvoiceCompany,
      },
      page: { number, size },
    } = contextState;

    if (filterSearchFor !== searchFor) {
      if (isFirstRender) {
        setFilter('searchFor', searchFor);
      } else {
        setSearchFor(filterSearchFor);
      }
    }

    if (filterCurrency !== currency) {
      if (isFirstRender) {
        setFilter('currency', currency);
      } else {
        setCurrency(filterCurrency);
      }
    }

    if (filterStatus !== status) {
      if (isFirstRender) {
        setFilter('status', status);
      } else {
        setStatus(filterStatus);
      }
    }
    const invoiceCompanyId =
      invoiceCompany && Number.parseInt(invoiceCompany, 10);
    if (filterInvoiceCompany !== invoiceCompanyId) {
      if (isFirstRender) {
        setFilter('invoiceCompany', invoiceCompanyId);
      } else {
        setInvoiceCompany(filterInvoiceCompany);
      }
    }

    if (size !== pageSize) {
      if (isFirstRender) {
        tableSetPageSize(pageSize);
      } else {
        setPageSize(size);
      }
    }

    if (number !== page - 1) {
      if (isFirstRender) {
        goToPage(page - 1);
      } else {
        setPage(number + 1);
      }
    }

    setIsFirstRender(false);
  }, [
    contextState,
    currency,
    goToPage,
    invoiceCompany,
    isFirstRender,
    page,
    pageSize,
    setCurrency,
    setFilter,
    setIsFirstRender,
    setInvoiceCompany,
    setPage,
    setPageSize,
    setStatus,
    status,
    searchFor,
    setSearchFor,
    tableSetPageSize,
  ]);

  const tableEmptyMessage = useMemo(() => {
    if (
      contextState.filter.currency ||
      contextState.filter.status ||
      contextState.filter.invoiceCompany
    ) {
      return {
        title: t('filtered-invoices-empty-title'),
        description: t('filtered-invoices-empty-description'),
      };
    }

    return {
      title: t('all-invoices-empty-title'),
      description: t('all-invoices-empty-description'),
    };
  }, [contextState.filter, t]);

  const handleDownloadFiles = selectedItems => {
    const selectedIds = selectedItems.map(item => item.id).join(',');

    window.open(
      `${API_URL}${SHIPPER_API_VERSION}/shipments/invoices/download?shipment_ids=${selectedIds}`,
      '_blank'
    );
  };

  return (
    <div className="flex flex-col">
      <Table
        autoFilters={{
          enabled: true,
          clearFilterText: t('clear-filters'),
          children: csvData && (
            <CSVLink
              data={csvData}
              filename={`${
                status || 'general'
              }-invoices-${new Date().toISOString()}.csv`}
              target="_blank"
              onClick={() => {
                FullStory.event('[Billing] Downloaded CSV export');
              }}>
              <CTAButton
                mode="light"
                colorType="secondary"
                size="small"
                icon="fileDownload"
                shape="rounded">
                CSV
              </CTAButton>
            </CSVLink>
          ),
        }}
        data={data}
        columns={columns}
        isLoading={isLoading}
        pagination={{
          ofText: t('of-text'),
          totalItems,
        }}
        onRowClick={({ original: invoice }) => {
          let pdfUrl = generatePath(app.billingPdf, {
            shipmentName: invoice.fileName,
          });

          history.push(pdfUrl);

          FullStory.event('[Billing] Opened Invoice', {
            invoice,
          });
        }}
        actions={{
          label: numSelected => `${numSelected} ${t('label-billing-selected')}`,
          items: selectedItems => (
            <>
              <button
                style={{
                  color: 'white',
                  padding: 12,
                  marginRight: 8,
                  background: 'rgba(255, 255, 255, 0.03)',
                }}
                onClick={() => handleDownloadFiles(selectedItems)}>
                {t('button-download')}
              </button>
            </>
          ),
        }}
        empty={tableEmptyMessage}
        styles={{
          autoFilters: {
            filtersWrapper: {
              justifyContent: 'flex-start',
              gap: 8,
            },
          },
        }}
      />

      <div className="mb-12 pt-6 ">
        <WithDataMigrationMessage />
      </div>
    </div>
  );
};

export default BillingTable;
