import './styles/index.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PageHeaderProvider } from '@nuvocargo/nuvo-styleguide';

import Context from 'context';
import { Spinner } from 'components/generic/kit/index.js';
import { isDevelopment } from 'core/utils/lib';

import App from './app.js';
import './bootstrap/i18n';
import './styles/font.css';

FullStory.init({
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
  devMode: isDevelopment,
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
};
TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Context>
          <PageHeaderProvider>
            <App />
          </PageHeaderProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </Context>
      </QueryClientProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);
