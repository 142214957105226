/**
 * Calculate journey percentage (out of 100) completion based on shipment events
 * @param { Object[] } shipmentEvents
 * @returns Percentage as number
 */
export function calcJourneyPercentage(shipmentEvents = []) {
  const totalEvents = shipmentEvents.length || 1;

  // Events with actualDatetime are already completed
  const completedEventsCount = shipmentEvents.reduce(
    (acc, event) => acc + (event.actualDatetime ? 1 : 0),
    0
  );

  return (completedEventsCount / totalEvents) * 100;
}
