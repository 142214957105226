import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import styled from 'styled-components';

import { StateCard } from 'components/common/app/StateCard';
import { app } from 'routes/paths';

/**
 *
 * @param { Object } props
 * @param { Function } props.onRetry
 */
export function FetchRouteError({ onRetry }) {
  const { t } = useTranslation();

  return (
    <StateCard
      styles={{
        root: {
          padding: '60px 0',
        },
      }}
      icon={
        <IconWrapper>
          <Iconography
            alt="Error icon"
            name="insurance"
            styles={{ root: { width: '100%' } }}
          />
        </IconWrapper>
      }
      header={t('fetch-route-error-title')}
      description={t('fetch-route-error-description')}
      primaryAction={{
        label: t('return-to-shipments'),
        props: {
          as: RouterLink,
          isLink: true,
          to: {
            pathname: app.shipments.root,
          },
        },
      }}
      secondaryAction={
        Boolean(onRetry) && {
          label: t('try-again'),
          props: {
            onClick: onRetry,
          },
        }
      }
    />
  );
}

const IconWrapper = styled.div`
  width: 300px;
  aspect-ratio: 1;
  background: ${({ theme }) => theme.colors.darkCream};
  border-radius: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 320px;
    height: 220px;
  }
`;
IconWrapper.displayName = 'IconWrapper';
