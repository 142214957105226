import {
  CTAButton,
  Grid,
  GridColumn,
  Heading,
  Link,
} from '@nuvocargo/nuvo-styleguide';
import {
  Checkbox,
  Dropdown,
  PhoneNumberField,
  TextField,
  Password,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import {
  MIN_LENGTH,
  SPECIAL_CHARACTERS,
  NUMBERS,
  validatePassword as initValidatePassword,
} from '@nuvocargo/nuvo-styleguide/forms/native/Password/validations';
import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Box, ExpiryPage } from 'components/generic/kit';
import { ErrorMessage } from 'components/generic/form';
import Layout from 'components/common/layout/unauthenticated';
import { useCheckInvite } from 'core/hooks';

import {
  constructInitialValues,
  createRoleOptions,
  validationSchema,
} from './data';
import { useCompleteProfile } from './hooks';

const validatePassword = initValidatePassword({ minLength: 8 });

export default function CompleteProfile() {
  const { t, i18n } = useTranslation();

  const { isLoading, error, inviteToken, type, userInfo, senderInfo } =
    useCheckInvite();

  const { isSigningUp, signupErrors, completeProfile } = useCompleteProfile({
    email: userInfo.email,
  });

  const rolesOptions = React.useMemo(() => createRoleOptions(t), [t]);

  const initialValues = userInfo => {
    let initialValues = constructInitialValues(userInfo);
    initialValues['role'] = rolesOptions[0];
    return initialValues;
  };

  if (isLoading) {
    return (
      <Layout>
        <Wrapper>
          <Grid>
            <GridColumn
              startMd={4}
              md={6}
              styles={{ root: { textAlign: 'center', paddingTop: '36px' } }}>
              <Header>
                <HeadingPlaceholder
                  initial={{ opacity: 0.7 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    repeat: Infinity,
                    duration: 0.4,
                    repeatType: 'reverse',
                  }}
                />
              </Header>
            </GridColumn>
          </Grid>
        </Wrapper>
      </Layout>
    );
  }

  const isTokenExpired =
    type !== 'company_additional_contact' || error?.data?.error;

  if (isTokenExpired) {
    return <ExpiryPage title={t(`onboarding-expired-token-title`)} />;
  }

  return (
    <Layout>
      <Wrapper>
        <Grid>
          <GridColumn
            startMd={4}
            md={6}
            styles={{ root: { textAlign: 'center', paddingTop: '36px' } }}>
            <Header>
              <Heading component="div" variant="h3" color="green">
                {t('shipment-manager-introduction', {
                  name: senderInfo.name,
                })}
              </Heading>
            </Header>
          </GridColumn>
        </Grid>
        <Box styles={{ root: { marginTop: '40px' } }}>
          <Formik
            enableReinitialize
            initialValues={initialValues(userInfo)}
            validationSchema={validationSchema({ t, validatePassword })}
            onSubmit={({
              acceptTerms,
              firstName,
              lastName,
              password,
              phoneNumber,
              title,
              role,
            }) =>
              completeProfile({
                invite_token: inviteToken,
                language: i18n.language,
                accept_terms: acceptTerms,
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
                password,
                title,
                role,
              })
            }>
            {({ values }) => (
              <Form>
                <Grid>
                  <GridColumn
                    startMd={4}
                    md={3}
                    styles={{ root: { marginTop: '24px' } }}>
                    <TextField
                      name="firstName"
                      label={t('first-name')}
                      type="text"
                    />
                  </GridColumn>
                  <GridColumn
                    startMd={7}
                    md={3}
                    styles={{ root: { marginTop: '24px' } }}>
                    <TextField
                      name="lastName"
                      label={t('last-name')}
                      type="text"
                    />
                  </GridColumn>
                  <GridColumn
                    startMd={4}
                    md={3}
                    styles={{ root: { marginTop: '24px' } }}>
                    <TextField
                      name="companyName"
                      label={t('company-name')}
                      type="text"
                      disabled
                    />
                  </GridColumn>
                  <GridColumn
                    startMd={7}
                    md={3}
                    styles={{ root: { marginTop: '24px' } }}>
                    <PhoneNumberField
                      name="phoneNumber"
                      label={t('phone-number')}
                    />
                  </GridColumn>
                  <GridColumn
                    startMd={4}
                    md={3}
                    styles={{ root: { marginTop: '24px' } }}>
                    <TextField
                      name="email"
                      label={t('email')}
                      type="email"
                      disabled
                    />
                  </GridColumn>
                  <GridColumn
                    startMd={7}
                    md={3}
                    styles={{ root: { marginTop: '24px' } }}>
                    <Password
                      name="password"
                      label={t('create-password')}
                      useInternalValidation={true}
                      overrideValidationMessages={{
                        [MIN_LENGTH]: t('password-validation-min-length'),
                        [SPECIAL_CHARACTERS]: t(
                          'password-validation-special-characters'
                        ),
                        [NUMBERS]: t('password-validation-number'),
                      }}
                    />
                  </GridColumn>
                  <GridColumn
                    startMd={4}
                    md={6}
                    styles={{ root: { marginTop: '24px' } }}>
                    <Dropdown
                      name="role"
                      label={t('select-your-role')}
                      options={rolesOptions}
                    />
                  </GridColumn>
                  <GridColumn
                    startMd={4}
                    md={6}
                    styles={{ root: { marginTop: '24px' } }}>
                    <TextField
                      name="title"
                      label={t('your title')}
                      type="text"
                    />
                  </GridColumn>
                  <GridColumn
                    startMd={5}
                    md={4}
                    styles={{ root: { marginTop: '24px' } }}>
                    <Checkbox
                      name="acceptTerms"
                      label={
                        <Trans i18nKey="onboarding-terms-agreement">
                          I agree to the{' '}
                          <Link
                            href={`${process.env.REACT_APP_LANDING_PAGE_URL}/${
                              i18n.language
                            }/${
                              i18n.language.startsWith('es')
                                ? 'politicas'
                                : 'policies'
                            }`}
                            type="inline"
                            color="steel"
                            target="_blank"
                            styles={{ small: { fontSize: '14px' } }}>
                            Privacy Policy
                          </Link>{' '}
                          and{' '}
                          <Link
                            href={`${process.env.REACT_APP_LANDING_PAGE_URL}/${i18n.language}/terms-and-conditions#terms-of-use`}
                            type="inline"
                            color="steel"
                            target="_blank"
                            styles={{ small: { fontSize: '14px' } }}>
                            Terms of Use
                          </Link>
                        </Trans>
                      }
                    />
                  </GridColumn>
                  <GridColumn>
                    {signupErrors?.data?.message && (
                      <ErrorMessage backgroundColor="taupe">
                        {signupErrors.data.message}
                      </ErrorMessage>
                    )}

                    <CTAButton
                      type="submit"
                      styles={{
                        primary: { margin: '48px auto 0px' },
                        disabledStyle: { margin: '48px auto 0px' },
                      }}
                      disabled={!values?.acceptTerms || isSigningUp}>
                      {t('next')}
                    </CTAButton>
                  </GridColumn>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Wrapper>
    </Layout>
  );
}

const Header = styled.div`
  display: flex;
  max-width: 687px;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;

  &:nth-child(1) {
    margin-top: 32px;
  }
  &:nth-child(2) {
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  margin-top: -40px;
  padding-bottom: 172px;
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        margin-top: 2rem;
        padding: 0 10px;
        padding-bottom: 3rem;
      }
    `}
`;

const HeadingPlaceholder = styled(motion.div)`
  display: flex;
  max-width: 687px;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  height: 64px;
  background-color: #efe8e1;
`;
