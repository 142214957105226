import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

// Second axios instance for root url instead of /v0
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const getEnabledFeatures = actorId =>
  axiosInstance
    .get(`/feature_flags/api/actors/${actorId}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error?.response || error));
