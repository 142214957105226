import React from 'react';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/browser';
import { toast, ToastContainer } from 'react-toastify';

import { useUser } from 'context/user';
import { Spinner } from 'components/generic/kit';
import AuthenticatedApp from 'routes/authenticated-routes';
import UnauthenticatedApp from 'routes/unauthenticated-routes';
import { GlobalBanner, ErrorPage } from 'components/common/app';
import { ErrorBoundary } from 'core/integrations/errors';

import bootstrap from './bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle, theme } from './styles';

bootstrap();

function App() {
  const { isAuthenticated, isLoading, backUrl } = useUser();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastContainer
        enableMultiContainer
        className="banner-container"
        containerId="banner"
        theme="colored"
      />
      <ToastContainer
        enableMultiContainer
        theme="colored"
        position={toast.POSITION.BOTTOM_RIGHT}
      />

      <ErrorBoundary
        errorView={
          <ErrorPage
            onClick={() => Sentry.lastEventId() && Sentry.showReportDialog()}
          />
        }>
        <GlobalBanner />
        <RenderApp
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          backUrl={backUrl}
        />
      </ErrorBoundary>
    </ThemeProvider>
  );
}
const RenderApp = ({ isAuthenticated, isLoading, backUrl }) => {
  if (isLoading) return <Spinner />;
  if (isAuthenticated) return <AuthenticatedApp />;
  return <UnauthenticatedApp backUrl={backUrl} />;
};

export default App;
