import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  > h3 {
    margin-bottom: 10px;
  }

  @media (min-width: ${({ theme }) => theme.mediaSizes.mobileL}) {
    flex-direction: row;

    > h3 {
      margin: 0 24px 0 0;
    }
  }
`;

export const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 22px;

  > a {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.mediaSizes.mobileL}) {
    > a {
      display: block;
    }
  }
`;

export const StyledTable = styled.div`
  padding: 30px 0;

  overflow-x: scroll;

  table {
    border-spacing: 0;
    padding: 20px 30px;
    width: 100%;
    thead tr th {
      border-bottom: 0;
    }

    th,
    td {
      border-bottom: 1px solid #ece9e7;
      margin: 0;
      padding: 20px;
      text-align: left;
      :first-child {
        border-top: 0;
      }
      :last-child {
        border-right: 0;
      }
    }

    @media (min-width: ${({ theme }) => theme.mediaSizes.mobileL}) {
      overflow-x: auto;
    }
  }
`;

export const StyledBlocks = styled.div`
  display: block;

  @media (min-width: ${({ theme }) => theme.mediaSizes.mobileL}) {
    display: flex;
  }
`;

export const TableRow = styled.tr`
  cursor: pointer;

  :hover {
    td > span {
      color: #888884;
    }

    td > svg {
      fill: #888884;
    }
  }
`;

export const StyledFullTd = styled.td`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
  }
`;
