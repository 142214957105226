import styled from 'styled-components';

export const FlexItem = styled.div`
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
  max-width: 659px;
  width: 100%;
`;

export const BtnContainer = styled.div`
  margin-top: ${({ topSpacing }) => (topSpacing ? topSpacing : '40px')}
  margin-bottom: ${({ bottomSpacing }) =>
    bottomSpacing ? bottomSpacing : '40px'}
  display: flex;
  justify-content: center;
`;
