import { useState } from 'react';
import qs from 'qs';
import { useQuery } from 'react-query';

import * as userApi from 'core/api/user';

const DEFAULT_SALES_PICTURE = `${process.env.REACT_APP_API_URL}/images/sales_profiles/deepak@nuvocargo.com.png`;
const DEFAULT_SALES_NAME = 'Deepak';

export default function useCheckInvite() {
  const [senderInfo, setSenderInfo] = useState({
    image_url: DEFAULT_SALES_PICTURE,
    name: DEFAULT_SALES_NAME,
  });

  const { invite_token } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const { isLoading, error, data } = useQuery(
    ['/invites/check', invite_token],
    () => userApi.checkInviteToken(invite_token),
    {
      enabled: !!invite_token,
      onSuccess: ({ data }) => {
        const image = new Image();
        image.onload = () =>
          setSenderInfo({
            image_url: data?.data?.sender?.imageUrl,
            name: data?.data?.sender?.name,
          });
        image.src = data?.data?.sender?.imageUrl;
      },
      retry: false,
    }
  );

  return {
    senderInfo,
    error,
    isLoading,
    inviteToken: invite_token,
    type: data?.type,
    userInfo: {
      email: data?.data.invitee?.email || '',
      companyName: data?.data?.company?.name || '',
      firstName: data?.data.invitee?.contact?.first_name,
      lastName: data?.data.invitee?.contact?.last_name,
      title: data?.data.invitee?.contact?.job_title,
      phoneNumber: data?.data.invitee?.contact?.phone_number,
      role: data?.data.invitee?.contact?.role,
    },
  };
}
