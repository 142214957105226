import { gql } from 'graphql-request';

export const INSIGHTS_QUERY = gql`
  query customerui_getShipments(
    $first: Int
    $after: String
    $companyId: String
    $state: [ShipmentStateEnum!]
    $searchFor: String
  ) {
    shipments(
      first: $first
      after: $after
      companyId: $companyId
      state: $state
      searchFor: $searchFor
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        folioNumber
        currency
        priceInCents
        origin {
          ...placeFields
        }
        destination {
          ...placeFields
        }
        shipmentEvents(first: 10) {
          edges {
            node {
              ...shipmentEventFields
            }
          }
        }
      }
    }
  }

  fragment placeFields on Place {
    name
    zipCode {
      parents {
        city
        state
      }
    }
  }

  fragment shipmentEventFields on ShipmentEvent {
    actualDatetime
    id
    type
  }
`;
