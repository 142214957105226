import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@nuvocargo/nuvo-styleguide';
import { Link } from 'react-router-dom';
import { isEmpty } from 'ramda';

const getOverdueMessage = (overdueData, t) => {
  const { USD, MXN } = overdueData;
  const overdueUSD =
    USD > 0
      ? `${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(USD)} USD`
      : '';
  const overdueMXN =
    MXN > 0
      ? `${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(MXN)} MXN`
      : '';

  if (!isEmpty(overdueUSD) && !isEmpty(overdueMXN)) {
    return `${t('overdue-message', { totalOverdue: overdueUSD })} ${t(
      'and-overdue-message',
      { totalOverdue: overdueMXN }
    )}`;
  }
  return `${t('overdue-message', {
    totalOverdue: isEmpty(overdueMXN) ? overdueUSD : overdueMXN,
  })}`;
};

const OverdueBanner = ({ overdueData }) => {
  const { t } = useTranslation();
  const overdueMessage = getOverdueMessage(overdueData, t);

  return (
    <Heading
      variant="h6"
      styles={{
        root: {
          margin: '4px 0 0 10px',
          fontSize: '16px',
          lineHeight: '24px',
        },
      }}
      color="black">
      <span>
        {`${overdueMessage}. ${t('overdue-subject-to-review')}. `}
        <Link
          to="/billing"
          style={{ fontWeight: '200', textDecoration: 'underline' }}>
          {t('more-details')}
        </Link>
      </span>
    </Heading>
  );
};

export default OverdueBanner;
