import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import qs from 'qs';

import {
  AdditionalServices,
  Commodity,
  CompleteProfile,
  ForgotPassword,
  LinkSent,
  Login,
  MagicLinkLogin,
  ResetPassword,
  ShipmentRoutes,
  Success,
  UserInformation,
} from 'screens';
import Layout from 'components/common/layout/unauthenticated';
import { Provider as OnboardingProvider } from 'context/onboarding';

import { auth, onboarding } from './paths';

const UNAUTHENTICATED_ROUTES = [
  ...Object.values(auth),
  ...Object.values(onboarding),
];

export default function UnauthenticatedRoutes({ backUrl }) {
  const { search } = useLocation();

  const { redirect } = qs.parse(backUrl, { ignoreQueryPrefix: true });
  const redirectUrl = UNAUTHENTICATED_ROUTES.includes(redirect)
    ? undefined
    : backUrl;

  return (
    <Switch>
      <Route path={auth.resetPassword}>
        <Layout>
          <ResetPassword />
        </Layout>
      </Route>
      <Route path={auth.forgotPassword}>
        <ForgotPassword />
      </Route>
      <Route path={auth.login}>
        <Login />
      </Route>
      <Route path={onboarding.completeProfile}>
        <CompleteProfile />
      </Route>

      <Route path={onboarding.root}>
        <OnboardingProvider>
          <Switch>
            <Route
              exact
              path={onboarding.shipmentLanes}
              component={ShipmentRoutes}
            />
            <Route exact path={onboarding.commodity} component={Commodity} />
            <Route
              exact
              path={onboarding.additionalServices}
              component={AdditionalServices}
            />
            <Route
              exact
              path={onboarding.userInfo}
              component={UserInformation}
            />
            <Route
              exact
              path={onboarding.onboardingSuccessful}
              component={Success}
            />

            <Redirect
              to={{
                pathname: onboarding.shipmentLanes,
                search,
              }}
            />
          </Switch>
        </OnboardingProvider>
      </Route>

      <Route path="/auth/link">
        <MagicLinkLogin />
      </Route>
      <Route path="/auth/link-sent">
        <LinkSent />
      </Route>
      <Route path="*">
        <Redirect to={`${auth.login}${redirectUrl ? redirectUrl : ''}`} />
      </Route>
    </Switch>
  );
}
