import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export default async function request(
  path,
  method,
  {
    body,
    headers,
    baseUrl,
    params,
    withCredentials = true,
    cancelToken,
    signal,
  } = {}
) {
  return axios({
    method,
    withCredentials,
    baseURL: baseUrl ? baseUrl : API_URL,
    url: path,
    cancelToken,
    params,
    data: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    signal,
  });
}

export async function graphQLRequest(params) {
  return request('/graphql', 'post', params);
}

/**
 * Checks if the GraphQL response has any errors, if yes raise it.
 * @param { Object } response Axios response object with GraphQL response
 */
export async function assertGraphQLError(response) {
  const hasErrors = response?.data?.errors?.length > 0;

  if (!hasErrors) {
    return response;
  }

  const firstError = response?.data?.errors[0];
  const error = { data: firstError };
  throw error;
}
