import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CTAButton,
  Eyebrow,
  GridColumn,
  Heading,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import Calendar from '@nuvocargo/nuvo-styleguide/Calendar';
import { Checkbox } from '@nuvocargo/nuvo-styleguide/forms/native';

import { useRoute } from 'screens/routes/routes-index/hooks';
import Layout from 'components/common/layout/authenticated';
import { Flex, MovingTruck, Spinner } from 'components/generic/kit';
import { useMediaQueries } from 'core/hooks';
import { ADDITIONAL_SERVICES } from 'core/utils/constants';
import { app } from 'routes/paths';

import { usePickupDatesLogic } from '../hooks';

import {
  CityWithCountryFlag,
  FetchRouteError,
  NewShipmentHeader,
  RouteSummary,
  TrashButton,
} from './components';
import {
  CustomCalendarPopperContainer,
  FooterActionsWrapper,
  PickupDatesTable,
  PickupDatesTableAction,
  PickupDatesTableFooter,
  PickupDatesTableRow,
  RouteDetailsFixedContainer,
  RouteDetailsSmallContainer,
} from './styles';

export function SelectPickupDatesScreen() {
  const { t } = useTranslation();
  const { isSm } = useMediaQueries();

  const {
    isError: isRouteError,
    isLoading: isRouteLoading,
    route,
    refetch: refetchRoute,
  } = useRoute();

  const {
    headers,
    isRequestLoading,
    onAddAnotherDateHandler,
    onDatePickerChangeHandler,
    onRemovePickupDateHandler,
    onAdditionalsChangeHandler,
    onRequestShipmentsClickHandler,
    pickups,
  } = usePickupDatesLogic({ route });

  const additionalServices = ADDITIONAL_SERVICES(t);

  return (
    <Layout paddingTop={0}>
      {isRouteLoading ? (
        <GridColumn
          md={12}
          styles={{
            root: {
              display: 'flex',
              justifyContent: 'center',
              marginTop: 64,
            },
          }}>
          <MovingTruck />
        </GridColumn>
      ) : isRouteError ? (
        <GridColumn md={12}>
          <FetchRouteError onRetry={refetchRoute} />
        </GridColumn>
      ) : (
        <>
          <GridColumn
            md={isSm ? 12 : 8}
            styles={{
              root: {
                padding: isSm ? '60px 35px 30px' : '60px 0 30px',
              },
            }}>
            <NewShipmentHeader
              step={t('shipment-request-header-pickup-dates-step')}>
              <Text
                color="darkGray"
                styles={{
                  regular: {
                    display: 'block',
                    marginTop: 8,
                    fontSize: 14,
                  },
                }}>
                {t('review-route-request')}
              </Text>
            </NewShipmentHeader>

            <PickupDatesTable>
              <PickupDatesTableRow>
                {headers.map(header => (
                  <Eyebrow
                    key={header}
                    color="darkGray"
                    text={t(header)}
                    styles={{
                      root: { flex: 1 },
                    }}
                  />
                ))}
              </PickupDatesTableRow>

              {pickups.map(pickup => (
                <PickupDatesTableRow key={pickup.id}>
                  <CityWithCountryFlag
                    city={pickup.origin.city}
                    country={pickup.origin.country}
                  />

                  <CityWithCountryFlag
                    city={pickup.destination.city}
                    country={pickup.destination.country}
                  />

                  <Flex styles={{ root: { alignItems: 'center' } }}>
                    <Calendar
                      error={pickup.error}
                      onChange={(_, date) =>
                        onDatePickerChangeHandler(date, pickup.id)
                      }
                      placeholder={t(
                        'shipment-request-pickup-date-placeholder'
                      )}
                      popperContainer={CustomCalendarPopperContainer}
                      showPopperArrow={false}
                      styles={{
                        customInput: {
                          border: 'unset',
                          borderBottom: '1px solid transparent',
                          borderColor: pickup.error ? 'red' : 'transparent',
                          borderRadius: 'unset',
                          backgroundColor: 'rgba(64, 64, 64, 0.03)',
                        },
                      }}
                      value={pickup.date}
                    />
                    <TrashButton
                      disabled={pickup.disabled}
                      onClick={() => onRemovePickupDateHandler(pickup.id)}
                    />
                  </Flex>
                </PickupDatesTableRow>
              ))}

              <PickupDatesTableFooter>
                <PickupDatesTableAction onClick={onAddAnotherDateHandler}>
                  <Text color="forest">
                    {t('shipment-request-add-pickup-date')}
                  </Text>
                </PickupDatesTableAction>

                {pickups.some(({ error }) => error) && (
                  <Text
                    size="small"
                    color="red"
                    styles={{ small: { textAlign: 'right' } }}>
                    {t('shipment-request-required-pickup-dates-error')}
                  </Text>
                )}
              </PickupDatesTableFooter>
            </PickupDatesTable>

            <section className="my-12">
              <Heading variant="h5" color="green">
                {t('request-additional-services')}
              </Heading>

              <div className="mt-8 flex flex-col gap-6">
                {additionalServices.map(as => (
                  <label
                    key={`additional-services-${as.value}`}
                    className="flex cursor-pointer flex-row items-start gap-4">
                    <Checkbox
                      id={as.value}
                      name="additional_services"
                      value={as.value}
                      size="medium"
                      onChange={onAdditionalsChangeHandler}
                    />

                    <div className="flex flex-col">
                      <Text size="regular">{as.label}</Text>
                      <Text size="small" color="steel">
                        {as.description}
                      </Text>
                    </div>
                  </label>
                ))}
              </div>
            </section>

            {isSm && (
              <RouteDetailsSmallContainer>
                <RouteSummary route={route} />
              </RouteDetailsSmallContainer>
            )}

            <FooterActionsWrapper>
              <CTAButton
                as={RouterLink}
                colorType="secondary"
                isLink
                to={{
                  pathname: app.shipments.new.root,
                }}>
                {t('back')}
              </CTAButton>

              <CTAButton
                disabled={isRequestLoading}
                onClick={onRequestShipmentsClickHandler}>
                {isRequestLoading ? (
                  <Spinner color="white" />
                ) : (
                  <Text color="inherit">{t('shipment-request-confirm')}</Text>
                )}
              </CTAButton>
            </FooterActionsWrapper>
          </GridColumn>

          {!isSm && (
            <GridColumn md={4}>
              <RouteDetailsFixedContainer>
                <RouteSummary route={route} />
              </RouteDetailsFixedContainer>
            </GridColumn>
          )}
        </>
      )}
    </Layout>
  );
}
