import { gql } from 'graphql-request';

export const NOTIFICATIONS_AND_NOTIFICATION_SETTINGS_QUERY = gql`
  query customerui_getNotificationAndNotificationSettings(
    $userId: String!
    $category: String
  ) {
    notifications(category: $category) {
      nodes {
        id
        name
        description
        enabled
        category
        notificationSettingForUser(userId: $userId) {
          id
          enabled
        }
      }
    }
  }
`;
