import React from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { CTAButton, Text } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

import { CheckBox as QuoteCheckBox } from 'components/generic/form';

const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
`;

const FlexItem = styled.div`
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
  max-width: 659px;
  width: 100%;
`;

const BtnContainer = styled.div`
  margin-top: ${({ topSpacing }) => (topSpacing ? topSpacing : '40px')}
  margin-bottom: ${({ bottomSpacing }) =>
    bottomSpacing ? bottomSpacing : '40px'}
  display: flex;
  justify-content: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
`;

export default function NewRoutesAdditionalServicesForm() {
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <FlexColumn>
        <FlexAddServiceItem style={{ minWidth: '-webkit-fill-available' }}>
          <QuoteCheckBox
            name="customs"
            icon="customs"
            label={t('customs')}
            description={t('customs-description')}
          />
        </FlexAddServiceItem>
        <FlexAddServiceItem
          style={{ marginTop: '8px', minWidth: '-webkit-fill-available' }}>
          <QuoteCheckBox
            name="crossBorderInsurance"
            icon="insurance"
            label={t('cross-Border-insurance')}
            description={t('cross-Border-insurance-description')}
          />
        </FlexAddServiceItem>
        <FlexAddServiceItem
          style={{ marginTop: '8px', minWidth: '-webkit-fill-available' }}>
          <QuoteCheckBox
            name="financing"
            icon="financing"
            label={t('trade-financing')}
            description={t('trade-financing-description')}
          />
        </FlexAddServiceItem>
      </FlexColumn>

      <BtnContainer topSpacing="36px">
        <CTAButton
          type="submit"
          styles={{
            primary: {
              width: '212px',
            },
          }}>
          <Text color="white">{t('next')}</Text>
        </CTAButton>
      </BtnContainer>
    </FormWrapper>
  );
}

export const FlexAddServiceItem = styled(FlexItem)`
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        padding: 0px 25px;
      }
      @media (max-width: ${theme.mediaSizes.mobileL}) {
        padding: 0px;
      }
    `}
`;
