import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@nuvocargo/nuvo-styleguide';

const ImpersonationBanner = ({ currentUser }) => {
  const { t } = useTranslation();

  return (
    <Heading
      variant="h6"
      styles={{ root: { marginLeft: '10px' } }}
      color="white">
      {`${t('impersonating')} - ${currentUser.impersonatedEmail}`}
    </Heading>
  );
};

export default ImpersonationBanner;
