import React from 'react';
import styled from 'styled-components';
import { Grid, GridColumn, Heading, Text } from '@nuvocargo/nuvo-styleguide';

import Layout from 'components/common/layout/unauthenticated';

export default function ExpiryPage({ title, subtitle, children }) {
  return (
    <Layout>
      <Grid
        styles={{
          root: {
            alignItems: 'center',
            gridTemplateRows: '90vh',
          },
        }}>
        <GridColumn startMd={5} md={4} sm={4}>
          <Header>
            <Heading variant="h3" color="green">
              {title}
            </Heading>
            <Text color="steel">{subtitle} </Text>
          </Header>
        </GridColumn>
      </Grid>
      {children}
    </Layout>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  & > * + * {
    margin-top: 16px;
  }
`;
