import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { stringify } from 'qs';
import { omit, isNil, isEmpty } from 'ramda';

import { useQueryParams } from './useQueryParams';

export const useQueryParamsState = (parameterKey, initialValue) => {
  const qp = useQueryParams();
  const history = useHistory();

  const updateQueryParam = newValue => {
    let newQp = omit([parameterKey], qp);

    if (!isNil(newValue) && !isEmpty(newValue)) {
      newQp[parameterKey] = newValue;
    }

    history.push({
      search: stringify(newQp, { encode: false }),
    });
  };

  useEffect(() => {
    // Won't override the URL value if it exists, but if it does not, we'll set
    // the URL value to the passed initialValue
    if (initialValue && !qp[parameterKey]) {
      updateQueryParam(initialValue);
    }
  }, []);

  return [qp[parameterKey], updateQueryParam];
};
