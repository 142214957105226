import React, { useMemo } from 'react';
import { Eyebrow, Text } from '@nuvocargo/nuvo-styleguide';

import { LocationCell } from '../components';

/**
 * Creates array of headers for routes table based on `useTable` requirements
 * @param { Object[] } routes Routes (see Nuvocargo's GraphQL API schema for RouteType)
 * @param { string } path Current window path
 * @param { import("i18next").TFunction } t Translation function
 * @see https://react-table.tanstack.com/docs/api/useTable#column-options
 * @returns { import("react-table").Column[] }
 */
export default function createRoutesTableHeaders(routes, path, t) {
  return useMemo(
    () => [
      {
        Header: <Eyebrow text={t('route-origin')} color="darkGray" />,
        accessor: 'origin',
        Cell: row => (
          <LocationCell
            data-testid="routes-table-cell-origin"
            location={row.cell.value}
          />
        ),
      },
      {
        Header: <Eyebrow text={t('route-destination')} color="darkGray" />,
        accessor: 'destination',
        Cell: row => (
          <LocationCell
            data-testid="routes-table-cell-destination"
            location={row.cell.value}
          />
        ),
      },
      {
        Header: <Eyebrow text={t('truck-type')} color="darkGray" />,
        accessor: 'truckType',
        shrinkToContent: true,
        Cell: row => (
          <Text data-testid="routes-table-cell-truck-type">
            {t(row.cell.value?.toLowerCase())}
          </Text>
        ),
      },
      {
        Header: <Eyebrow text={t('commodity')} color="darkGray" />,
        accessor: 'commodities.nodes[0].name',
        Cell: row => (
          <Text data-testid="routes-table-cell-commodity">
            {row.cell.value}
          </Text>
        ),
      },
    ],
    [routes, t]
  );
}
