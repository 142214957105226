export const auth = {
  login: '/auth/login',
  forgotPassword: '/password/forgot',
  resetPassword: '/password/reset',
};

export const onboarding = {
  root: '/welcome',
  // Step 1
  shipmentLanes: '/welcome/shipment-lanes',
  // Step 2
  commodity: '/welcome/commodity',
  // Step 3
  additionalServices: '/welcome/additional-services',
  // Step 4
  userInfo: '/welcome/user-information',
  // Step 5/Completion
  onboardingSuccessful: '/welcome/success',
  completeProfile: '/complete-profile',
};

export const app = {
  insights: '/insights',
  routes: {
    root: '/routes',
    details: '/routes/:id',
    new: {
      root: '/routes/new',
      commodity: '/routes/commodity',
      additionalServices: '/routes/additional-services',
      success: '/routes/success',
    },
  },
  billing: '/billing',
  billingPdf: '/billing/:shipmentName',
  settings: {
    root: '/settings',
    account: '/settings/account',
    notifications: '/settings/notifications',
  },
  account: '/account',
  shipments: {
    root: '/shipments',
    details: '/shipments/:id',
    new: {
      root: '/shipments/new',
      success: '/shipments/new/success',
      pickupDates: '/shipments/new/:routeId',
    },
  },
};
