import * as yup from 'yup';

export const constructInitialValues = (neededFields = {}) => {
  let initialValues = {};
  if (neededFields.password) initialValues.password = '';
  if (neededFields.roles) initialValues.roles = [];
  if (neededFields.acceptTerms) initialValues.acceptTerms = false;
  if (neededFields.title) initialValues.title = '';
  return initialValues;
};

export const createRoleOptions = t => [
  {
    value: 'ops',
    label: t('role-logistics-operations'),
  },
  { value: 'admin', label: t('role-admin') },
  { value: 'executive', label: t('role-owner-excutive') },
  { value: 'sales', label: t('role-sales-marketing') },
  {
    value: 'money',
    label: t('role-accounting-finance-billing'),
  },
  { value: 'warehouse', label: t('role-warehouse') },
  { value: 'customer-support', label: t('role-customer-support') },
  { value: 'business-development', label: t('role-business-development') },
  {
    value: 'director',
    label: t('role-director-manager-supervisor'),
  },
];

const getRoleDescription = (role, t) => {
  return {
    ops: {
      value: 'ops',
      label: t('role-logistics-operations'),
    },
    admin: { value: 'admin', label: t('role-admin') },
    executive: { value: 'executive', label: t('role-owner-excutive') },
    sales: { value: 'sales', label: t('role-sales-marketing') },
    money: {
      value: 'money',
      label: t('role-accounting-finance-billing'),
    },
    warehouse: { value: 'warehouse', label: t('role-warehouse') },
    'customer-support': {
      value: 'customer-support',
      label: t('role-customer-support'),
    },
    'business-development': {
      value: 'business-development',
      label: t('role-business-development'),
    },
    director: {
      value: 'director',
      label: t('role-director-manager-supervisor'),
    },
  }[role];
};

export const getRoles = (roles, t) => {
  if (!roles || !roles.length) {
    return [];
  }
  return roles.map(role => getRoleDescription(role, t));
};

export const constructValidationSchema = ({
  t,
  validatePassword,
  neededFields,
}) => {
  let validationsShape = {};
  if (neededFields.password)
    validationsShape.password = yup
      .string()
      .required(t('valdiation-password-required'))
      .test('password-valdiation', true, validatePassword);
  if (neededFields.role)
    validationsShape.role = yup.array().required('validation-role-required');
  if (neededFields.acceptTerms)
    validationsShape.acceptTerms = yup
      .bool()
      .oneOf([true], t('valdiation-acceptTerms-required'));
  if (neededFields.title)
    validationsShape.title = yup
      .string()
      .required(t('validation-title-required'));
  return yup.object().shape(validationsShape);
};
