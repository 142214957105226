export async function downloadFileFromStorage(fileUrl, fileName = '') {
  const fileUrlObject = new URL(fileUrl);
  const fileNameFromUrl = fileUrlObject.pathname.split('/').pop();

  // Evaluates null, undefined and '' to false to use fileNameFromUrl
  const fileNameValue = fileName || fileNameFromUrl;

  try {
    const response = await fetch(fileUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    });

    const blob = await response.blob();
    const fileBlobUrl = URL.createObjectURL(blob);

    const downloadAttr = document.createAttribute('download');
    downloadAttr.value = fileNameValue;

    const a = document.createElement('a');
    a.href = fileBlobUrl;
    a.setAttributeNode(downloadAttr);
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    throw new Error(`An error ocurred downloading the file: ${error.message}`);
  }
}
