import { assertGraphQLError, graphQLRequest } from 'core/utils/request';
import { INSIGHTS_QUERY } from 'core/graphql/queries';

export const fetchInsightsQL = async ({ companyId, states, signal }) =>
  graphQLRequest({
    body: {
      query: INSIGHTS_QUERY,
      variables: {
        companyId,
        state: states,
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(r => {
      const data = r?.data?.data?.shipments;

      return (
        data || {
          totalCount: 0,
          pageInfo: {},
          nodes: [],
        }
      );
    });
