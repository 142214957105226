import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

html, body, #root {
  background: #faf8f6;
  height: 100vh;
}

body {
  font-family: "value-sans-regular", sans-serif;
  font-size: 16px;
  margin: 0;
  overflow-x: hidden;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.mapboxgl-popup-tip {
  border: unset !important;
}

.mapboxgl-popup-content {
  background: #75968c !important;
}

button,
input, select:focus {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

// Align
.tl {
  text-align: left;
}
.tr {
  text-align: right;
}
.tc {
  text-align: center;
}

// Text transform
.ttc {
  text-transform: capitalize;
}

// Font Weight
.fw300 {
  font-weight: 300;
}

// Links
a {
  color: inherit;
  text-decoration: none;
}
a.dark-green {
  color: #26463f;
}
.active {
  color: #1B473F;
}


// Margins
.center {
  margin: 0 auto;
}
.mv05 {
  margin-bottom: 5px;
  margin-top: 5px;
}
.mv1 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.mv2 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.ml1 {
  margin-left: 10px;
}

// Paddings
.pv1 {
  padding-bottom: 8px;
  padding-top: 10px;
}
.pv2 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.pt3 {
  padding-top: 30px;
}
.pb2 {
  padding-bottom: 20px;
}
.ph3 {
  padding-left: 30px;
  padding-right: 30px;
}
.pr2 {
  padding-right: 20px;
}

// Floats
.clear {
  clear: both;
}
.left {
  float: left;
}
.right {
  float: right;
}

// Display
.dn {
  display: none;
}
.db {
  display: block;
}
.inline {
  display: inline-block;
}

.dn-sm {
  ${({ theme }) => `
  @media only screen and (max-width: ${theme.mediaSizes.mobileL}) {
    display: none;
  }
  `}
  }

.dn-ns {
  ${({ theme }) => `
  @media only screen and (min-width: ${theme.mediaSizes.mobileL}) {
    display: none;
  }
  `}
  }



// Position
.absolute {
  position: absolute;
}
.bottom {
  bottom: 0;
}
.right {
  right: 0;
}


// Error
.field-error {
  color: red;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.4rem 0.7rem;
}

// Fonts
.gt-medium {
  font-family: "gt-medium", sans-serif;
}
.gt-bold {
  font-family: "gt-bold", sans-serif;
}
.value-sans-regular {
  font-family: "value-sans-regular", sans-serif;
}
.value-sans-medium {
  font-family: "value-sans-medium", sans-serif;
}

// Datepicker
.SingleDatePickerInput__withBorder {
  width: 100%;
}
.DateInput {
  width: 75%;
}
@media only screen and (max-device-width: 768px) {
  .DateInput {
    width: 80%;
  }
}
.DateInput .DateInput_input {
  font-size: 14px !important;
  height: 58px;
  font-weight: 600;
}

.Toastify__toast-container {
  --toastify-color-light: #404040;
  --toastify-color-success: #307460;
  --toastify-color-warning: #FFD232;
  --toastify-color-error: #CE1126;
  --toastify-color-info: #404040;
  --toastify-text-color-light: #FFFFFF;
  --toastify-text-color-warning: #404040;
  --toastify-icon-color-warning: #404040;

  .Toastify__toast {
    .Toastify__close-button {
      align-self: center;
      > svg {
        height: 24px;
        width: 24px;
      }
    }
    &.Toastify__toast--warning {
      .Toastify__close-button {
        > svg {
          color: var(--toastify-icon-color-warning)
        }
      }
    }
  }
}

.banner-container {
  --toastify-color-light: #404040;
  --toastify-color-success: #307460;
  --toastify-color-warning: #FFD232;
  --toastify-color-error: #CE1126;

  --toastify-text-color-light: #FFFFFF;
  --toastify-text-color-warning: #404040;

  --toastify-icon-color-warning: #404040;

  && {
    width: 100%;
    position: inherit;
    padding: 0;

    .Toastify__toast {
      border-radius: 0;
      margin-bottom: 0;
      padding-left: 44px;
      padding-right: 50px;
      cursor: auto;

      .Toastify__close-button {
        align-self: center;
        > svg {
          height: 24px;
          width: 24px;
        }
      }

      &.Toastify__toast--warning {
        .Toastify__close-button {
          > svg {
            color: var(--toastify-icon-color-warning)
          }
        }
      }
    }
  }

}
`;

export default GlobalStyle;
