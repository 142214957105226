import { useQuery } from 'react-query';
import { CancelToken } from 'axios';

import request from 'core/utils/request';

export const useGeolocationsRequest = ({ filterName, filterType }) =>
  useQuery(['geolocations', filterType, filterName], () => {
    const source = CancelToken.source();

    const promise = getGeolocations({
      filterName,
      filterType,
      cancelToken: source.token,
    });

    promise.cancel = () => {
      source.cancel(
        'Request canceled to avoid race conditions, newer request was made'
      );
    };

    return promise;
  });

export const getGeolocations = ({ filterName, filterType, cancelToken }) => {
  const params = {
    'filter[name]': filterName,
    'filter[type]': filterType,
    fields: 'id,name,country,parents',
  };
  return request('/v0/geolocations', 'get', {
    params,
    cancelToken,
  }).then(r => r.data);
};
