import React from 'react';

import { theme } from 'styles';
import { Box } from 'components/generic/kit';

const getStackAxis = ({ axis }) => {
  if (!axis) return;
  const isVertical = axis === 'vertical';
  return {
    flexDirection: isVertical ? 'column' : 'row',
  };
};

const getStackSpace = ({ axis, space }) => {
  if (!space || !axis) return;
  const isVertical = axis === 'vertical';
  return {
    '&>*+*': {
      marginTop: 'unset',
      marginLeft: 'unset',
      [isVertical ? 'marginTop' : 'marginLeft']: space,
    },
  };
};

const getStackStyles = ({ axis, space }) => {
  return {
    ...getStackAxis({ axis }),
    ...getStackSpace({ axis, space }),
  };
};

export default function Stack({
  space,
  axis = 'vertical',
  center,
  children,
  styles,
  ...props
}) {
  const sm = `@media only screen and ${theme.device.sm}`;

  return (
    <Box
      styles={{
        root: {
          display: 'flex',
          alignItems: center && 'center',
          ...getStackStyles({
            space: Array.isArray(space) ? space[1] : space,
            axis: Array.isArray(axis) ? axis[1] : axis,
          }),
          ...styles?.root,
          [sm]: {
            ...getStackStyles({
              space: Array.isArray(space) ? space[0] : space,
              axis: Array.isArray(axis) ? axis[0] : axis,
            }),
            ...styles?.root?.[sm],
          },
        },
      }}
      {...props}>
      {children}
    </Box>
  );
}
