import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Icon, Text } from '@nuvocargo/nuvo-styleguide';
export default function BackButton({ color = 'forest', onClick, ...props }) {
  const { t } = useTranslation();
  let history = useHistory();
  const handleClick = onClick ? onClick : () => history.goBack();
  return (
    <Back style={{ cursor: 'pointer' }} onClick={handleClick} {...props}>
      <Icon name="keyboardArrowLeft" color={color} alt="Go back" />
      <Text
        color={color}
        styles={{
          regular: {
            marginLeft: '10px',
            display: 'none',
            [`@media(min-width: 968px)`]: {
              display: 'block',
            },
          },
        }}>
        {' '}
        {t('back')}
      </Text>
    </Back>
  );
}

export const Back = styled.div`
  display: flex;
  align-items: center;

  > img {
    margin-right: 10px;
  }
`;

export const BackText = styled.span`
  color: #ddf2ff;
  font-size: 16px;
  font-weight: 500;
  display: none;

  @media (min-width: ${({ theme }) => theme.mediaSizes.sm}) {
    display: block;
  }
`;
