import { useTranslation } from 'react-i18next';
import { countryShortNameToFlag } from '@nuvocargo/nuvo-styleguide/lib/utils';
import { colorWithAlpha } from '@nuvocargo/nuvo-styleguide/lib/theme';
import { IconTextCell } from '@nuvocargo/nuvo-styleguide';

import { whenRouteIs, hasPartialDetails } from 'core/utils/route';

const PlaceCell = ({ value, type, row, ...rest }) => {
  const { t } = useTranslation();
  const route = row.original;

  const textColor = whenRouteIs(route, {
    missingDetails: 'darkGrey',
    requested: 'darkGrey',
    unserviceable: 'steel',
    expired: 'steel',
    fallback: 'green',
  });

  const description = whenRouteIs(route, {
    missingDetails: hasPartialDetails(route[type])
      ? `${value?.zipCode?.parents?.city}, ${value?.zipCode?.name}`
      : t('priced-routes-incomplete'),
    requested: t('priced-routes-incomplete'),
    unserviceable: '',
    expired: '',
    fallback: `${value?.zipCode?.parents?.city}, ${value?.zipCode?.name}`,
  });

  const icon = whenRouteIs(route, {
    missingDetails: hasPartialDetails(route[type])
      ? {
          name: countryShortNameToFlag(
            value?.zipCode?.parents?.country || 'placeholder'
          ),
          color: 'darkGrey',
        }
      : {
          name: 'errorOutline',
          color: 'darkGrey',
        },
    requested: {
      name: 'errorOutline',
      color: 'darkGrey',
    },
    unserviceable: {
      name: 'errorOutline',
      color: 'steel',
    },
    expired: {
      name: 'errorOutline',
      color: 'steel',
    },
    fallback: {
      name: countryShortNameToFlag(
        value?.zipCode?.parents?.country || 'placeholder'
      ),
      color: 'darkGrey',
    },
  });

  const backgroundColor = whenRouteIs(route, {
    missingDetails: colorWithAlpha('darkGrey/3'),
    requested: colorWithAlpha('darkGrey/3'),
    fallback: 'inherit',
  });

  return (
    <IconTextCell
      text={value?.name}
      textColor={textColor}
      description={description}
      icon={icon}
      styles={{
        root: {
          height: '100%',
          backgroundColor,
        },
      }}
    />
  );
};

export default PlaceCell;
