import { useParams } from 'react-router-dom';
import { last } from 'ramda';

import { PDFViewerPage } from 'components/generic/kit/PDFViewerPage';
import { app } from 'routes/paths';
import { useInvoices } from 'core/hooks';

const getLastFileOfTypeFromInvoices = (invoices = [], type = 'pdf') =>
  last(invoices.filter(invoice => invoice.type.includes(type)))?.downloadUrl;

export function BillingPdf() {
  const { shipmentName } = useParams();
  const { data, isLoading } = useInvoices(
    {
      searchFor: shipmentName,
      perPage: 1,
    },
    {
      select: ({ data }) => data?.records?.[0],
    }
  );

  if (isLoading) {
    return null;
  }

  const { fileName, invoices } = data;

  const lastPdfInvoice = getLastFileOfTypeFromInvoices(invoices, 'pdf');
  const lastXmlInvoice = getLastFileOfTypeFromInvoices(invoices, 'xml');

  return (
    <PDFViewerPage
      fileName={fileName}
      pdfFile={lastPdfInvoice}
      xmlFile={lastXmlInvoice}
      backRoute={app.billing}
    />
  );
}
