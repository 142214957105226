import axios from 'axios';

import request, {
  assertGraphQLError,
  graphQLRequest,
} from 'core/utils/request';
import {
  SHIPMENT_QUERY,
  SHIPMENT_REQUESTS_MUTATION,
  SHIPMENTS_QUERY,
} from 'core/graphql';
import {
  SHIPMENTS_API_ENDPOINT,
  SHIPPER_API_VERSION,
} from 'core/utils/constants';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Retrieves shipments from API using a GraphQL query filtered by `companyId`.
 */
export const fetchShipmentsQL = async ({
  companyId,
  states,
  signal,
  cursor,
  pageSize,
  searchQuery,
}) =>
  graphQLRequest({
    body: {
      query: SHIPMENTS_QUERY,
      variables: {
        companyId,
        first: pageSize,
        state: states,
        after: cursor,
        searchFor: searchQuery || undefined,
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(r => {
      const data = r?.data?.data?.shipments;

      return (
        data || {
          totalCount: 0,
          pageInfo: {},
          nodes: [],
        }
      );
    });

/**
 * Retrieves a single Shipment by `shipment.id` filtered by `companyId`.
 */
export const fetchShipment = async ({ companyId, shipmentId, signal }) =>
  graphQLRequest({
    body: {
      query: SHIPMENT_QUERY,
      variables: {
        companyId,
        shipmentId,
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(r => {
      let shipment = r?.data?.data?.shipments?.nodes?.[0] ?? null;
      return shipment;
    });

/**
 * Send a GraphQL mutation to create shipment requests
 * @param { Object } params
 * @param { { routeId: number; readyForPickup: string }[] } params.requests
 */
export async function createShipmentRequestQL({ requests, signal }) {
  return graphQLRequest({
    body: {
      query: SHIPMENT_REQUESTS_MUTATION,
      variables: {
        requests,
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(r => {
      const result = r?.data?.data?.createShipmentRequest ?? null;
      return result;
    });
}

export const createShipmentRequest = data =>
  request('/shipments', 'post', { body: data });

export const getNewShipmentFormData = () => request('/shipments/new', 'get');

export const getCompanyBalance = options =>
  request(`${SHIPPER_API_VERSION}/shipments/billing_status`, 'get', options);

export const createShipmentAttachments = async ({ shipmentId, body }) => {
  try {
    return await axios.patch(
      `${API_URL}${SHIPMENTS_API_ENDPOINT}/${shipmentId}/uploads`,
      body,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    );
  } catch (e) {
    console.error(e);
  }
};
