import React from 'react';
import styled, { css } from 'styled-components/macro';

const Root = styled.div``;

export default function Box({ children, styles = { root: {} }, ...props }) {
  return (
    <Root css={css(styles.root)} {...props}>
      {children}
    </Root>
  );
}
