import React from 'react';
import { isEmpty } from 'ramda';
import styled, { css } from 'styled-components';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  GridColumn,
  Heading,
  Icon,
  CTAButton,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import {
  RouteAutoComplete,
  TextField,
} from '@nuvocargo/nuvo-styleguide/forms/formik';

import { theme } from 'styles';

import {
  CloseIcon,
  FormWrapper,
  LocationArrayErrors,
  LocationWrapper,
  Row,
} from './styles';
import {
  handlePostalCodeAutoCompleteKeyDown,
  addRoute,
  autocompleteRouteLocations,
} from './utils';

export default function NewRoutesRequest(props) {
  const { values, errors, setErrors } = props;
  const { t } = useTranslation();
  const sm = `@media only screen and ${theme.device.sm}`;

  const isEmptyRoute = route =>
    isEmpty(route.destination) ||
    isEmpty(route.origin) ||
    isEmpty(route.loadsPerMonth);

  return (
    <Grid>
      <GridColumn startMd={1} md={12} sm={4}>
        <FormWrapper>
          <FieldArray
            name="routes"
            render={arrayHelpers =>
              values.routes.map((route, index) => {
                return (
                  <React.Fragment key={route.id}>
                    <Row key={route.id}>
                      <Heading
                        color="blue"
                        variant="h2B"
                        styles={{
                          root: {
                            [sm]: {
                              display: 'none',
                            },
                          },
                        }}>
                        {index + 1}
                      </Heading>

                      <LocationWrapper>
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            textAlign: 'left',
                          }}>
                          <RouteAutoComplete
                            id={`routes[${index}].origin`}
                            name={`routes[${index}].origin`}
                            placeholder={t(
                              'onboarding-routes-origin-postal-code-placeholder'
                            )}
                            getOptionValue={option => option.id}
                            loadOptions={autocompleteRouteLocations}
                            onChange={() => {
                              setErrors({});
                            }}
                            styles={{
                              root: {
                                width: '100%',
                              },
                            }}
                            onKeyDown={handlePostalCodeAutoCompleteKeyDown}
                            loadingMessage={() =>
                              t('onboarding-routes-loading')
                            }
                            openMenuOnFocus={false}
                            openMenuOnClick={false}
                          />
                        </Row>

                        <Row
                          style={{
                            justifyContent: 'space-between',
                            textAlign: 'left',
                          }}>
                          <RouteAutoComplete
                            id={`routes[${index}].destination`}
                            name={`routes[${index}].destination`}
                            placeholder={t(
                              'onboarding-routes-destination-postal-code-placeholder'
                            )}
                            getOptionValue={option => option.id}
                            loadOptions={autocompleteRouteLocations}
                            onChange={addRoute(values, index, arrayHelpers)}
                            onKeyDown={handlePostalCodeAutoCompleteKeyDown}
                            loadingMessage={() =>
                              t('onboarding-routes-loading')
                            }
                            openMenuOnFocus={false}
                            openMenuOnClick={false}
                            styles={{
                              root: {
                                width: '100%',
                              },
                            }}
                          />
                        </Row>
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            textAlign: 'left',
                          }}>
                          <TextField
                            id={`routes[${index}].loadsPerMonth`}
                            name={`routes[${index}].loadsPerMonth`}
                            type="number"
                            placeholder={t('loads-per-month-placeholder')}
                            styles={{
                              root: {
                                width: '100%',
                              },
                            }}
                          />
                        </Row>
                      </LocationWrapper>

                      <CloseIcon
                        isFirst={index === 0 && values.routes.length === 1}
                        isHidden={isEmptyRoute(route)}>
                        <Icon
                          name="close"
                          onClick={
                            index === 0 && values.routes.length === 1
                              ? () => {}
                              : () => arrayHelpers.remove(index)
                          }
                          color="steel"
                        />
                      </CloseIcon>
                    </Row>
                    <LocationArrayErrors errors={errors} route={route.id} />
                  </React.Fragment>
                );
              })
            }></FieldArray>

          <Row style={{ margin: 0 }}>
            <CloseIcon isHidden>
              <Icon name="close" color="steel" />
            </CloseIcon>
          </Row>
          <SvgWrapper>
            <Iconography name="truck" styles={{ marginRight: '8px' }} />
            <Text color="steel">{t('only-dry-van-offer')}</Text>
          </SvgWrapper>
          <BtnContainer bottomSpacing="60px">
            <CTAButton
              type="submit"
              styles={{
                primary: {
                  width: '212px',
                },
              }}>
              <Text color="white">{t('next')}</Text>
            </CTAButton>
          </BtnContainer>
        </FormWrapper>
      </GridColumn>
    </Grid>
  );
}

const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;
  height: 42px;
  ${({ theme }) =>
    css`
      @media (max-width: ${theme.mediaSizes.mobileL}) {
        height: 40px;
        svg {
          display: none;
        }
      }
    `}
`;

const BtnContainer = styled.div`
  margin-top: ${({ topSpacing }) => (topSpacing ? topSpacing : '40px')}
  margin-bottom: ${({ bottomSpacing }) =>
    bottomSpacing ? bottomSpacing : '40px'}
  display: flex;
  justify-content: center;
`;
