import { StatusFilledCell, TextCell } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

import { centsToDollars, formatMoneyWithCurrency } from 'core/utils/lib';
import { whenRouteIs } from 'core/utils/route';

import PlaceCell from './PlaceCell';

const useRouteTable = () => {
  const { t } = useTranslation();
  const columns = [
    {
      Header: t('priced-routes-table-header-route-id'),
      accessor: 'id',
      Cell: ({ cell: { value, row } }) => {
        const textColor = whenRouteIs(row.original, {
          expired: 'steel',
          unserviceable: 'steel',
          fallback: 'green',
        });

        return (
          <TextCell
            textColor={textColor}
            fontType="eyebrow"
            text={`RT-${value}`}
          />
        );
      },
    },
    {
      Header: t('route-origin'),
      accessor: 'origin',
      Cell: ({ ...props }) => <PlaceCell type="origin" {...props} />,
    },
    {
      Header: t('route-destination'),
      accessor: 'destination',
      Cell: ({ ...props }) => <PlaceCell type="destination" {...props} />,
    },
    {
      Header: t('priced-routes-table-header-price'),
      accessor: 'convertedPriceCents',
      Cell: ({ cell: { value, row } }) => {
        const textColor = whenRouteIs(row.original, {
          expired: 'steel',
          unserviceable: 'steel',
          requested: 'steel',
          fallback: 'blue',
        });

        const text = whenRouteIs(row.original, {
          expired: t('priced-routes-status-expired'),
          unserviceable: '--',
          requested: t('priced-routes-price-in-progress'),
          fallback: formatMoneyWithCurrency(
            centsToDollars(value),
            row.original.currency
          ),
        });

        return <TextCell textColor={textColor} text={text} />;
      },
    },
    {
      Header: t('shipments'),
      accessor: 'shipments.totalCount',
      Cell: ({ cell: { value, row } }) => {
        const text = whenRouteIs(row.original, {
          expired: '',
          unserviceable: '',
          fallback: String(value),
        });

        return <TextCell text={text} />;
      },
    },
    {
      Header: t('priced-routes-table-header-status'),
      accessor: 'status',
      Cell: ({ cell: { row } }) => {
        const text = whenRouteIs(row.original, {
          active: t('priced-routes-status-active'),
          ready: t('priced-routes-status-ready'),
          missingDetails: t('priced-routes-status-in-progress'),
          expired: t('priced-routes-status-expired'),
          unserviceable: t('priced-routes-status-unserviceable'),
          requested: t('priced-routes-status-requested'),
          fallback: '--',
        });

        const status = whenRouteIs(row.original, {
          active: 'success',
          ready: 'success',
          missingDetails: 'warning',
          unserviceable: 'error',
          fallback: 'info',
        });

        return <StatusFilledCell text={text} status={status} />;
      },
    },
  ];
  return {
    columns,
  };
};

export default useRouteTable;
