import React from 'react';
import { Text } from '@nuvocargo/nuvo-styleguide';

import { TwoRowDetails } from 'components/generic/kit';

import { CityWithCountryFlag } from './CityWithCountryFlag';

/**
 * Tow row component with Country (label & flag) and name of location
 * @param { Object } params
 * @param { Object } params.location See Nuvocargo's GraphQL API Schema for `PlaceType`
 */
export function LocationCell({ location, ...props }) {
  return (
    <TwoRowDetails
      bottom={<Text color="darkGray">{location?.name}</Text>}
      top={
        <CityWithCountryFlag
          city={location?.zipCode?.parents?.city}
          country={location?.zipCode?.parents?.country}
        />
      }
      {...props}
    />
  );
}
