import { isEmpty } from 'ramda';
import { useMutation } from 'react-query';

import { commodityOptionToValue } from 'core/utils';
import { createRoute } from 'core/api/routes';
import { useNewRoutes } from 'context/routes';
import { app } from 'routes/paths';

const DRY_VAN = '53_foot_dry_van';

export const useSubmitNewRoute = ({ isMobile }) => {
  const { goTo, resetNewRoutesProcess } = useNewRoutes();

  const createRouteMutation = useMutation(createRoute, {
    onSuccess: () => {
      goTo(app.routes.new.success, {});
      resetNewRoutesProcess();
    },
  });

  return {
    isLoading: createRouteMutation.isLoading,
    error: createRouteMutation.error,
    createLaneRequest: data => {
      createRouteMutation.mutate(createRequest(data, isMobile));
    },
  };
};

const createRequest = (
  {
    customs,
    crossBorderInsurance,
    financing,
    routes,
    preferredBorder,
    category,
    name,
    valuePerLoad,
    weight,
    hazmat,
    fumigation,
    straps,
    notes,
    specialHandling,
    showShipmentValue,
    directService,
    firstName,
    lastName,
    companyName,
    email,
    password,
    acceptTerms,
    phoneNumber,
  },
  isMobile
) => {
  return {
    additionalServices: {
      customs,
      crossBorderInsurance,
      financing,
    },
    commodity: {
      category: category.value,
      name,
      valuePerLoad: showShipmentValue ? valuePerLoad.value : 100000,
      weight: weight.value,
      extraStrapsCount: isMobile
        ? commodityOptionToValue(straps)
        : commodityOptionToValue(specialHandling[1].required),
      specialRequirements: {
        hazmat,
        fumigation: isMobile
          ? commodityOptionToValue(fumigation)
          : commodityOptionToValue(specialHandling[0].required),
        straps: isMobile
          ? !!commodityOptionToValue(straps)
          : !!commodityOptionToValue(specialHandling[1].required),
      },
    },
    notes,
    serviceType: directService ? 'direct' : 'transload',
    truckType: DRY_VAN,
    routes: filterEmptyRoutes(routes),
    preferredBorder: preferredBorder.value,
    firstName,
    lastName,
    companyName,
    email,
    password,
    acceptTerms,
    phoneNumber,
  };
};

const filterEmptyRoutes = routes =>
  routes
    .filter(item => !isEmptyFieldArray(item))
    .map(({ origin, destination }) => ({
      origin: {
        geolocationId: origin.id,
      },
      destination: {
        geolocationId: destination.id,
      },
    }));

const isEmptyFieldArray = route =>
  isEmpty(route.destination) || isEmpty(route.origin);
