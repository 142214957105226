import React, { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import {
  Heading,
  Link,
  CTAButton,
  Text,
  Label,
  Icon,
  Modal,
  ToastContainer,
  Toast,
} from '@nuvocargo/nuvo-styleguide';
import { TextField, Dropdown } from '@nuvocargo/nuvo-styleguide/forms/native';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { useForgotPassword } from 'screens/password/forgot/hooks';
import { openBanner } from 'core/hooks/use-banner';
import { theme } from 'styles';
import { ErrorMessage } from 'components/generic/form';
import { useUser } from 'context/user';
import { useLanguageUpdate } from 'screens/account/profile/hooks';

const Account = () => {
  const { t } = useTranslation();
  const { currentUser } = useUser();
  const [isResetPasswordModal, setResetPasswordModal] = useState(false);
  const [isSuccessfullySaved, setSuccessfullySaved] = useState(false);
  const {
    changeLanguage,
    languageValue,
    isLoadingLanguageUpdate,
    onSelectChange,
  } = useLanguageUpdate();

  const matchesSize = useMediaQuery(`(min-width: ${theme.mediaSizes.laptop})`);

  const languages = [
    { label: 'English', value: 'en', icon: { name: 'usa' } },
    { label: 'Español', value: 'es', icon: { name: 'mexico' } },
  ];

  const getUsedLanguageOption = () => {
    let lng = 'en';
    if (languageValue === 'es') {
      lng = 'es';
    }

    return languages.find(l => l.value === lng);
  };

  const openResetPasswordModal = () => {
    setResetPasswordModal(true);
  };

  const closeResetPasswordModal = () => setResetPasswordModal(false);

  const { sendEmail, isLoading, error } = useForgotPassword({
    onSuccessCallback: () =>
      openBanner({
        key: 'modal-forgot-success',
        children: (
          <div className="flex">
            <Icon name="check" color="white" />
            <Heading
              variant="h6"
              styles={{ root: { marginLeft: '10px' } }}
              color="white">
              {t('password-forgot-email-sent')}
            </Heading>
          </div>
        ),
        timeout: 3000,
      }),
  });

  const onSaveClick = useCallback(() => {
    changeLanguage(languageValue)();
    setSuccessfullySaved(true);
  }, [languageValue, changeLanguage]);

  const onCloseToast = useCallback(() => {
    setSuccessfullySaved(false);
  }, []);

  return (
    <div className="flex flex-col rounded p-8">
      <div className="flex flex-col justify-start gap-10 nuvo-sm:flex-row nuvo-sm:gap-20">
        <div className="flex-2 gap-y-0.5 nuvo-laptop:mr-0">
          <Heading variant="h5">{t('account-settings')}</Heading>
          <Label color="steel">{t('account-settings-label')}</Label>
        </div>

        <div className="mt-4 flex flex-1 flex-col items-start gap-4">
          <TextField
            value={`${currentUser.firstName} ${currentUser.lastName}`}
            label={t('full-name')}
            styles={{ root: { width: '100%' } }}
            disabled
          />
          <TextField
            value={currentUser.email}
            label={t('email')}
            styles={{ root: { width: '100%' } }}
            disabled
          />
          <TextField
            label={t('password')}
            name="password"
            type="password"
            value="********"
            styles={{ root: { width: '100%' } }}
            disabled
          />
          <div className="self-end">
            <Link onClick={openResetPasswordModal}>
              {t('reset-password-title')}
            </Link>
            <Modal
              isOpen={isResetPasswordModal}
              closeModal={closeResetPasswordModal}
              styles={{
                root: {
                  width: '432px',
                },
              }}>
              <Text
                styles={{
                  regular: {
                    textAlign: 'center',
                  },
                }}>
                {t('reset-password-confirmation')} {currentUser?.email}.{' '}
                {t('are-you-sure')}?
              </Text>
              {error?.data?.error && (
                <ErrorMessage backgroundColor="taupe">
                  {error.data.error}
                </ErrorMessage>
              )}
              <div className="mt-4">
                <CTAButton
                  onClick={async () => {
                    await sendEmail({ email: currentUser.email });
                    closeResetPasswordModal();
                  }}
                  disabled={isLoading}>
                  {t('send-reset-link-btn')}
                </CTAButton>
              </div>
            </Modal>
          </div>
          <TextField
            value={currentUser.companyName}
            label={t('settings-company')}
            styles={{ root: { width: '100%' } }}
            disabled
          />
          <Dropdown
            label={t('language-input-label')}
            options={languages}
            value={getUsedLanguageOption()}
            onChange={onSelectChange}
            styles={{ root: { width: '100%' } }}
          />
        </div>
      </div>
      <div className="nuvo-sm:mt-12 nuvo-sm:w-full nuvo-sm:border-t"></div>
      <div className="mt-12 self-center nuvo-sm:mt-4 nuvo-sm:self-end">
        <CTAButton
          id="settings-save-button"
          style={{ marginLeft: '12px' }}
          disable={isLoadingLanguageUpdate}
          onClick={onSaveClick}
          styles={{
            primary: {
              width: matchesSize ? '' : '224px',
            },
          }}>
          {t('save-settings-button')}
        </CTAButton>
      </div>
      {isSuccessfullySaved && (
        <ToastContainer>
          <Toast type="success" onClose={onCloseToast}>
            <p>{t('accounts-settings-saved-successfully')}</p>
          </Toast>
        </ToastContainer>
      )}
    </div>
  );
};

export default Account;
