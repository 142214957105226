import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useAccessorials } from 'core/hooks/queries';
import OnBoarding from 'components/common/layout/unauthenticated/onboarding';
import { onboarding as onBoardingPaths } from 'routes/paths';
import NewRoutesCommodityForm from 'screens/routes/newRouteForm/commodity/index';
import {
  createInitialValues,
  validationSchema,
} from 'screens/routes/newRouteForm/commodity/data';
import { useOnboarding } from 'context/onboarding';

export default function Commodity() {
  const { answers, goTo } = useOnboarding();
  const { data: accessorials } = useAccessorials();
  const { t } = useTranslation();

  return (
    <OnBoarding
      title={t('onboarding-commodity-title')}
      subtitle={t('onboarding-commodity-subtitle')}>
      <Formik
        initialValues={createInitialValues(answers, t)}
        validationSchema={validationSchema(t)}
        onSubmit={goTo(onBoardingPaths.additionalServices)}>
        {({ values }) => (
          <NewRoutesCommodityForm
            accessorials={accessorials ?? []}
            values={values}
          />
        )}
      </Formik>
    </OnBoarding>
  );
}
