import React from 'react';
import { useTranslation } from 'react-i18next';

import { JourneyCardItem } from './JourneyCardItem';
import { JourneyCardWrapper } from './styles';

/**
 * Vertical journey with pickup and dropoff
 * @param { Object } props
 * @param { Object } props.origin
 * @param { string } props.origin.addressLine1
 * @param { string } props.origin.addressLine2
 * @param { string } props.origin.name
 * @param { Object } props.origin.zipCode
 * @param { Object } props.origin.zipCode.parents
 * @param { string } props.origin.zipCode.parents.country
 * @param { Object } props.destination
 * @param { string } props.destination.addressLine1
 * @param { string } props.destination.addressLine2
 * @param { string } props.destination.name
 * @param { Object } props.destination.zipCode
 * @param { Object } props.destination.zipCode.parents
 * @param { string } props.destination.zipCode.parents.country
 */
export function JourneyCard({ origin, destination }) {
  const { t } = useTranslation();

  const originAddress = origin?.addressLine1 + ` ${origin?.addressLine2 || ''}`;
  const destinationAddress =
    destination?.addressLine1 + ` ${destination?.addressLine2 || ''}`;

  return (
    <JourneyCardWrapper>
      {Boolean(origin) && (
        <JourneyCardItem
          country={origin?.zipCode?.parents?.country}
          type={t('journey-card-pickup-label')}
          heading={origin.name || t('unknown')}
          details={originAddress || t('unknown')}
          showVerticalRuler
        />
      )}

      {Boolean(destination) && (
        <JourneyCardItem
          country={destination?.zipCode?.parents?.country}
          type={t('journey-card-dropoff-label')}
          heading={destination.name || t('unknown')}
          details={destinationAddress || t('unknown')}
        />
      )}
    </JourneyCardWrapper>
  );
}
