import React from 'react';
import { Formik, Form } from 'formik';
import {
  Grid,
  GridColumn,
  CTAButton,
  Heading,
  Text,
  usePageHeader,
} from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/formik';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ErrorMessage } from 'components/generic/form';
import Layout from 'components/common/layout/unauthenticated';

import { initialValues, validationSchema } from './data';
import { useForgotPassword } from './hooks';

function ForgotPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const { showBanner } = usePageHeader();

  const { sendEmail, error, isLoading, invalidTokenError } = useForgotPassword({
    onSuccess: () => {
      showBanner({
        children: t('password-forgot-email-sent'),
        type: 'success',
        show: true,
      });

      setTimeout(() => {
        history.push('/');
      }, 5000);
    },
  });

  return (
    <Grid
      styles={{
        root: {
          alignItems: 'center',
          gridTemplateRows: '100vh',
        },
      }}>
      <GridColumn startMd={5} md={4} sm={4}>
        <Header>
          <Heading variant="h3" color="green">
            {t(`forgot-password-title${invalidTokenError}`)}
          </Heading>
          <Text color="steel">
            {t(`forgot-password-subtitle${invalidTokenError}`)}
          </Text>
        </Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema(t)}
          onSubmit={sendEmail}>
          <Form>
            <Row>
              <TextField
                label={t('email')}
                type="email"
                name="email"
                placeholder={t('placeholder-email')}
              />
            </Row>

            {error?.response?.data?.error && (
              <Row>
                <ErrorMessage backgroundColor="taupe">
                  {error?.response.data.error}
                </ErrorMessage>
              </Row>
            )}
            <Row alignCenter>
              <CTAButton type="submit" disabled={isLoading}>
                {t(`forgot-password-btn${invalidTokenError}`)}
              </CTAButton>
            </Row>
          </Form>
        </Formik>
      </GridColumn>
    </Grid>
  );
}

export default function ForgotPasswordWithLayout() {
  return (
    <Layout>
      <ForgotPassword />
    </Layout>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  & > * + * {
    margin-top: 16px;
  }
`;

const Row = styled.div`
  margin: 40px 0;
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`;
