/* eslint-disable react/display-name */
import React from 'react';
import { Eyebrow, Heading, CTAButton, Text } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

import { theme } from 'styles';
import { Stack } from 'components/generic/kit';

import { formatPrice } from '../utils';

export default function TotalBalanceCard({
  isLoading,
  totalBalance,
  overdueBalance,
  currency,
  label,
  onPayNow,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        axis={['vertical', 'horizontal']}
        space={['16px', '40px']}
        styles={{
          root: {
            marginTop: '24px',
            width: '100%',
            padding: '54px 30px 46px',
            borderRadius: '8px',
            alignItems: 'center',
            backgroundColor: theme.colors.darkCream,
            [`@media only screen and ${theme.device.sm}`]: {
              alignItems: 'flex-start',
              width: 'unset',
            },
          },
        }}>
        <Stack space="12px">
          {!isLoading && <Eyebrow text={label} color="steel" size="small" />}

          <Stack axis="horizontal" center space="8px">
            <Heading variant="h2B" color={isLoading ? 'steel' : 'blue'}>
              {isLoading ? t('loading') : formatPrice(totalBalance)}
            </Heading>
            {!isLoading && (
              <Eyebrow
                text={currency}
                color="blue"
                size="small"
                styles={{
                  small: {
                    marginTop: '8px',
                  },
                }}
              />
            )}
          </Stack>
          <Text color={overdueBalance > 0 ? 'lightRed' : 'steel'}>
            {!isLoading &&
              `${t('overdue-balance')}: ${formatPrice(overdueBalance)}`}
          </Text>
        </Stack>
        {!isLoading && (
          <CTAButton size="small" onClick={onPayNow}>
            {t('pay-now')}
          </CTAButton>
        )}
      </Stack>
    </>
  );
}
