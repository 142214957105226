import React from 'react';
import { Eyebrow, Heading } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

import CaretDown from '../CaretDown';
import CaretUp from '../CaretUp';
export default function InsightsBlock({
  title,
  result,
  displayCondition,
  percentageChange: { value, color, caret },
}) {
  return (
    <StyledBlock>
      <StyledBlockWrapper data-testid="insights-metrics">
        <Eyebrow text={title} size="small" color="grey"></Eyebrow>
        <Heading variant="h2B" color="blue" component="div">
          {result}
        </Heading>
      </StyledBlockWrapper>
      {displayCondition && value !== 0 && (
        <PercentageContainer data-testid="insights-percentage">
          <div style={{ marginRight: '8px' }}>
            {caret === 'down' && (
              <CaretDown color={color} data-testid="caret-down"></CaretDown>
            )}
            {caret === 'up' && (
              <CaretUp color={color} data-testid="caret-up"></CaretUp>
            )}
          </div>
          <Heading variant="h4" color={color} component="span">
            {value} %
          </Heading>
        </PercentageContainer>
      )}
    </StyledBlock>
  );
}

const PercentageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBlock = styled.div`
  background-color: #f3f1ef;
  position: relative;
  border: 1px solid #f1f0ef;
  margin: 10px;
  padding: 20px;
  text-align: left;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.mediaSizes.mobileL}) {
    padding: 54px 39px 47px 30px;
    width: 33%;
    margin: 0 10px 10px 0;

    :last-child {
      margin: 0 0 10px 0;
    }
  }
`;

const StyledBlockWrapper = styled.div`
  padding: 0 30px 0 20px;
  text-align: left;

  > span:first-of-type {
    margin-bottom: 7px;
  }

  ${({ theme }) => `
  @media only screen and (min-width: ${theme.mediaSizes.mobileL}) and (max-width: ${theme.mediaSizes.tablet}) {
    padding: 0 2px 0 2px;
  }
  @media only screen and (min-width: ${theme.mediaSizes.tablet}) and (max-width: ${theme.mediaSizes.laptop}) {
    padding: 0 30px 0 20px;
  }
  @media only screen and (min-width: ${theme.mediaSizes.laptop}) and (max-width: ${theme.mediaSizes.laptopL}) {
    padding: 0;
  }
  @media only screen and (min-width: ${theme.mediaSizes.laptopL}) {
    padding: 0;
  }
  `};
`;
