/**
 * Parses the cursor returned by GraphQL queries in the `after` and `febore`
 * fields to convert them to offsets.
 * Those cursors are hashed as Base64 strings.
 * If not cursor, or anything different than a string is provided, 0 will be
 * returned.
 * @param { string } cursor Base64 string holding an offset.
 * @returns { number } Offset
 */
export function getOffsetFromCursor(cursor = '') {
  if (!cursor || typeof cursor !== 'string') {
    return 0;
  }

  const offsetStr = window.atob(cursor);

  if (isNaN(offsetStr)) {
    return 0;
  }

  return Number(offsetStr);
}

/**
 * Converts an offset into a Base64 representation that GraphQL pagination
 * can understand.
 * @param { number } offset Items to skip
 * @returns { string } Base64 string holding an offset
 */
export function getCursorFromOffset(offset = 0) {
  if (!offset || typeof offset !== 'number') {
    return;
  }

  return window.btoa(String(offset)).replace(/=/gi, '');
}

/**
 * Calculates offset based on page size and a given page
 * @param { number } pageSize
 * @param { number } page
 * @returns { number } Offset
 */
export function getOffsetFromPageNumber(pageSize, page = 0) {
  return pageSize * page;
}
