import React from 'react';
import * as flag from '@nuvocargo/nuvo-styleguide/Icons/Flags';
import styled from 'styled-components';

import { getCountryName } from 'core/utils/lib';

/**
 * `img` wrapper that resolves the corresponding country flag based on the
 * `country` prop. Any other prop is forwarded to the `img` element.
 * @param { Object } props
 * @param { string } props.country
 * @param { string } props.marginRight CSS valid value. Default 0px
 * @param { boolean } props.strict If true, and a flag is not found for the provided
 * country, the resulting JSX will be `null`.
 * @param { string } props.width CSS valid value. Default 10px
 */
export function CountryFlag({
  country,
  marginRight = '0px',
  strict = false,
  width = '10px',
  ...props
}) {
  const locationCountryFlag = flag[getCountryName(country)];

  if (strict && !locationCountryFlag) {
    return null;
  }

  return (
    <CountryFlagImg
      marginRight={marginRight}
      src={locationCountryFlag}
      width={width}
      {...props}
    />
  );
}

const CountryFlagImg = styled.img`
  aspect-ratio: 1;
  flex: none;
  margin-right: ${({ marginRight }) => marginRight};
  width: ${({ width }) => width};
`;
CountryFlagImg.displayName = 'CountryFlagImg';
