import { gql } from 'graphql-request';

export const SHIPMENTS_QUERY = gql`
  query customerui_getShipments(
    $first: Int
    $after: String
    $companyId: String
    $state: [ShipmentStateEnum!]
    $searchFor: String
  ) {
    shipments(
      first: $first
      after: $after
      companyId: $companyId
      state: $state
      searchFor: $searchFor
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        externalId
        folioNumber
        state
        customerReference
        createdAt
        route {
          company {
            id
          }
        }
        origin {
          ...placeFields
        }
        destination {
          ...placeFields
        }
        lastOccurredShipmentEvent {
          ...shipmentEventFields
        }
        shipmentEvents(first: 50) {
          totalCount
          nodes {
            ...shipmentEventFields
          }
        }
      }
    }
  }

  fragment placeFields on Place {
    name
    zipCode {
      parents {
        city
        country
      }
    }
  }

  fragment shipmentEventFields on ShipmentEvent {
    actualDatetime
    id
    type
  }
`;
