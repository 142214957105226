import { gql } from 'graphql-request';

export const ROUTE_QUERY = gql`
  query customerui_getRoutes(
    $companyId: String!
    $routeId: ID!
    $state: RouteStateEnum!
  ) {
    routes(companyId: $companyId, id: $routeId, state: $state) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        origin {
          ...placeFields
        }
        destination {
          ...placeFields
        }
        truckType
        shipmentRequirements
        serviceType
        commodities {
          nodes {
            name
            category
          }
        }
      }
    }
  }
  fragment placeFields on Place {
    addressLine1
    addressLine2
    name
    zipCode {
      latitude
      longitude
      parents {
        city
        state
        country
      }
    }
  }
`;

export const ROUTE_BY_ID_QUERY = gql`
  query getRouteByID($id: ID!) {
    routes(id: $id, first: 1) {
      nodes {
        priceState
        convertedPriceCents
        convertedFuelCostCents
        currency
        status {
          is
        }
        legQuotes {
          origin {
            latitude
            longitude
          }
          destination {
            latitude
            longitude
          }
        }
        destination {
          ...placeFields
        }
        id
        origin {
          ...placeFields
        }
        company {
          id
          legalName
          variableFuel
        }
        handleBorderCrossing
        serviceType
        shipmentRequirements
        truckType
        commodities(first: 1) {
          nodes {
            name
            category
            weight
            valuePerLoadCents
            extraStrapsCount
            accessorials {
              slug
              name
            }
          }
        }
        timeInTransit
      }
    }
  }

  fragment placeFields on Place {
    id
    addressLine1
    addressLine2
    name
    contactName
    contactTelephone
    zipCode {
      id
      name
      latitude
      longitude
      parents {
        city
        state
        country
      }
    }
    facilityProcedure {
      appointmentType
      facilityInstructions
      loadingScheme
      loadingDuration
      placeId
      receivingDays
      startTime
      endTime
    }
  }
`;
