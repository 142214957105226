import React, { createContext } from 'react';

import { useAuth } from 'core/hooks/useAuth';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { isAuthenticated, currentUser, backUrl, isLoading } = useAuth();

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        backUrl,
        currentUser,
        isLoading,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
}
