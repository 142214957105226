import clsx from 'clsx';
import printJS from 'print-js';
import React, { useState, Fragment } from 'react';
import { MdArrowBack, MdDownload, MdPrint } from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { downloadFileFromStorage } from 'core/utils/file';
import tailwindConfig from 'styles/tailwind-config.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

/**
 * Full page PDF viewer component. Uses react-pdf & printJS for buttons.
 */
export function PDFViewerPage(props) {
  const { t } = useTranslation();
  const { fileName = '', pdfFile, xmlFile } = props;
  const [numPages, setNumPages] = useState(null);
  const isSm = useMediaQuery(`(min-width: ${tailwindConfig.sm})`);
  const isLg = useMediaQuery(`(min-width: ${tailwindConfig.lg})`);
  const history = useHistory();

  let pdfPageWidth;

  /**
   * Change the width of the PDF to be viewable on different size screens.
   */
  if (isSm) {
    if (isLg) {
      // width for viewport >1024
      pdfPageWidth = 800;
    } else {
      // width for viewport between 640 - 1024
      // undefined means it will get the page width in pdf document
      pdfPageWidth = undefined;
    }
  } else {
    // width for viewport <640
    pdfPageWidth = 350;
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const onClickDownload = fileUrl => e => {
    e.preventDefault();
    downloadFileFromStorage(fileUrl, fileName);
  };

  return (
    <div className="flex h-screen flex-col font-sans ">
      <header className="flex w-full justify-between gap-2 bg-nuvo-gray py-2 px-2 md:items-center md:py-4 md:px-7 ">
        <button
          className="flex items-center gap-3 rounded text-nuvo-white  underline-offset-2 outline-none hover:underline focus:ring focus:ring-nuvo-blue/75 md:px-2 md:text-lg"
          onClick={() => {
            history.goBack();
          }}>
          <MdArrowBack />
          {fileName}
        </button>

        <div className="flex justify-end gap-2 md:gap-4  ">
          {xmlFile ? (
            <Menu as="div" className="relative z-10 inline-block text-left">
              <Menu.Button className="inline-flex items-center gap-1 rounded-full px-4 py-3 text-base text-white shadow-sm hover:bg-nuvo-forest/75 focus:outline-none focus:ring-2 focus:ring-nuvo-forest focus:ring-offset-2 focus:ring-offset-nuvo-gray sm:bg-nuvo-forest md:gap-3 md:px-4">
                <span className="hidden sm:block">
                  {t('PDFViewer-download')}
                </span>
                <MdDownload className=" h-5 w-5 " />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute -left-10 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-nuvo-gray-dark shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-0">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={clsx(
                            'group flex w-full items-center rounded-md px-2 py-2 text-sm ',
                            active
                              ? 'bg-nuvo-white/25  text-white'
                              : 'text-nuvo-steel-soft '
                          )}
                          onClick={onClickDownload(pdfFile, fileName)}>
                          {t('PDFViewer-download-pdf')}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={clsx(
                            'group flex w-full items-center rounded-md px-2 py-2 text-sm ',
                            active
                              ? 'bg-nuvo-white/25  text-white'
                              : 'text-nuvo-steel-soft '
                          )}
                          onClick={onClickDownload(xmlFile, fileName)}>
                          {t('PDFViewer-download-xml')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <button
              className={clsx(
                'inline-flex items-center gap-1 rounded-full px-4 py-3 text-base text-white shadow-sm sm:bg-nuvo-forest md:gap-3 md:px-4',
                'hover:bg-nuvo-forest/75 focus:outline-none focus:ring-2 focus:ring-nuvo-forest focus:ring-offset-2 focus:ring-offset-nuvo-gray'
              )}
              onClick={onClickDownload(pdfFile, fileName)}>
              <span className="hidden sm:block">{t('PDFViewer-download')}</span>
              <MdDownload className=" h-5 w-5 " />
            </button>
          )}

          <button
            className={clsx(
              'inline-flex items-center gap-1 rounded-full py-3 px-4 text-nuvo-white md:gap-3',
              'hover:bg-nuvo-forest/75 focus:outline-none focus:ring-2 focus:ring-nuvo-forest focus:ring-offset-2 focus:ring-offset-nuvo-gray sm:hover:bg-nuvo-white/5'
            )}
            onClick={() => {
              printJS(pdfFile);
            }}>
            <span className="hidden sm:block">{t('PDFViewer-print')}</span>
            <MdPrint className=" h-4 w-4 " />
          </button>
        </div>
      </header>
      <main className="h-full  overflow-x-hidden overflow-y-scroll bg-nuvo-gray/50 py-10">
        <div className="flex flex-col items-center">
          <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={pdfPageWidth}
              />
            ))}
          </Document>
        </div>
      </main>
    </div>
  );
}

PDFViewerPage.propTypes = {
  /**
   * File name used in top nav bar for back button
   */
  fileName: PropTypes.string.isRequired,

  /**
   * URL location of PDF to display
   */
  pdfFile: PropTypes.string.isRequired,

  /**
   * URL location of xml to download
   */
  xmlFile: PropTypes.string.isRequired,
};
