import * as R from 'ramda';
import { useQuery } from 'react-query';
import { useState } from 'react';

import { fetchInsightsQL } from 'core/api/insights';
import { differenceInDaysFromNow } from 'core/utils/lib';
import { useUser } from 'context/user';

import { formatInsights, csvColumns, formatCSVModel } from './model';
import {
  calculatePercentages,
  averageMetrics,
  shippingDuration,
  getShipmentEventByType,
} from './utils';

export const useInsights = () => {
  const [csvData, setCsvData] = useState();
  const [selectedDate, setSelectedDate] = useState('oneMonth');
  const { currentUser } = useUser();

  const companyId = String(currentUser?.companyId);

  const { data, isLoading, error } = useQuery(
    ['ql_insights', companyId],
    ({ signal }) =>
      fetchInsightsQL({
        companyId,
        signal,
        states: 'COMPLETED',
      }),
    {
      enabled: Boolean(currentUser?.id),
      onSuccess: ({ nodes }) => {
        setCsvData(
          R.reduce(
            (acc, item) => acc.concat([formatCSVModel(item)]),
            [csvColumns],
            nodes || []
          )
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  const filters = {
    oneMonth: 30,
    twoMonths: 60,
    all: 0,
  };

  const records = R.propOr([], 'nodes', data);

  const onFilterChange = setSelectedDate;

  const filterInsights = insights => {
    return insights?.filter(item => {
      if (filters[selectedDate] === 0) return item;
      return (
        differenceInDaysFromNow(
          getShipmentEventByType(
            item.shipmentEvents,
            'DELIVERED_TO_DESTINATION'
          )?.node?.actualDatetime
        ) <= filters[selectedDate]
      );
    });
  };

  const filteredInsights = R.pipe(formatInsights, filterInsights)(records);

  let analytics = {};
  const costs = filteredInsights.map(o => o.priceInCents);

  const insightsDuration = shippingDuration(filteredInsights);

  let filteredShippingTimes = insightsDuration(
    'unformattedDeliveryTime',
    'unformattedPickupTime'
  ).filter(item => !Number.isNaN(item));

  analytics.averageCost = averageMetrics(costs);
  analytics.totalShipments = filteredInsights.length;
  analytics.averageTimeTransit = averageMetrics(filteredShippingTimes);
  return {
    isLoading,
    error,
    filteredInsights,
    onFilterChange,
    csvData,
    selectedDate,
    analytics,
    percentages: calculatePercentages(records),
  };
};
