import React from 'react';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';

import { CountryFlag } from '../CountryFlag';

import {
  JourneyCardItemVerticalRule,
  JourneyCardItemWrapper,
  JourneyCardItemWrapperBody,
  JourneyCardItemWrapperBodyDetails,
  JourneyCardItemWrapperHeader,
  JourneyCardLeftItemWrapper,
} from './styles';

/**
 *
 * @param { Object } props
 * @param { 'mexico' | 'usa' | 'canada' } props.country Supported country name
 * @param { string } props.details Secondary text
 * @param { number } props.flagSize Value used for flag width and height
 * @param { string } props.heading Primary text
 * @param { boolean } props.showVerticalRuler Whether or not display a vertical separator bellow the flag
 * @param { string } props.type Top label, i.e: Pickup or Dropoff
 */
export function JourneyCardItem({
  country,
  details,
  flagSize = 24,
  heading,
  showVerticalRuler = false,
  type,
}) {
  return (
    <JourneyCardItemWrapper>
      <JourneyCardItemWrapperHeader>
        <CountryFlag
          alt={`${country} flag icon`}
          country={country}
          marginRight="12px"
          width={`${flagSize}px`}
        />
        <Text
          color="steel"
          size="small"
          styles={{
            small: {
              textTransform: 'capitalize',
            },
          }}>
          {type}
        </Text>
      </JourneyCardItemWrapperHeader>

      <JourneyCardItemWrapperBody>
        <JourneyCardLeftItemWrapper marginRight="12px" width={`${flagSize}px`}>
          {showVerticalRuler && <JourneyCardItemVerticalRule />}
        </JourneyCardLeftItemWrapper>

        <JourneyCardItemWrapperBodyDetails>
          <Heading variant="h6">{heading}</Heading>
          <Text color="steel">{details}</Text>
        </JourneyCardItemWrapperBodyDetails>
      </JourneyCardItemWrapperBody>
    </JourneyCardItemWrapper>
  );
}
