import React from 'react';
import { CTAButton, Heading, Text } from '@nuvocargo/nuvo-styleguide';

import { Box } from 'components/generic/kit';

/**
 * Vertical stacked elements for usecases like Empty, Success and Error states.
 * @param { Object } props
 * @param { JSX.Element } props.icon
 * @param { string } props.header
 * @param { boolean } props.isHeaderOutstanding
 * @param { string } props.description
 * @param { { label: string, props: { [key: string]: any } } } props.primaryAction
 * @param { { label: string, props: { [key: string]: any } } } props.secondaryAction
 * @param { Object } props.styles
 */
export function StateCard({
  icon,
  header,
  isHeaderOutstanding = false,
  description,
  primaryAction,
  secondaryAction,
  styles = { root: {} },
}) {
  return (
    <Box
      data-testid="state-card"
      styles={{
        root: {
          display: 'flex',
          justifyContent: 'center',
          ...styles.root,
        },
      }}>
      <Box
        styles={{
          root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            rowGap: '16px',
            maxWidth: '420px',
          },
        }}>
        <Box
          styles={{
            root: {
              marginBottom: '12px',
            },
          }}>
          {icon}
        </Box>

        <Box styles={{ root: { marginBottom: '8px' } }}>
          <Heading
            data-testid="state-card-header"
            styles={{ root: { textAlign: 'center' } }}
            variant={isHeaderOutstanding ? 'h3' : 'h5'}>
            {header}
          </Heading>
        </Box>

        <Box styles={{ root: { marginBottom: '32px' } }}>
          <Text
            data-testid="state-card-description"
            color="steel"
            styles={{
              regular: {
                display: 'block',
                textAlign: 'center',
              },
            }}>
            {description}
          </Text>
        </Box>

        {Boolean(primaryAction) && (
          <Box styles={{ root: { marginBottom: '8px' } }}>
            <CTAButton
              data-testid="state-card-primary-action"
              colorType="primary"
              {...primaryAction.props}>
              {primaryAction.label}
            </CTAButton>
          </Box>
        )}

        {Boolean(secondaryAction) && (
          <Box>
            <CTAButton
              data-testid="state-card-secondary-action"
              colorType="secondary"
              {...secondaryAction.props}>
              {secondaryAction.label}
            </CTAButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}
