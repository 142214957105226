import React, { useState } from 'react';
import { CTAButton, Heading, Text } from '@nuvocargo/nuvo-styleguide';
import Modal from '@nuvocargo/nuvo-styleguide/Modals/Modal';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { createInvite } from 'core/api/user';

import { ReactComponent as SpinnerIcon } from '../../assets/images/icons/spinner.svg';
import { ReactComponent as FileFoundIcon } from '../../assets/images/icons/File_found.svg';

const emailsSchema = yup.array().of(yup.string().email());
const createInviteSchema = t =>
  yup.object().shape({
    emails: yup
      .string()
      .required()
      .test({
        test: emailsString => {
          const emails = emailsString?.split(',').map(email => email.trim());

          return emailsSchema.isValidSync(emails);
        },
      }),
  });

/**
 * Executes the mutation to create an invite with a minimum delay of 800ms
 *
 * @param {*} data
 * @returns
 */
const createInviteWithDelay = async data => {
  const [createInviteResult] = await Promise.allSettled([
    createInvite(data),
    new Promise(resolve => setTimeout(resolve, 800)),
  ]);

  if (createInviteResult.status === 'rejected') {
    throw createInviteResult.reason.data.message;
  }

  if (createInviteResult?.value?.createInvite?.success === false) {
    throw createInviteResult?.value?.createInvite;
  }

  return await createInviteResult?.value;
};

export const InviteModal = ({ isOpen, onOpenChange }) => {
  const createInviteMutation = useMutation(createInviteWithDelay);
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(1);

  const handleSubmit = data => {
    createInviteMutation.mutate(
      {
        ...data,
        language: i18n.language,
      },
      {
        onSuccess: () => {
          setStep(2);
        },
      }
    );
  };

  const failedExistingCompanyEmails =
    createInviteMutation?.error?.failedExistingCompanyEmails?.join(', ');

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      {step === 1 && (
        <Formik
          initialValues={{ emails: '' }}
          validationSchema={createInviteSchema}
          onSubmit={handleSubmit}>
          <Form className="container mx-auto">
            <Heading
              variant="h5"
              color="green"
              styles={{
                root: {
                  textAlign: 'center',
                },
              }}>
              {t('invite-title')}
            </Heading>
            <TextField
              name="emails"
              placeholder={t('invite-emailfield-placeholder')}
              label={t('invite-emailfield-label')}
              // can have two types of errors: isAdmin and invalid emails
              error={
                createInviteMutation?.error?.failedExistingCompanyEmails
                  ? `${t(
                      'invite-emailfield-error-failed'
                    )} ${failedExistingCompanyEmails}`
                  : createInviteMutation?.error
              }
            />
            <Text
              color="steel"
              as="p"
              styles={{
                regular: {
                  marginTop: '16px',
                  marginBottom: '48px',
                },
              }}>
              {t('invite-description')}
            </Text>
            <Modal.Footer>
              <CTAButton
                type="submit"
                disabled={createInviteMutation.isLoading}
                styles={{
                  common: {
                    minWidth: '128px',
                  },
                }}>
                {createInviteMutation.isLoading ? (
                  <SpinnerIcon className="h-5 w-5 animate-spin text-nuvo-white" />
                ) : (
                  t('invite-button')
                )}
              </CTAButton>
            </Modal.Footer>
          </Form>
        </Formik>
      )}
      {step === 2 && (
        <div className="flex flex-col items-center">
          <FileFoundIcon
            className="animate-bounce"
            style={{
              animationIterationCount: `2.5`,
            }}
          />
          <Heading
            variant="h5"
            color="green"
            styles={{
              root: {
                textAlign: 'center',
              },
            }}>
            {t('invite-success-title')}
          </Heading>
          <Text
            color="steel"
            as="p"
            styles={{
              regular: {
                marginTop: '8px',
                marginBottom: '48px',
              },
            }}>
            {t('invite-success-description')}
          </Text>
          <CTAButton
            onClick={() => {
              setStep(1);
            }}>
            {t('invite-success-button')}
          </CTAButton>
        </div>
      )}
    </Modal>
  );
};
