import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LastUsed from 'locize-lastused';
import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as R from 'ramda';
import { locizePlugin } from 'locize';

import { isDevelopment } from 'core/utils/lib';

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  referenceLng: 'en',
};

const englishOrSpanishRegex = /^(es|en)$/g;
const isValidLanguage = lng => {
  return R.not(R.isNil(lng.match(englishOrSpanishRegex)));
};

i18n
  .use(locizePlugin)
  .use(Backend)
  .use(LastUsed)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    saveMissing: isDevelopment ? true : false,
    debug: isDevelopment ? true : false,
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged editorSaved',
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupQuerystring: 'language',
    },
    fallbackLng: 'en',
    editor: {
      ...locizeOptions,
      autoOpen: isDevelopment ? true : false,
      onEditorSaved: async (lng, ns) => {
        await i18n.reloadResources(lng, ns);
        i18n.emit('editorSaved');
      },
    },
  })
  .then(() => {
    if (!isValidLanguage(i18n.language)) {
      i18n.changeLanguage('en');
    } else {
    }
  });

export default i18n;
