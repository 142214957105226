import { useMutation } from 'react-query';

import * as userApi from 'core/api/user';
import { useSignIn } from 'screens/auth/login/hooks';

export const useCompleteProfile = ({ email }) => {
  const { isSigningIn, errorData, signIn } = useSignIn();
  const {
    isLoading: isSigningUp,
    mutate,
    errors: signupErrors,
  } = useMutation(userApi.completeProfile, {
    onSuccess: async ({ data }, variables) => {
      signIn({
        email: data.user.email,
        ...variables,
      });
    },
  });

  return {
    isSigningUp: isSigningUp || isSigningIn,
    signupErrors: signupErrors || errorData,
    completeProfile: mutate,
  };
};
