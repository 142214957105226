import { useState } from 'react';
import * as FullStory from '@fullstory/browser';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useMutation, useQueryClient } from 'react-query';

import * as userApi from 'core/api/user';

export const useSignIn = () => {
  const [email, setEmail] = useState();
  const { i18n } = useTranslation();
  const { search } = useLocation();

  const { redirect } = qs.parse(search, { ignoreQueryPrefix: true });
  const queryClient = useQueryClient();
  const history = useHistory();

  const { isLoading, mutate, error } = useMutation(userApi.signIn, {
    onSuccess: async ({ data }) => {
      const { id, displayName, companyName, impersonatedEmail } = data;
      FullStory.identify(id, {
        email,
        displayName,
        companyName_str: companyName,
        language: i18n.language,
        ...(impersonatedEmail ? { impersonatedEmail } : {}),
      });

      await queryClient.invalidateQueries('check-auth');

      if (redirect) {
        history.push(redirect);
      }
    },
  });

  return {
    isSigningIn: isLoading,
    errorData: error?.response?.data,
    signIn: data => {
      setEmail(data.email);
      mutate(data);
    },
  };
};
