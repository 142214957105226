import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { createShipmentAttachments } from 'core/api/shipments';
import { useUser } from 'context/user';

/**
 * Executes the mutation to create an invite with a minimum delay of 800ms
 *
 * @param {*} data
 * @returns
 */
const createShipmentAttachmentsWithDelay = async data => {
  const [result] = await Promise.allSettled([
    createShipmentAttachments(data),
    new Promise(resolve => setTimeout(resolve, 800)),
  ]);

  if (result.status === 'rejected') {
    throw result.value;
  }

  return await result?.value?.data;
};

export const useShipmentAttachments = (queryConfig = {}) => {
  const queryClient = useQueryClient();
  const { id: shipmentId } = useParams();
  const { currentUser } = useUser();

  const companyId = String(currentUser?.companyId);

  return useMutation(createShipmentAttachmentsWithDelay, {
    ...queryConfig,
    onSuccess: (...args) => {
      queryClient
        .invalidateQueries(['customerui_getShipment', companyId, shipmentId])
        .then(() => {
          queryConfig?.onSuccess?.(...args);
        });
    },
  });
};
