import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import OnBoarding from 'components/common/layout/unauthenticated/onboarding';
import { useOnboarding } from 'context/onboarding';
import { onboarding as onBoardingPaths } from 'routes/paths';
import NewRoutesRequest from 'screens/routes/newRouteForm/newRoutesRequest';
import {
  validateLocations,
  initialValues,
} from 'screens/routes/newRouteForm/newRoutesRequest/data';

export default function ShipmentRoutes() {
  const { t } = useTranslation();
  const { goTo, answers } = useOnboarding();

  return (
    <OnBoarding
      title={t('onboarding-routes-title')}
      subtitle={t('onboarding-we-send-quote-for-each-route')}>
      <Formik
        onSubmit={goTo(onBoardingPaths.commodity)}
        initialValues={{
          ...initialValues,
          routes: (answers.routes || []).concat(initialValues.routes),
          ...answers,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={validateLocations(t)}>
        {({ values, errors, setErrors }) => (
          <NewRoutesRequest
            values={values}
            errors={errors}
            setErrors={setErrors}
          />
        )}
      </Formik>
    </OnBoarding>
  );
}
