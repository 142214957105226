export const csvColumns = () => [
  'Invoice ID',
  'Reference #',
  'Invoice date',
  'Due date',
  'Pickup date',
  'Paid date',
  'Total amount',
  'Paid amount',
  'Outstanding balance',
  'Pay to',
];
