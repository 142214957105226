import { isEmpty } from 'ramda';

import { isValidRoute } from 'screens/routes/utils';

export const BORDER_OPTIONS = [
  {
    label: 'Laredo, TX (Default)',
    value: 'Laredo, TX',
  },
  { label: 'El Paso, TX', value: 'El Paso, TX' },
  { label: 'McAllen, TX', value: 'McAllen, TX' },
  { label: 'Brownsville, TX', value: 'Brownsville, TX' },
];

export const initialValues = {
  preferredBorder: BORDER_OPTIONS[0],
  routes: [
    {
      id: 'dsfsd23',
      origin: '',
      destination: '',
      loadsPerMonth: '',
    },
  ],
};

const isValidLoadsPerMonth = loadsPerMonth =>
  loadsPerMonth > 0 && Number.isInteger(loadsPerMonth);

export const validateLocations =
  t =>
  ({ routes }) => {
    const errors = { routes: {} };
    const routesToValidate = routes.length > 1 ? routes.slice(0, -1) : routes;

    routesToValidate.forEach(route => {
      if (!isValidRoute(route)) {
        errors.routes[route.id] = t('invalid-shipping-route');
      } else if (!isValidLoadsPerMonth(route.loadsPerMonth)) {
        errors.routes[route.id] = t('invalid-loads-per-month');
      }
    });

    return isEmpty(errors.routes) ? {} : errors;
  };
