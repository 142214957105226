import * as R from 'ramda';

import { differenceInDaysFromNow } from 'core/utils/lib';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const durationFilters = t => {
  return {
    oneMonth: { label: t('last-30-days-filter'), value: 'oneMonth' },
    twoMonths: { label: t('last-60-days-filter'), value: 'twoMonths' },
    all: { label: t('all-filter'), value: 'all' },
  };
};

export const average = R.converge(R.divide, [R.sum, R.length]);

export const getShipmentEventByType = (shipmentEvents, type) =>
  shipmentEvents?.edges?.find(event => event?.node?.type === type);

export const shippingDuration = insights => () =>
  insights.map(shipment => {
    const deliveryTime = new Date(
      getShipmentEventByType(
        shipment.shipmentEvents,
        'DELIVERED_TO_DESTINATION'
      )?.node?.actualDatetime
    );
    const pickUpTime = new Date(
      getShipmentEventByType(
        shipment.shipmentEvents,
        'PICKED_UP_FROM_ORIGIN'
      )?.node?.actualDatetime
    );
    return Math.abs(dateDiffInDays(deliveryTime, pickUpTime));
  });

const calculateChangePercentage = (originalNumber, latestNumber) => {
  if (Number(originalNumber) === 0) return 0;
  return ((latestNumber - originalNumber) / originalNumber) * 100;
};

const filterOn =
  insights =>
  ({ minDate = 0, maxDate = 29 } = {}) => {
    return insights.filter(item => {
      return (
        differenceInDaysFromNow(
          getShipmentEventByType(
            item.shipmentEvents,
            'DELIVERED_TO_DESTINATION'
          )?.node?.actualDatetime
        ) <= maxDate &&
        differenceInDaysFromNow(
          getShipmentEventByType(
            item.shipmentEvents,
            'DELIVERED_TO_DESTINATION'
          )?.node?.actualDatetime
        ) >= minDate
      );
    });
  };

/**
 * Calculates avg on a collection of numbers
 * @param { number[] } metrics
 * @returns { number }
 */
export const averageMetrics = metrics =>
  metrics.length > 0 ? average(metrics) : 0;

export const calculateLoadChange = (
  numberOfLoadsPrior30DaysAgo,
  numberOfLoad30DaysAgo
) => {
  const result = {};

  const percentageOfLoadsChange = calculateChangePercentage(
    numberOfLoadsPrior30DaysAgo,
    numberOfLoad30DaysAgo
  );

  result.value = Math.round(Math.abs(percentageOfLoadsChange));
  result.caret = percentageOfLoadsChange < 0 ? 'down' : 'up';
  result.color = percentageOfLoadsChange < 0 ? '#888884' : '#307460';
  return result;
};

export const calculateAvgChange = (averagePrior30, average30) => {
  const percentageOfAverageChange = calculateChangePercentage(
    averagePrior30,
    average30
  );
  return {
    value: Math.round(Math.abs(percentageOfAverageChange)),
    caret: percentageOfAverageChange < 0 ? 'down' : 'up',
    color: percentageOfAverageChange < 0 ? '#307460' : '#888884',
  };
};

export const calculatePercentages = insights => {
  const applyInsightsFilter = filterOn(insights);
  const shipments30DaysAgo = applyInsightsFilter();
  const shipmentsPrior30Days = applyInsightsFilter({
    minDate: 30,
    maxDate: 59,
  });
  const costs = R.map(o => o.priceInCents);

  const avgCost30 = averageMetrics(costs(shipments30DaysAgo));
  const avgCostPrior30 = averageMetrics(costs(shipmentsPrior30Days));

  const avgShippingDuration30 = averageMetrics(
    shippingDuration(shipments30DaysAgo)()
  );
  const avgShippingDurationPrior30 = averageMetrics(
    shippingDuration(shipmentsPrior30Days)()
  );

  //Calculate Loads percentage change. and it's state
  const loadsChange = calculateLoadChange(
    shipmentsPrior30Days.length,
    shipments30DaysAgo.length
  );

  // Calculate Average time in transit change and it's state
  const avgTimeChange = calculateAvgChange(
    avgShippingDurationPrior30,
    avgShippingDuration30
  );

  // Calculate Average Cost percentage change and it's state
  const avgCostChange = calculateAvgChange(avgCostPrior30, avgCost30);

  return { loadsChange, avgTimeChange, avgCostChange };
};
