import qs from 'qs';
import { mergeDeepRight } from 'ramda';
import { useMutation } from 'react-query';

import { createUserOnBoarding, validateUser } from 'core/api/user';
import { useOnboarding } from 'context/onboarding';
import { onboarding as onBoardingPaths } from 'routes/paths';
import { isValidRoute } from 'screens/routes/utils';
import { commodityOptionToValue } from 'core/utils';

const DRY_VAN = '53_foot_dry_van';
const LB_TO_KG_FACTOR = 0.45359237;

export const useSubmitOnboarding = ({ language, isMobile }) => {
  const { invite_token = '' } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const { goTo } = useOnboarding();

  const createUserOnBoardingMutation = useMutation(createUserOnBoarding, {
    onSuccess: () => {
      goTo(onBoardingPaths.onboardingSuccessful, {});
    },
  });
  const validateUserMutation = useMutation(validateUser, {
    onSuccess: (_, data) => {
      createUserOnBoardingMutation.mutate(data);
    },
  });

  return {
    isLoading:
      validateUserMutation.isLoading || createUserOnBoardingMutation.isLoading,
    error: mergeDeepRight(
      validateUserMutation.error,
      createUserOnBoardingMutation.error
    ),
    validateUserAndCreateLaneRequest: data => {
      validateUserMutation.mutate(
        createRequest(data, language, invite_token, isMobile)
      );
    },
  };
};

const createRequest = (
  {
    customs,
    crossBorderInsurance,
    financing,
    routes,
    preferredBorder,
    category,
    name,
    valuePerLoad,
    weight,
    hazmat,
    fumigation,
    straps,
    notes,
    specialHandling,
    showShipmentValue,
    directService,
    firstName,
    lastName,
    companyName,
    email,
    password,
    acceptTerms,
    phoneNumber,
  },
  language,
  inviteToken,
  isMobile
) => {
  // We want to add some fields only for some truck types

  return {
    language,
    inviteToken,
    additionalServices: {
      customs,
      crossBorderInsurance,
      financing,
    },
    commodity: {
      category: category.value,
      name,
      valuePerLoad: showShipmentValue ? valuePerLoad.value : 100000,
      weight:
        weight.unit?.toLowerCase() === 'lb'
          ? Math.ceil(weight.value * LB_TO_KG_FACTOR)
          : weight.value,
      extraStrapsCount: isMobile
        ? commodityOptionToValue(straps)
        : commodityOptionToValue(specialHandling[1].required),
      specialRequirements: {
        hazmat,
        fumigation: isMobile
          ? commodityOptionToValue(fumigation)
          : commodityOptionToValue(specialHandling[0].required),
        straps: isMobile
          ? !!commodityOptionToValue(straps)
          : !!commodityOptionToValue(specialHandling[1].required),
      },
    },
    notes,
    serviceType: directService ? 'direct' : 'transload',
    truckType: DRY_VAN,
    routes: getValidRoutes(routes),
    preferredBorder: preferredBorder.value,
    firstName,
    lastName,
    companyName,
    email,
    password,
    acceptTerms,
    phoneNumber,
  };
};

const formatRoute = ({ origin, destination, loadsPerMonth }) => ({
  origin: {
    geolocationId: origin.id,
  },
  destination: {
    geolocationId: destination.id,
  },
  loadsPerMonth: loadsPerMonth,
});

const getValidRoutes = routes => {
  const validRoutes = routes
    .filter(route => isValidRoute(route))
    .map(route => formatRoute(route));

  return validRoutes;
};
