import React from 'react';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(error, { extra: errorInfo });
      FullStory.event('Production Error', {
        error,
        errorInfo,
      });
    }
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { errorView, children } = this.props;

    if (this.state.hasError) {
      return <>{errorView}</>;
    }

    return children;
  }
}
