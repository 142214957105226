import React from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormWrapper = styled(Form)`
  margin-top: 60px;
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.mediaSizes.mobileL}) {
      margin-top: 2em;
    }
  `}
`;

export const LocationArrayErrors = ({ errors, route }) => {
  if (errors?.routes && errors?.routes[route]) {
    return (
      <>
        <div style={{ textAlign: 'center', color: 'red' }}>
          {errors.routes[route]}
        </div>
      </>
    );
  }
  return null;
};

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > * + * {
    margin-left: 26px;
  }
  ${({ theme }) => css`
    @media only screen and ${theme.device.sm} {
      & > * + * {
        margin-left: 0px;
      }
    }
  `}
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  ${({ isFirst }) =>
    isFirst &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    `}

  ${({ theme }) => css`
    @media only screen and ${theme.device.sm} {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  `}
`;

export const Line = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(232, 232, 232);
  padding: 0;
  margin: 0;
`;

export const LocationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 5px;
  padding: 0px 25px;
  height: 240px;
  width: 100%;
  max-width: 431px;

  &:focus-within,
  &:hover {
    cursor: pointer;
    border: 1px solid #307460;
  }
`;
