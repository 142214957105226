import * as FullStory from '@fullstory/browser';
import { Icon } from '@nuvocargo/nuvo-styleguide';

import { formatShortDateTime } from 'core/utils/date-fns';
import { downloadFileFromStorage } from 'core/utils/file';

const List = ({ t, documents }) => {
  const onClickDownload = (fileUrl, fileName) => e => {
    e.preventDefault();
    FullStory.event('[SHIPMENT] Download Documents');
    downloadFileFromStorage(fileUrl, fileName);
  };

  const filesList = documents.map((doc, index) => (
    <li
      key={doc.id || `doc_${index}`}
      data-testid={`shipment-details-documents-list-item-${doc.id}`}
      className="flex items-center justify-between gap-2 py-5 px-1 transition-colors hover:bg-nuvo-gray/5 sm:gap-5">
      <Icon name="insertDriveFile" size="medium" color="steel" />
      <div className="flex flex-1 flex-col">
        <span
          data-testid={`shipment-details-documents-list-item-${doc.id}-category`}
          className="font-value-sans-medium font-medium">
          {t(`doc_${doc.category || 'uncategorized'}`)}
        </span>

        {(doc.document.fileName || doc.name) && (
          <span
            data-testid={`shipment-details-documents-list-item-${doc.id}-name`}
            className="break-all font-value-sans-regular">
            {doc.document.fileName || doc.name}
          </span>
        )}

        <div className=" font-value-sans-regular text-sm text-nuvo-steel sm:hidden">
          {doc.document.createdAt &&
            formatShortDateTime(doc.document.createdAt)}
        </div>
      </div>
      <div className="flex flex-col items-center sm:flex-row  sm:gap-2 ">
        <div
          data-testid={`shipment-details-documents-list-item-${doc.id}-date`}
          className="hidden font-value-sans-regular text-sm text-nuvo-steel sm:block">
          {doc.document.createdAt &&
            formatShortDateTime(doc.document.createdAt)}
        </div>

        <button
          data-testid={`shipment-details-documents-list-item-${doc.id}-download`}
          className="rounded px-4 py-2 font-value-sans-medium text-nuvo-forest transition hover:bg-nuvo-forest/10 focus:ring focus:ring-nuvo-forest sm:px-2 sm:py-1"
          type="button"
          onClick={onClickDownload(
            doc.document.url,
            doc.document.fileName || doc.name
          )}>
          {t('button-download')}
        </button>
      </div>
    </li>
  ));

  return (
    <ul
      data-testid="shipment-details-documents-list"
      className="divide-y divide-nuvo-gray/10">
      {filesList}
    </ul>
  );
};

export default List;
