import React from 'react';

export default function CaretUp({ color = '#307460', ...props }) {
  return (
    <svg width="9px" height="6px" viewBox="0 0 9 6" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-379.000000, -302.000000)" fill={color}>
          <g transform="translate(50.000000, 215.000000)">
            <polygon points="333.5 87 338 93 329 93"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}
