import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import NewRoute from 'components/common/layout/authenticated/new-route';
import { useNewRoutes } from 'context/routes';
import { useWindowSize } from 'core/hooks';

import NewRoutesAdditionalServicesForm from '../newRouteForm/additionalServices/index';
import { initialValues } from '../newRouteForm/additionalServices/data';
import { useSubmitNewRoute } from '../hooks';
import { isMobile as checkIsMobile } from '../utils';

export default function NewRoutesAdditionalServices() {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { answers } = useNewRoutes();
  const isMobile = checkIsMobile(width);
  const { createLaneRequest } = useSubmitNewRoute({ isMobile });

  return (
    <NewRoute title={t('onboarding-services-title')}>
      <Formik
        initialValues={{ ...initialValues, ...answers }}
        onSubmit={createLaneRequest}>
        <NewRoutesAdditionalServicesForm />
      </Formik>
    </NewRoute>
  );
}
