import { useMutation, useQueryClient } from 'react-query';
import * as FullStory from '@fullstory/browser';

import { assertGraphQLError, graphQLRequest } from 'core/utils/request';
import { ROUTES_QUERY, ROUTE_QUERY } from 'core/graphql/queries';
import { ROUTES_API_ENDPOINT } from 'core/utils/constants';
import request from 'core/utils/request';

import { PRICED_ROUTES_QUERY } from '../graphql/queries/routes';
import { ROUTE_BY_ID_QUERY } from '../graphql/queries/route';
import { placeIdToNumber } from '../utils/place';

/**
 * Retrieves Routes from the API using a GraphQL query filtered by `companyId`
 * @param { Object } args
 * @param { string } args.companyId
 * @param { string } args.cursor Offset enconded as base64
 * @param { number } args.pageSize
 * @param { AbortSignal } args.signal
 */
export async function fetchRoutes({ companyId, cursor, pageSize, signal }) {
  return graphQLRequest({
    body: {
      query: ROUTES_QUERY,
      variables: {
        after: cursor,
        companyId,
        first: pageSize,
        state: 'ACTIVE',
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(r => {
      const data = r?.data?.data?.routes;

      return (
        data || {
          totalCount: 0,
          pageInfo: {},
          nodes: [],
        }
      );
    });
}

/**
 * Exec GraphQL route query to fetch route by id scoped to a company
 * @param { Object } args
 * @param { string } args.companyId
 * @param { string } args.routeId
 * @param { AbortSignal } args.signal
 */
export async function fetchRoute({ companyId, routeId, signal }) {
  return graphQLRequest({
    body: {
      query: ROUTE_QUERY,
      variables: {
        companyId,
        routeId,
        state: 'ACTIVE',
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(r => {
      const routes = r?.data?.data?.routes?.nodes ?? [];
      return routes[0] ?? null;
    });
}

export async function fetchRouteByID({ id, signal }) {
  return graphQLRequest({
    body: {
      query: ROUTE_BY_ID_QUERY,
      variables: {
        id,
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(r => {
      const routes = r?.data?.data?.routes?.nodes ?? [];

      return routes[0] ?? null;
    });
}

/**
 * Retrieves Priced Routes from the API using a GraphQL query filtered by `companyId`
 * @param { Object } args
 * @param { string } args.companyId
 * @param { string } args.after Offset enconded as base64
 * @param { number } args.pageSize
 */
export async function fetchPricedRoutes({ companyId, after, pageSize = 50 }) {
  return graphQLRequest({
    body: {
      query: PRICED_ROUTES_QUERY,
      variables: {
        after,
        companyId,
        first: pageSize,
      },
    },
  })
    .then(assertGraphQLError)
    .then(r => {
      const data = r?.data?.data?.routes;

      return (
        data || {
          totalCount: 0,
          pageInfo: {},
          nodes: [],
        }
      );
    });
}

export const createRoute = async data =>
  await request(ROUTES_API_ENDPOINT, 'post', { body: data });

export const useUpdateRoute = ({
  id,
  onSuccessCallback,
  updateProperty,
  onErrorCallback,
}) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id: newPlaceId }) =>
      updateRouteLocation({ id, newPlaceId, updateProperty }),
    {
      onSuccess: updatedRoute => {
        FullStory.event('Updated route to point to an existing place', {
          updatedRoute,
        });

        queryClient.invalidateQueries(['fetchRouteByID', id]);
        // @todo: remove this one when _configured_ props from _route_ is working
        queryClient.invalidateQueries('places');
        onSuccessCallback?.(updatedRoute);
      },
      onError: error => {
        console.log('error =>', error);
        FullStory.event(
          'Error trying to update route to point to an existing place',
          {
            error,
          }
        );

        onErrorCallback?.(error);
      },
    }
  );
};

const updateRouteLocation = ({ id, newPlaceId, updateProperty }) =>
  request(`/v0/routes/${id}`, 'patch', {
    body: {
      [updateProperty]: {
        id: placeIdToNumber(newPlaceId),
      },
    },
  });
