import React from 'react';
import styled from 'styled-components';

export default function ErrorMessage({ children, backgroundColor, ...props }) {
  return (
    <StyledNotificationBox backgroundColor={backgroundColor} {...props}>
      <Error>{children}</Error>
    </StyledNotificationBox>
  );
}

const StyledNotificationBox = styled.div`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors['taupe']};
  border-radius: 4px;
  margin: 10px 0;
  padding: 20px;
  width: 100%;
`;

const Error = styled.div`
  color: #e7040f;
  font-size: 18px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  ::first-letter {
    text-transform: capitalize;
  }
`;
