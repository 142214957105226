import { useEffect, useState } from 'react';
import { UnitField } from '@nuvocargo/nuvo-styleguide/forms/native';
import { useField } from 'formik';

const MIDNIGHT = '12:00';

const AM = 'am';
const PM = 'pm';

const TIME_UNITS = {
  am: { label: 'AM', value: AM },
  pm: { label: 'PM', value: PM },
};

export const padTime = time => time.toString().substring(0, 2).padStart(2, '0');

export const twentyFourToTwelve = hours =>
  parseInt(hours) > 12 ? padTime(hours - 12) : padTime(hours);

export const twentyFourStringToTwelve = string => {
  if (!string) {
    return '';
  }
  const [hours, minutes] = string.split(':');
  return `${twentyFourToTwelve(hours)}:${padTime(minutes)} ${amOrPm(hours)}`;
};

const twelveToTwentyFour = (time = MIDNIGHT, unit) => {
  const [hours = '12', minutes = '0'] = time.split(':');
  const twentyFourHours =
    unit.value === PM
      ? (parseInt(hours) + 12).toString()
      : twentyFourToTwelve(hours);

  return `${padTime(twentyFourHours)}:${padTime(minutes)}`;
};

const innerTime = (hours, minutes) =>
  `${twentyFourToTwelve(hours)}:${padTime(minutes)}`;

export const amOrPm = hours => (parseInt(hours) > 12 ? PM : AM);

const TimeSelector = props => {
  const [field = {}, meta, helpers] = useField(props);

  const [innerValue, setInnerValue] = useState('');
  const [innerUnit, setInnerUnit] = useState({});

  const timeWithDefault = field.value || MIDNIGHT;

  const [hours = '12', minutes = '0'] = timeWithDefault.split(':');

  useEffect(() => {
    setInnerValue(innerTime(hours, minutes));
    setInnerUnit(TIME_UNITS[amOrPm(hours)]);
  }, []);

  useEffect(() => {
    const [hours = '12', minutes = '0'] = innerValue.split(':');

    if (innerValue === '') {
      helpers.setValue('');
      return;
    }

    if (hours === '00') {
      setInnerValue(`12:${padTime(minutes)}`);
      setInnerUnit(TIME_UNITS[PM]);
    }
    if (innerUnit.value === AM && parseInt(hours) > 12) {
      setInnerUnit(TIME_UNITS[PM]);
    }
    if (parseInt(hours) > 12) {
      setInnerValue(`${twentyFourToTwelve(hours)}:${padTime(minutes)}`);
    }

    helpers.setValue(twelveToTwentyFour(innerValue, innerUnit));
  }, [innerValue, innerUnit]);

  return (
    <>
      <UnitField
        {...props}
        options={[TIME_UNITS.am, TIME_UNITS.pm]}
        inputValue={innerValue}
        handleInputChange={setInnerValue}
        unitValue={innerUnit}
        handleUnitChange={setInnerUnit}
        maxlength="5"
        error={meta?.touched && meta?.error}
      />
    </>
  );
};

export default TimeSelector;
