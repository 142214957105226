import { gql } from 'graphql-request';

export const ROUTES_QUERY = gql`
  query customerui_getRoutes(
    $companyId: String
    $state: RouteStateEnum!
    $first: Int
    $after: String
  ) {
    routes(companyId: $companyId, state: $state, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        commodities(first: 1) {
          nodes {
            name
            category
          }
        }
        destination {
          ...placeFields
        }
        id
        origin {
          ...placeFields
        }
        serviceType
        shipmentRequirements
        truckType
      }
    }
  }
  fragment placeFields on Place {
    addressLine1
    addressLine2
    name
    zipCode {
      latitude
      longitude
      parents {
        city
        state
        country
      }
    }
  }
`;

export const PRICED_ROUTES_QUERY = gql`
  query getPricedRoutes($companyId: String, $first: Int, $after: String) {
    routes(companyId: $companyId, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        destination {
          ...placeFields
        }
        origin {
          ...placeFields
        }
        convertedPriceCents
        currency
        status {
          value
          is
        }
        shipments {
          totalCount
        }
      }
    }
  }

  fragment placeFields on Place {
    addressLine1
    addressLine2
    name
    zipCode {
      name
      latitude
      longitude
      parents {
        city
        state
        country
      }
    }
  }
`;
