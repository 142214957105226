import styled, { css } from 'styled-components';

const OnlyMobile = styled.div`
  display: none;
  ${({ theme }) => css`
    @media only screen and ${theme.device.sm} {
      display: block;
    }
  `}
`;

export default OnlyMobile;
