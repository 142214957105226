import qs from 'qs';
import * as FullStory from '@fullstory/browser';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { checkAuth } from 'core/api/user';

export const useAuth = () => {
  const { t } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { i18n } = useTranslation();

  const { data, isLoading, error } = useQuery(
    ['check-auth'],
    () => checkAuth(t),
    {
      onSuccess: async data => {
        const {
          id,
          email,
          displayName,
          companyName,
          impersonatedEmail,
          language,
        } = data;

        if (language) {
          await i18n.changeLanguage(language);
        }

        FullStory.identify(id, {
          email,
          displayName,
          companyName_str: companyName,
          ...(impersonatedEmail ? { impersonatedEmail } : {}),
          language: i18n.language,
        });
      },
      onError: () => {
        console.log('an Error has occurred');
      },
    }
  );

  const adminAttrs = user => {
    if (!user?.isAdmin) {
      return {};
    }

    return {
      displayName: user.impersonatedFullName || user.email,
      companyName: user.impersonatedCompany || 'Nuvocargo',
      companyId: user.impersonatedCompanyId || user.companyId,
    };
  };

  const { pathname } = document.location;
  const shouldRedirect = pathname !== '/' && !!pathname;
  const isAuthenticated = !!data?.hasValidCredentials;
  const currentUser = isLoading ? null : { ...data, ...adminAttrs(data) };

  return {
    isLoading,
    isAuthenticated,
    backUrl: !isAuthenticated && shouldRedirect && `?redirect=${pathname}`,
    currentUser,
    error: error,
  };
};
