import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useFlags } from 'core/hooks';
import { useUser } from 'context/user';
import {
  Shipments,
  Shipment,
  ChooseRouteScreen,
  SelectPickupDatesScreen,
  ShipmentRequestSuccessScreen,
  Insights,
  ResetPassword,
  ForgotPassword,
  RoutesIndex,
  NewRoutes,
  NewRoutesCommodity,
  NewRoutesAdditionalServices,
  NewRoutesSuccess,
  Invoice,
  AdditionalAppInvite,
  BillingPdf,
  RouteDetails,
} from 'screens/';
import { Provider as NewRoutesProvider } from 'context/routes';
import Layout from 'components/common/layout/authenticated';
import Settings from 'screens/settings';
import Account from 'screens/settings/account';
import Notifications from 'screens/settings/notifications';

import { auth, app } from './paths';

export default function AuthenticatedRoutes() {
  const { currentUser } = useUser();
  const { data } = useFlags(currentUser?.email);

  if (!data) return null;

  const { customer_ui_show_notification_settings } = data.features;

  return (
    <>
      <AdditionalAppInvite />
      <Switch>
        <Route path={auth.resetPassword}>
          <ResetPassword />
        </Route>
        <Route exact path="/">
          <Redirect to={app.shipments.root} />
        </Route>
        <Route exact path={auth.login}>
          <Redirect to={app.shipments.root} />
        </Route>
        <Route path={auth.forgotPassword}>
          <ForgotPassword />
        </Route>
        <Route exact path={app.billingPdf}>
          <BillingPdf />
        </Route>
        <Route exact path={app.billing}>
          <Invoice />
        </Route>
        <Route exact path={app.routes.root}>
          <RoutesIndex />
        </Route>
        <Route exact path={app.routes.new.root}>
          <NewRoutesProvider>
            <NewRoutes />
          </NewRoutesProvider>
        </Route>
        <Route exact path={app.routes.new.additionalServices}>
          <NewRoutesProvider>
            <NewRoutesAdditionalServices />
          </NewRoutesProvider>
        </Route>
        <Route exact path={app.routes.new.commodity}>
          <NewRoutesProvider>
            <NewRoutesCommodity />
          </NewRoutesProvider>
        </Route>
        <Route exact path={app.routes.new.success}>
          <NewRoutesProvider>
            <NewRoutesSuccess />
          </NewRoutesProvider>
        </Route>
        <Route exact path={app.routes.details}>
          <RouteDetails />
        </Route>
        <Route exact path={app.insights}>
          <Insights />
        </Route>
        <Route exact path={app.settings.account}>
          <Settings>
            <Account />
          </Settings>
        </Route>
        <Route exact path={app.settings.root}>
          <Settings>
            <Account />
          </Settings>
        </Route>
        <Route exact path={app.settings.account}>
          <Settings>
            <Account />
          </Settings>
        </Route>
        {customer_ui_show_notification_settings.enabled && (
          <Route exact path={app.settings.notifications}>
            <Settings>
              <Notifications />
            </Settings>
          </Route>
        )}
        <Route exact path={app.shipments.root}>
          <Layout ignoreGrid>
            <Shipments />
          </Layout>
        </Route>
        <Route exact path={app.shipments.new.root}>
          <ChooseRouteScreen />
        </Route>
        <Route exact path={app.shipments.new.success}>
          <ShipmentRequestSuccessScreen />
        </Route>
        <Route path={app.shipments.new.pickupDates}>
          <SelectPickupDatesScreen />
        </Route>
        {/* TODO: Remove this once the redesign is released */}
        <Route path={app.shipments.details}>
          <Shipment />
        </Route>
        <Route path="*">
          <Redirect to={app.shipments.root} />
        </Route>
      </Switch>
    </>
  );
}
