import React from 'react';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { StateCard } from 'components/common/app/StateCard';

export function EmptyRoutesTable() {
  const { t } = useTranslation();

  return (
    <StateCard
      styles={{
        root: {
          padding: '60px 0',
        },
      }}
      icon={
        <IconWrapper>
          <Iconography
            alt="Empty table icon"
            name="tech"
            styles={{ root: { width: '100%' } }}
          />
        </IconWrapper>
      }
      header={t('empty-routes-title')}
      description={t('empty-routes-description')}
    />
  );
}

const IconWrapper = styled.div`
  width: 300px;
  aspect-ratio: 1;
  background: ${({ theme }) => theme.colors.darkCream};
  border-radius: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 320px;
    height: 220px;
  }
`;
IconWrapper.displayName = 'IconWrapper';
