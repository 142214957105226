import { Grid, GridColumn, Heading, Text } from '@nuvocargo/nuvo-styleguide';
import { motion } from 'framer-motion';
import { isEmpty } from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { onboarding } from 'routes/paths';
import { isFunction } from 'core/utils/lib';
import { useWindowSize } from 'core/hooks';
import { useOnboarding } from 'context/onboarding/index';
import { Box, ExpiryPage } from 'components/generic/kit';
import Layout from 'components/common/layout/unauthenticated';

import { FlexItem } from '../../../../screens/onboarding/styles';
import {
  isMobile as checkIsMobile,
  getHeadingVariant,
} from '../../../../screens/onboarding/utils';

export const TOTAL_STEPS = 4;

export default function OnBoarding({ children, title, subtitle }) {
  const history = useHistory();
  const location = useLocation();
  const {
    answers,
    inviteResponse: { isLoading, isTokenExpired, senderInfo },
  } = useOnboarding();
  const { t } = useTranslation();
  const step = calculateSelfServeStep(location.pathname);
  const { width } = useWindowSize();
  const isMobile = checkIsMobile(width);

  useEffect(() => {
    if (isEmpty(answers)) {
      history.push({
        pathname: onboarding.shipmentLanes,
        search: location.search,
      });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (isLoading) {
    return (
      <Layout>
        <Wrapper>
          <Grid>
            <GridColumn
              startMd={4}
              md={6}
              styles={{ root: { textAlign: 'center' } }}>
              <AvatarPlaceholder />
              <Header>
                <HeadingPlaceholder
                  initial={{ opacity: 0.7 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    repeat: Infinity,
                    duration: 0.4,
                    repeatType: 'reverse',
                  }}
                />
              </Header>
            </GridColumn>
          </Grid>
        </Wrapper>
      </Layout>
    );
  }

  if (isTokenExpired) {
    return (
      <ExpiryPage
        title={t(`onboarding-expired-token-title`)}
        subtitle={t(`onboarding-expired-token-subtitle`)}
      />
    );
  }

  return (
    <Layout>
      <FlexColumn>
        <FlexOnboardingItem
          style={{
            padding: '6px 8px',
            marginTop: '48px',
          }}>
          <Heading
            color="darkGrey"
            variant="h6"
            styles={{
              root: {
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: '16px',
              },
            }}>
            {t('self-serve-step-counter', { step, totalSteps: TOTAL_STEPS })}
          </Heading>
        </FlexOnboardingItem>
        <FlexOnboardingItem>
          <Heading
            id="title"
            component="div"
            variant={getHeadingVariant(width)}
            color="green"
            styles={{
              root: {
                marginTop: '1em',
              },
            }}>
            {isFunction(title) ? title(senderInfo.name) : title}
          </Heading>
          <Header>
            {subtitle ? (
              <Text
                color="steel"
                size={isMobile ? 'extraSmall' : 'regular'}
                fontWeight="regular">
                {subtitle}
              </Text>
            ) : null}
          </Header>
        </FlexOnboardingItem>
        <FlexOnboardingItem>
          <Box>{children}</Box>
        </FlexOnboardingItem>
      </FlexColumn>
    </Layout>
  );
}

export const calculateSelfServeStep = routePath => {
  switch (routePath) {
    case onboarding.shipmentLanes:
      return 1;

    case onboarding.commodity:
      return 2;

    case onboarding.additionalServices:
      return 3;

    case onboarding.userInfo:
      return 4;

    default:
      return null;
  }
};

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
`;

export const FlexOnboardingItem = styled(FlexItem)`
  ${({ theme }) =>
    css`
      @media (max-width: ${theme.mediaSizes.mobileL}) {
        padding: 0 1.5em 0 1.5em;
      }
    `}
`;

const Header = styled.div`
  display: 'flex';
  max-width: 687px;
  flex-direction: column;
  text-align: center;
  margin-top: 0.75em;

  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.mobileL}) {
        margin-top: 0.5em;
      }
    `}
`;

const Wrapper = styled.div`
  margin-top: -40px;
  padding-bottom: 6rem;
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        margin-top: 2rem;
        padding: 0 10px;
        padding-bottom: 3rem;
      }
    `}
`;

const AvatarPlaceholder = styled.div`
  height: 72px;
  width: 72px;
  ${({ theme }) => `background-color:${theme.colors.green}`};
  ${({ theme }) => `border: 4px solid ${theme.colors.green}`};
  border-radius: 50%;
  margin: 0 auto;
`;

const HeadingPlaceholder = styled(motion.div)`
  display: 'flex';
  max-width: 687px;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  height: 64px;
  background-color: #efe8e1;
`;
