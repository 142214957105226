import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import { Map as MapComponent } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary } from 'core/integrations/errors';
import { calcJourneyPercentage } from 'core/utils/shipments';

function MapError() {
  const { t } = useTranslation();
  return (
    <div className="col-span-1 flex flex-col items-start justify-center gap-2  rounded bg-nuvo-cream-dark p-5 lg:col-span-5">
      <Heading
        variant="h4"
        color="green"
        styles={{
          root: {
            margin: '8px auto',
          },
        }}>
        {t('single-shipment-map-error-title')}
      </Heading>
      <Text color="steel">{t('single-shipment-map-error-message')}</Text>
    </div>
  );
}

export function Map(props) {
  const { isLoading, shipment } = props;

  if (isLoading) {
    return (
      <div className=" col-span-1 animate-pulse rounded  bg-nuvo-green/25 p-8 lg:col-span-5 " />
    );
  }

  return (
    <ErrorBoundary errorView={<MapError />} id="shipment-details-map-boundary">
      <div className=" col-span-1 h-96 rounded lg:col-span-5 ">
        <MapComponent
          styles={{
            container: {
              height: '100%',
            },
          }}
          fullRoute={{
            legs: shipment.legs,
          }}
          completedRoute={{
            percentage:
              calcJourneyPercentage(shipment?.shipmentEvents?.nodes) / 100,
          }}
          mapToken={process.env.REACT_APP_MAPBOX_TOKEN}
        />
      </div>
    </ErrorBoundary>
  );
}
