import React, { useEffect } from 'react';
import * as R from 'ramda';
import * as FullStory from '@fullstory/browser';

import { diffInDays } from 'core/utils/date-fns';
import { formatMoney } from 'core/utils/lib';
import {
  useInvoices,
  useQueryParamsState,
  usePageQueryParam,
} from 'core/hooks';

import { formatCSVModel, shouldPayTo } from './utils';
import { csvColumns } from './data';

const OPEN_PAYMENT_MODAL_EVENT = '[Billing] Opened Payment Modal';
const VALID_CURRENCIES = ['USD', 'MXN'];

export const INVOICE_STATUSES = {
  paid: 'paid',
  due: 'due',
  overdue: 'overdue',
};

const isPaid = status => status === 'paid';

const calculateInvoiceStatus = invoice => {
  if (invoice.paid) {
    return {
      status: INVOICE_STATUSES.paid,
      state: 'success',
    };
  }

  const dueDate = new Date(invoice.dueDate);
  const today = new Date();

  if (dueDate > new Date()) {
    return {
      status: INVOICE_STATUSES.due,
      state: 'warning',
      dueInDays: diffInDays(dueDate, today),
    };
  }

  return {
    status: INVOICE_STATUSES.overdue,
    state: 'error',
    overdueByDays: diffInDays(today, dueDate),
  };
};

/**
 * Invoices can only have one currency. So we find which invoice this
 * particular invoice has, and extract its financial data that way.
 */
const getInvoiceFinancialData = invoice => {
  let currency = VALID_CURRENCIES.find(c => invoice.balance.total[c] > 0);
  let due = formatMoney(invoice.balance.due[currency]);
  let paid = formatMoney(invoice.balance.paid[currency]);
  let total = formatMoney(invoice.balance.total[currency]);

  return {
    currency: currency || 'USD',
    due,
    paid,
    total,
  };
};

const transformInvoice = invoice => ({
  ...invoice,
  ...getInvoiceFinancialData(invoice),
  ...calculateInvoiceStatus(invoice),
});

const mapInvoiceCompaniesOpts = options =>
  options.map(opt => ({ value: opt.id, label: opt.legalName }));

export const useNewBilling = () => {
  const [searchFor, setSearchFor] = useQueryParamsState('searchFor');
  const [currency, setCurrency] = useQueryParamsState('currency');
  const [status, setStatus] = useQueryParamsState('status');
  const [invoiceCompany, setInvoiceCompany] =
    useQueryParamsState('invoiceCompany');
  const [page, setPage] = usePageQueryParam();
  const [pageSize = 50, setPageSize] = useQueryParamsState('pageSize');
  const pageSizeNumber = parseInt(pageSize, 10);

  const { isLoading, data, isError } = useInvoices(
    {
      searchFor,
      currency,
      invoiceCompany,
      page,
      perPage: pageSize,
      status,
    },
    {
      select: ({ data }) => ({
        invoices: data.records.map(transformInvoice),
        overdueAmount: data.total.overdue,
        paymentCurrency: data.paymentCurrency || { USD: true, MXN: false },
        invoiceCompanyOptions: mapInvoiceCompaniesOpts(data.invoiceCompanies),
        totalAmount: data.total.balance,
        totalItems: data.metadata.totalRecords,
      }),
    }
  );

  return {
    invoices: data?.invoices || [],
    overdueAmount: data?.overdueAmount || { USD: 0, MXN: 0 },
    paymentCurrency: data?.paymentCurrency || { USD: true, MXN: false },
    invoiceCompanyOptions: data?.invoiceCompanyOptions || [],
    totalAmount: data?.totalAmount || { USD: 0, MXN: 0 },
    totalItems: data?.totalItems || 10,
    isError,
    isLoading,
    currency,
    setCurrency,
    status,
    setStatus,
    invoiceCompany,
    setInvoiceCompany,
    page,
    setPage,
    pageSize: pageSizeNumber,
    setPageSize,
    searchFor,
    setSearchFor,
  };
};

export const useCSV = ({ invoices, status }) => {
  const [csvData, setCsvData] = React.useState();

  const generateCsvData = React.useCallback(() => {
    if (invoices.length > 0) {
      const csv = R.reduce(
        (acc, item) => acc.concat([formatCSVModel(item, isPaid(status))]),
        [csvColumns()],
        invoices
      );
      return csv;
    }
  }, [invoices, status]);

  useEffect(() => {
    if (invoices.length > 0) {
      const result = generateCsvData();
      setCsvData(result);
    }
  }, [status, invoices, generateCsvData]);

  return { csvData };
};

export const useBillingModal = ({
  totalAmount,
  overdueAmount,
  invoices,
  t,
}) => {
  const NUVOCARGO_SA_MXN = [
    {
      label: t('bank-name'),
      value: t('bank-details-name-mxn'),
    },
    {
      label: t('bank'),
      value: t('bank-details-address-mxn'),
    },
    {
      label: t('bank-cuenta'),
      value: t('bank-details-cuenta-mxn'),
    },
    {
      label: t('bank-clabe'),
      value: t('bank-details-clabe-mxn'),
    },
    {
      label: t('bank-swift'),
      value: t('bank-details-swift-mxn'),
    },
  ];

  const NUVOCARGO_SA_USD = [
    {
      label: t('bank-name'),
      value: t('bank-details-name-usd'),
    },
    {
      label: t('bank'),
      value: t('bank-details-address-usd'),
    },
    {
      label: t('bank-cuenta'),
      value: t('bank-details-cuenta-usd'),
    },
    {
      label: t('bank-clabe'),
      value: t('bank-details-clabe-usd'),
    },
    {
      label: t('bank-swift'),
      value: t('bank-details-swift-usd'),
    },
  ];

  const ONCARRIAGE_BANK = [
    {
      label: t('bank-name'),
      value: t('bank-details-name-oncarriage'),
    },
    {
      label: t('bank'),
      value: t('bank-details-address-oncarriage'),
    },
    {
      label: t('bank-cuenta'),
      value: t('bank-details-account-oncarriage'),
    },
    {
      label: t('bank-routing'),
      value: t('bank-details-routing-oncarriage'),
    },
    {
      label: t('bank-swift'),
      value: t('bank-details-swift-oncarriage'),
    },
  ];

  const shouldPayToOnCarriage = shouldPayTo('Oncarriage LLC', R.last(invoices));
  const [modalState, setModalState] = React.useState({
    isOpen: false,
    details: NUVOCARGO_SA_USD,
    totalAmount: 0,
    overdueAmount: 0,
    currency: 'USD',
  });

  const openUsdModal = () => {
    const update = {
      details: shouldPayToOnCarriage ? ONCARRIAGE_BANK : NUVOCARGO_SA_USD,
      totalAmount: totalAmount.USD,
      overdueAmount: overdueAmount.USD,
      currency: 'USD',
    };

    FullStory.event(OPEN_PAYMENT_MODAL_EVENT, update);

    // We should update the details for US bank details
    return setModalState({
      isOpen: true,
      ...update,
    });
  };
  const openMxnModal = () => {
    const update = {
      details: NUVOCARGO_SA_MXN,
      totalAmount: totalAmount.MXN,
      overdueAmount: overdueAmount.MXN,
      currency: 'MXN',
    };

    FullStory.event(OPEN_PAYMENT_MODAL_EVENT, update);

    return setModalState({
      isOpen: true,
      ...update,
    });
  };
  const closeModal = () => setModalState(prev => ({ ...prev, isOpen: false }));

  return {
    isModalOpen: modalState.isOpen,
    billingProps: {
      overdueBalance: modalState.overdueAmount,
      totalBalance: modalState.totalAmount,
      bankDetails: modalState.details,
      currency: modalState.currency,
    },
    closeModal,
    openUsdModal,
    openMxnModal,
  };
};
