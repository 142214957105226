import React from 'react';

import { useUser } from 'context/user';

import Header from './Header';

export default function AuthenticatedLayout(props) {
  const { currentUser } = useUser();

  if (!currentUser) {
    return null;
  }

  return <Header {...props} />;
}
