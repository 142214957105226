import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { useDebouncedValue } from '@mantine/hooks';

import { closeBanner, openBanner } from 'core/hooks/use-banner';
import { createShipmentRequestQL } from 'core/api/shipments';
import { compareObjectsByDate } from 'core/utils/lib';
import FullStory from 'core/integrations/fullstory';
import { useUser } from 'context/user';
import {
  getCursorFromOffset,
  getOffsetFromPageNumber,
} from 'core/utils/pagination';
import { fetchShipmentsQL, getCompanyBalance } from 'core/api/shipments';
import { useQueryParams } from 'core/hooks';
import { app } from 'routes/paths';

import { ErrorBanner } from './new/components';

/** Shipments to fetch by each page */
const QL_PAGE_SIZE = 15;

/** shipments query will be executed after this timeout */
const SEARCH_TIMEOUT_MS = 350;

const FAILED_REQUEST_ERROR_BANNER_ID = 'new-shipment-request-error';

/**
 * Constructor function to create pickup dates
 * @param { Date } date If provided, used for value, otherwise `null`.
 */
function createPickupDate(date) {
  return {
    id: uniqid(),
    value: date || null,
  };
}

/**
 * Abstract all logic in the SelectPickupDatesScreen to a custom hook.
 * @param { Object } args
 * @param { Object } args.route See Nuvocargo's GraphQL API schema for route type
 */
export function usePickupDatesLogic({ route }) {
  const history = useHistory();
  const [dates, setDates] = useState([createPickupDate()]);
  const [additionals, setAdditionals] = useState([]);
  const [wasSubmited, setWasSubmited] = useState(false);

  const closeBannerHandler = useCallback(() => {
    closeBanner(FAILED_REQUEST_ERROR_BANNER_ID);
  }, [closeBanner]);

  const onAddAnotherDateHandler = useCallback(() => {
    setWasSubmited(false);
    setDates(dates => dates.concat(createPickupDate()));
  }, []);

  const onRemovePickupDateHandler = useCallback(id => {
    setDates(dates => dates.filter(date => date.id !== id));
  }, []);

  const onDatePickerChangeHandler = useCallback((date, id) => {
    setDates(dates => {
      const index = dates.findIndex(date => date.id === id);

      return [
        ...dates.slice(0, index),
        { ...dates[index], value: date },
        ...dates.slice(index + 1, dates.length),
      ];
    });
  }, []);

  const onAdditionalsChangeHandler = useCallback(e => {
    const { checked, value } = e.target;
    setAdditionals(currentAdditionals =>
      checked
        ? [...currentAdditionals, value.toUpperCase()]
        : currentAdditionals.filter(
            additional => additional !== value.toUpperCase()
          )
    );
  }, []);

  const { isLoading: isRequestLoading, mutate: createShipmentRequest } =
    useMutation(createShipmentRequestQL, {
      onSuccess: () => {
        FullStory.event(FullStory.events.SHIPMENT_REQUESTED);
        history.push(app.shipments.new.success);
      },
      onError: () => {
        openBanner({
          key: FAILED_REQUEST_ERROR_BANNER_ID,
          children: () => <ErrorBanner onClose={closeBannerHandler} />,
          type: 'error',
        });
      },
    });

  // Do not leave the banner open of leaving the page.
  useEffect(() => {
    return () => closeBannerHandler();
  }, []);

  const headers = ['route-origin', 'route-destination', 'pickup-date'];
  const pickups = dates.map(({ id, value }) => ({
    id,
    date: value,
    destination: {
      city: route?.destination?.zipCode?.parents?.city,
      country: route?.destination?.zipCode?.parents?.country,
    },
    error: wasSubmited && !value,
    disabled: dates.length === 1,
    origin: {
      city: route?.origin?.zipCode.parents?.city,
      country: route?.origin?.zipCode?.parents?.country,
    },
  }));

  const onRequestShipmentsClickHandler = useCallback(() => {
    setWasSubmited(true);

    if (dates.some(({ value }) => !value)) {
      return;
    }

    createShipmentRequest({
      requests: dates.map(({ value }) => ({
        routeId: Number(route.id),
        readyForPickup: value.toISOString(),
        ...(additionals.length > 0 && { additionalServices: additionals }),
      })),
    });
  }, [additionals, createShipmentRequest, dates, route]);

  return {
    headers,
    isRequestLoading,
    onAddAnotherDateHandler,
    onDatePickerChangeHandler,
    onRemovePickupDateHandler,
    onAdditionalsChangeHandler,
    onRequestShipmentsClickHandler,
    pickups,
  };
}

/**
 * Hook to retrieve shipments with the following functionality
 *  - Filter by the current user's company
 *  - Handle pagination
 *  - Handle search by keyword
 */
export function useShipments() {
  const { t } = useTranslation();

  const FILTER_STATES = [
    {
      value: 'requested',
      label: t('pending-shipments'),
    },
    {
      value: 'active',
      label: t('active-shipments'),
    },
    {
      value: 'completed',
      label: t('completed'),
    },
  ];

  const { filter: activeFilter = FILTER_STATES[1].value } = useQueryParams();
  const { currentUser } = useUser();
  const companyId = String(currentUser?.companyId);

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(() => FILTER_STATES.slice());
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebouncedValue(
    searchQuery,
    SEARCH_TIMEOUT_MS
  );

  const { data, isLoading, isFetching, error } = useQuery(
    [
      'ql_shipments',
      companyId,
      {
        page,
        filter: activeFilter,
        searchQuery: debouncedSearchQuery,
      },
    ],
    ({ signal }) =>
      fetchShipmentsQL({
        companyId,
        cursor: getCursorFromOffset(
          getOffsetFromPageNumber(QL_PAGE_SIZE, page - 1)
        ),
        pageSize: QL_PAGE_SIZE,
        searchQuery,
        signal,
        states: activeFilter.toUpperCase(),
      }),
    {
      onSuccess: data => {
        setFilters(filters =>
          addTotalCountToActiveFilter(filters, activeFilter, data?.totalCount)
        );
      },
      keepPreviousData: true,
    }
  );

  const gotoPage = page => setPage(page + 1);
  const nextPage = () => setPage(page => page + 1);
  const previousPage = () => setPage(page => page - 1);

  const pagination = {
    pageCount: Math.ceil((data?.totalCount ?? 1) / QL_PAGE_SIZE),
    pageIndex: page - 1,
    gotoPage,
    nextPage,
    previousPage,
  };

  const shipments = (data?.nodes ?? []).sort((s1, s2) =>
    compareObjectsByDate(s1, s2, 'createdAt')
  );

  return {
    shipments,
    pagination,
    filter: activeFilter,
    filters,
    isLoading,
    isFetching,
    error,
    setSearchQuery,
  };
}

export const useCompanyBalance = queryConfig =>
  useQuery('company_balance', ({ signal }) => getCompanyBalance({ signal }), {
    select: ({ data }) => ({
      isCompanyBalanceOverdue:
        data?.companyBalance?.overdue?.MXN > 0 ||
        data?.companyBalance?.overdue?.USD > 0,
      overdueData: data?.companyBalance?.overdue,
      financeHold: data.financeHold,
    }),
    ...queryConfig,
  });

/**
 * This will display a count of the total items for a given filter next
 * to the filter label in the Select component.
 * @param { Object[] } filters Valid filters for shipments
 * @param { string } activeFilter
 * @param { number } count Total count of items for the given active filter
 */
function addTotalCountToActiveFilter(filters, activeFilter, count = 0) {
  const currentIndex = filters.findIndex(f => f.value === activeFilter);
  return [
    ...filters.slice(0, currentIndex),
    {
      ...filters[currentIndex],
      count: `${count}`,
    },
    ...filters.slice(currentIndex + 1, filters.length),
  ];
}
