import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Pagination, Text } from '@nuvocargo/nuvo-styleguide';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MovingTruck } from 'components/generic/kit';
import { WithDataMigrationMessage } from 'components/common/app';

import * as styles from './styles';

export default function Table({ columns, data, isLoading }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => {
            const { key, ...headerGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...headerGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key, ...headerProps } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );
                  return (
                    <th key={key} {...headerProps}>
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <tr>
              <styles.StyledFullTd colSpan={columns.length}>
                <MovingTruck />
              </styles.StyledFullTd>
            </tr>
          ) : page.length > 0 ? (
            page.map(row => {
              prepareRow(row);
              const { key } = row.getRowProps();
              return (
                <styles.TableRow
                  {...row.getRowProps()}
                  key={key}
                  onClick={() => {
                    history.push(`/shipments/${row.original.id}`);
                  }}>
                  {row.cells.map(cell => {
                    const { key } = cell.getCellProps();
                    return (
                      <td {...cell.getCellProps()} key={key}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </styles.TableRow>
              );
            })
          ) : (
            <styles.StyledFullTd colSpan={columns.length}>
              <div>
                <Text>{t('insights-table-empty-data')}</Text>
              </div>
            </styles.StyledFullTd>
          )}
        </tbody>
      </table>
      <WithDataMigrationMessage padding="0 20px">
        {pageOptions.length > 0 && (
          <Pagination
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageCount={pageOptions.length}
            pageIndex={pageIndex}
          />
        )}
      </WithDataMigrationMessage>
    </>
  );
}
