import { NOTIFICATION_SETTINGS_MUTATION } from 'core/graphql';
import { assertGraphQLError, graphQLRequest } from 'core/utils/request';
import { NOTIFICATIONS_AND_NOTIFICATION_SETTINGS_QUERY } from 'core/graphql/queries';

export const fetchNotificationSettings = ({ userId, category, signal }) =>
  graphQLRequest({
    body: {
      query: NOTIFICATIONS_AND_NOTIFICATION_SETTINGS_QUERY,
      variables: {
        userId: userId,
        category: category,
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(r => r?.data?.data?.notifications?.nodes ?? []);

export async function createNotificationSettingsQL({ notificationSettings }) {
  return graphQLRequest({
    body: {
      query: NOTIFICATION_SETTINGS_MUTATION,
      variables: {
        notificationSettings,
      },
    },
  })
    .then(assertGraphQLError)
    .then(r => {
      const result = r?.data?.data?.createNotificationSettings ?? null;
      return result;
    });
}
