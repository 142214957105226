import React from 'react';
import styled from 'styled-components';
import { Icon } from '@nuvocargo/nuvo-styleguide';

export default function Modal({ open, onToggle, children, styles }) {
  return (
    open && (
      <Wrapper style={styles?.root}>
        <Body style={styles?.body}>
          <CloseButton onClick={onToggle}>
            <Icon name="close" />
          </CloseButton>
          {children}
        </Body>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(32, 32, 32, 0.1);
  width: 100%;
  height: 100vh;

  @media (max-width: ${({ theme }) => theme.mediaSizes.tablet}) {
    align-items: flex-end;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  text-align: center;
  top: 18px;
  right: 18px;
  width: 24px;
  height: 24px;
`;

const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
  padding: 96px 40px 64px;
`;
