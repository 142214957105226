import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Link } from 'react-router-dom';
import {
  GridColumn,
  CTAButton,
  Heading,
  Icon,
  Iconography,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

import { MovingTruck } from 'components/generic/kit';
import { useRoutes } from 'screens/routes/routes-index/hooks';
import { GenericTable } from 'components/common/app';
import Layout from 'components/common/layout/authenticated';
import { useMediaQueries } from 'core/hooks';
import { app } from 'routes/paths';

import { useCompanyBalance } from '../hooks';

import {
  EmptyRoutesTable,
  FetchRouteError,
  NewShipmentHeader,
  RouteMap,
} from './components';
import createRoutesTableHeaders from './lib/createRoutesTableHeaders';

/**
 * Screen for New Shipment request, Choose Route step.
 */
export function ChooseRouteScreen() {
  const { isSm } = useMediaQueries();
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  const [hoveredRoute, setHoveredRoute] = useState(null);
  const [isPageLoading, setPageLoading] = useState(true);
  const [error, setError] = useState(false);
  const { error: routesError, isLoading, routes } = useRoutes();
  const {
    data,
    isLoading: isCompanyBalanceLoading,
    error: companyBalanceError,
  } = useCompanyBalance({ enabled: path === app.shipments.new.root });
  const headers = createRoutesTableHeaders(routes, path, t);

  const onRowHovered = useCallback(route => {
    setHoveredRoute(route);
  }, []);

  useEffect(() => {
    setPageLoading(isLoading || isCompanyBalanceLoading);
  }, [isLoading, isCompanyBalanceLoading]);

  useEffect(() => {
    setError(routesError || companyBalanceError);
  }, [routesError, companyBalanceError]);

  const displayRoutes = useCallback(
    () =>
      !isPageLoading &&
      !error &&
      (!data?.financeHold || !data?.isCompanyBalanceOverdue),
    [isPageLoading, error, data]
  );

  const FinanceHold = () => (
    <Wrapper>
      <Iconography
        name="insurance"
        alt="financial-hold"
        styles={{
          width: '200px',
          height: '200px',
          padding: '24px',
          backgroundColor: '#F3F1EF',
          borderRadius: '1200px',
        }}
      />
      <Heading
        variant="h5"
        color="green"
        styles={{
          root: {
            fontSize: '24px',
            lineHeight: '30px',
            weigth: '400',
          },
        }}>
        {t('finance-on-hold')}
      </Heading>
      <Text size="regular" color="steel" styles={{ regular: { width: '50%' } }}>
        {t('finance-hold-new-shipment-message')}
      </Text>
    </Wrapper>
  );

  return (
    <Layout paddingTop="0" marginTop="-64px">
      <GridColumn
        md={isSm ? 12 : 8}
        styles={{
          root: {
            padding: isSm ? '60px 20px' : '60px 0',
          },
        }}>
        <NewShipmentHeader
          step={t('shipment-request-header-choose-route-step')}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text
              data-testid="routes-table-contact-us"
              color="darkGray"
              styles={{
                regular: {
                  display: 'flex',
                  margin: '8px 0 32px 0',
                  fontSize: 14,
                },
              }}>
              <Icon
                name="errorOutline"
                color="darkGray"
                styles={{ paddingRight: 4, paddingBottom: 4 }}
              />
              {t('only-approved-routes')}
            </Text>
            <CTAButton
              as={Link}
              isLink
              styles={{
                primary: {
                  display: 'flex',
                  margin: '8px 0 32px 0',
                },
              }}
              to={{
                pathname: app.routes.new.root,
              }}>
              <Text color="white">{t('request-a-route')}</Text>
            </CTAButton>
          </div>
        </NewShipmentHeader>

        {isPageLoading && (
          <Wrapper>
            <MovingTruck />
          </Wrapper>
        )}

        {!isPageLoading && error && <FetchRouteError />}

        {!displayRoutes() && <FinanceHold />}

        {displayRoutes() && (
          <GridColumn>
            <GenericTable
              columns={headers}
              data={routes ?? []}
              data-testid="routes-table"
              EmptyComponent={EmptyRoutesTable}
              isLoading={isLoading}
              onRowHover={onRowHovered}
              path={path}
              isRowClickable={true}
            />
          </GridColumn>
        )}
      </GridColumn>

      {!isSm && (
        <GridColumn md={4}>
          <StickyMapContainer>
            {Boolean(hoveredRoute) && (
              <RouteMap
                origin={hoveredRoute.origin}
                destination={hoveredRoute.destination}
              />
            )}
          </StickyMapContainer>
        </GridColumn>
      )}
    </Layout>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 24px;
`;

const StickyMapContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.forest};
  height: 100vh;
  position: sticky;
  top: 0;
  width: calc(100% + 50px); // 50px parent padding
`;
StickyMapContainer.displayName = 'StickyMapContainer';
