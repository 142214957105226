export const PROCEDURES_RECEIVING_DAYS_MAP = {
  MONDAY_FRIDAY: 'MONDAY_FRIDAY',
  MONDAY_SATURDAY: 'MONDAY_SATURDAY',
  MONDAY_SUNDAY: 'MONDAY_SUNDAY',
};

export const PROCEDURES_APPOINTMENT_TYPES_MAP = {
  FIFO: 'FIFO',
  EXACT_TIME: 'EXACT_TIME',
};

export const PROCEDURES_LOADING_SCHEMA_MAP = {
  LIVE: 'LIVE',
  DROP: 'DROP',
};

export const PROCEDURES_LOADING_DURATION = {
  LIVE: {
    LESS_THAN_TWO_HOURS: 'LESS_THAN_TWO_HOURS',
    TWO_FOUR_HOURS: 'TWO_FOUR_HOURS',
    FOUR_SIX_HOURS: 'FOUR_SIX_HOURS',
    MORE_THAN_SIX_HOURS: 'MORE_THAN_SIX_HOURS',
  },
  DROP: {
    TWENTY_FOUR_HOURS: 'TWENTY_FOUR_HOURS',
    FORTY_EIGHT_HOURS: 'FOURTY_EIGHT_HOURS',
    SEVENTY_TWO_HOURS: 'SEVENTY_TWO_HOURS',
    MORE_THAN_SEVENTY_TWO_HOURS: 'MORE_THAN_SEVENTY_TWO_HOURS',
  },
};

export const buildSchemaOptions = ({ t }) => {
  return Object.keys(PROCEDURES_LOADING_SCHEMA_MAP).map(key => ({
    value: key,
    label: t(`procedures-schema-${key}-option`),
  }));
};

export const getLoadingSchemeOptionOrDefault = ({ value, t }) => {
  const loadingScheme =
    PROCEDURES_LOADING_SCHEMA_MAP[value] ?? PROCEDURES_LOADING_SCHEMA_MAP.LIVE;

  return buildSchemaOptions({ t }).find(
    option => option.value === loadingScheme
  );
};

export const buildAppointmentTypeOptions = ({ t }) => {
  return Object.keys(PROCEDURES_APPOINTMENT_TYPES_MAP).map(key => ({
    value: key,
    label: t(`procedures-appointment-type-${key}-option`),
  }));
};

export const getAppointmentTypeOptionOrDefault = ({ value, t }) => {
  const appointmentType =
    PROCEDURES_APPOINTMENT_TYPES_MAP[value] ??
    PROCEDURES_APPOINTMENT_TYPES_MAP.FIFO;

  return buildAppointmentTypeOptions({ t }).find(
    option => option.value === appointmentType
  );
};

export const buildReceivingDaysOptions = ({ t }) => {
  return Object.keys(PROCEDURES_RECEIVING_DAYS_MAP).map(key => ({
    value: key,
    label: t(`procedures-receiving-days-${key}-option`),
  }));
};

export const getReceivingDaysOptionOrDefault = ({ value, t }) => {
  const receivingDays =
    PROCEDURES_RECEIVING_DAYS_MAP[value] ??
    PROCEDURES_RECEIVING_DAYS_MAP.MONDAY_FRIDAY;

  return buildReceivingDaysOptions({ t }).find(
    option => option.value === receivingDays
  );
};

export const buildLoadingDurationOptions = ({ loadingSchema, t }) => {
  let options = {};

  if (loadingSchema === PROCEDURES_LOADING_SCHEMA_MAP.LIVE) {
    options = PROCEDURES_LOADING_DURATION.LIVE;
  } else {
    options = PROCEDURES_LOADING_DURATION.DROP;
  }

  return Object.keys(options).map(key => ({
    value: key,
    label: t(`procedures-loading-duration-${key}-option`),
  }));
};

export const getLoadingDurationOptionOrDefault = ({
  value,
  loadingSchema,
  t,
}) => {
  const loadingDuration =
    loadingSchema === PROCEDURES_LOADING_SCHEMA_MAP.LIVE
      ? PROCEDURES_LOADING_DURATION.LIVE[value] ??
        PROCEDURES_LOADING_DURATION.LIVE.LESS_THAN_TWO_HOURS
      : PROCEDURES_LOADING_SCHEMA_MAP.DROP[value] ??
        PROCEDURES_LOADING_DURATION.DROP.TWENTY_FOUR_HOURS;

  return buildLoadingDurationOptions({ loadingSchema, t }).find(
    option => option.value === loadingDuration
  );
};
