import React from 'react';
import { Text } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

import { capitalize, mapLanguageToCountry } from 'core/utils/lib';

export default function LanguageDropDown({ value, onChange }) {
  return (
    <Wrapper onClick={() => onChange(value === 'en' ? 'es' : 'en')}>
      <Text>{capitalize(value)}</Text>
      <img src={mapLanguageToCountry[value]} width="24" />
    </Wrapper>
  );
}

const Wrapper = styled.button`
  display: flex;
  & > * + * {
    margin-left: 8px;
  }
  :hover {
    cursor: pointer;
  }
`;
