import React from 'react';
import { Formik, Form } from 'formik';
import {
  Grid,
  GridColumn,
  CTAButton,
  Heading,
  Text,
  Link,
} from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/formik';
import styled, { css } from 'styled-components';

import Layout from 'components/common/layout/unauthenticated';

import { initialValues, validationSchema } from './data';

export default function MagicLinkLogin() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Grid
        styles={{
          root: {
            alignItems: 'center',
            gridTemplateRows: '100vh',
          },
        }}>
        <GridColumn startMd={5} md={4} sm={4}>
          <Header>
            <Heading variant="h3" color="green">
              {t('sign-in-title')}
            </Heading>
            <Text color="steel">{t('magic-link-subtitle')}</Text>
          </Header>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t)}>
            <Form>
              <Row>
                <TextField
                  label={t('email')}
                  type="email"
                  name="email"
                  placeholder={t('placeholder-email')}
                />
              </Row>
              <Row alignCenter>
                <CTAButton type="submit">
                  {t('magic-link-signin-btn')}
                </CTAButton>
              </Row>
            </Form>
          </Formik>
          <Notice>
            <Text>
              {t('magic-link-notice')}{' '}
              <Link href="/auth/login">{t('here')}.</Link>
            </Text>
          </Notice>
        </GridColumn>
      </Grid>
    </Layout>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  & > * + * {
    margin-top: 16px;
  }
`;

const Row = styled.div`
  margin: ${({ verticalSpacing }) =>
      verticalSpacing ? verticalSpacing : '40px'}
    0;
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

const Notice = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.colors.darkCream,
  textAlign: 'center',
  padding: '16px 32px',
}));
