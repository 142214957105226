import React, { useState } from 'react';
import {
  CTAButton,
  Heading,
  TableProvider,
  TableWrapper,
} from '@nuvocargo/nuvo-styleguide';
import FullscreenModal from '@nuvocargo/nuvo-styleguide/Modals/FullscreenModal';
import { useTranslation } from 'react-i18next';

import Layout from 'components/common/layout/authenticated';

import { useBillingModal, useNewBilling } from './hooks';
import Table from './table';
import { BillingPayment, TotalBalanceCard } from './components';
import { InviteModal } from './InviteModal';

export default function Billing() {
  const { t } = useTranslation();
  const {
    currency,
    invoiceCompany,
    invoices,
    isLoading,
    overdueAmount,
    page,
    pageSize,
    paymentCurrency,
    searchFor,
    setCurrency,
    setInvoiceCompany,
    setPage,
    setPageSize,
    setStatus,
    setSearchFor,
    status,
    totalAmount,
    totalItems,
    invoiceCompanyOptions,
  } = useNewBilling();

  const { isModalOpen, closeModal, openMxnModal, openUsdModal, billingProps } =
    useBillingModal({ totalAmount, overdueAmount, invoices, t });
  const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);

  return (
    <>
      <Layout paddingTop="0px" ignoreGrid ignoreBanner>
        <FullscreenModal
          isOpen={isModalOpen}
          onOpenChange={closeModal}
          styles={{
            modalContent: {
              display: 'flex',
              flexDirection: 'column',
            },
            contentContainer: {
              flex: '1',
              display: 'flex',
              alignItems: 'center',
              margin: '0px auto !important',
            },
          }}>
          <BillingPayment {...billingProps} />
        </FullscreenModal>
        <TableWrapper>
          <div className="flex justify-between">
            <Heading variant="h3">{t('invoices')}</Heading>
            <CTAButton
              colorType="secondary"
              size="small"
              onClick={() => {
                setInviteModalIsOpen(true);
              }}>
              {t('billing-invite-button')}
            </CTAButton>
          </div>

          <div className="col-span-12 flex flex-col gap-6  md:flex-row  [&>*]:flex-1 ">
            {paymentCurrency.USD && (
              <TotalBalanceCard
                isLoading={isLoading}
                label={t('total-balance-due') + ' (USD)'}
                currency="USD"
                totalBalance={totalAmount.USD}
                overdueBalance={overdueAmount.USD}
                onPayNow={openUsdModal}
              />
            )}

            {paymentCurrency.MXN && (
              <TotalBalanceCard
                isLoading={isLoading}
                label={t('total-balance-due') + ' (MXN)'}
                currency="MXN"
                totalBalance={totalAmount.MXN}
                overdueBalance={overdueAmount.MXN}
                onPayNow={openMxnModal}
              />
            )}
          </div>

          <TableProvider>
            <Table
              currency={currency}
              invoiceCompanyOptions={invoiceCompanyOptions}
              invoiceCompany={invoiceCompany}
              data={invoices}
              isLoading={isLoading}
              page={page}
              pageSize={pageSize}
              setCurrency={setCurrency}
              setInvoiceCompany={setInvoiceCompany}
              setPage={setPage}
              setPageSize={setPageSize}
              setStatus={setStatus}
              status={status}
              searchFor={searchFor}
              setSearchFor={setSearchFor}
              totalItems={totalItems}
            />
          </TableProvider>
        </TableWrapper>

        <InviteModal
          key={inviteModalIsOpen}
          isOpen={inviteModalIsOpen}
          onOpenChange={setInviteModalIsOpen}
        />
      </Layout>
    </>
  );
}
