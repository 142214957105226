import { usa, mexico, canada } from '@nuvocargo/nuvo-styleguide/Icons/Flags';

import { COMMODITIES_SPECIAL_REQUIREMENTS } from '../../screens/routes/newRouteForm/commodity/utils';

const formatUSD = amount => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const commodityOptionToValue = option => option.value;

export const yesNoDropdownOptions = t => [
  { label: t('general-yes-text-label'), value: true },
  { label: t('general-no-text-label'), value: false },
];

const extraStrapsSize = 9;

// This function will be implemented to let user select 1-8 extra straps in the _Accessorials_ input table
export const buildStrapsOptions = t =>
  [...Array(extraStrapsSize)].map((strap, index) => ({
    label: `${index} ${t('general-extra-strap-text-label')}`,
    value: index,
  }));

export const isFumigationAccessorial = (object, property = 'id') => {
  return object[property] === COMMODITIES_SPECIAL_REQUIREMENTS.FUMIGATION;
};

export const isStrapsAccessorial = (object, property = 'id') => {
  return object[property] === COMMODITIES_SPECIAL_REQUIREMENTS.STRAPS;
};

export const formatMoneyWithCurrency = (amount, currency, formatOptions) =>
  `${formatMoney(amount, formatOptions)} ${currency.toUpperCase()}`;

/**
 * Converts USD cents to dollars
 * @param { number } amount
 */
export const centsToDollars = amount => {
  if (isNaN(amount)) {
    amount = 0;
  }

  return amount / 100;
};

/**
 * Format `amount` as USD currency
 * @param { number } amount
 * @param { Intl.NumberFormatOptions } formatOptions
 */
export const formatMoney = (amount, formatOptions = {}) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    ...formatOptions,
  }).format(amount);

/**
 * Format `weight`
 * @param { number } weight
 * @param { Intl.NumberFormatOptions } formatOptions
 */
export const formatWeight = (weight, formatOptions = {}) =>
  new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'kilogram',
    ...formatOptions,
  }).format(weight);

/** @TODO Delete me, I'm not being used */
const priceToDecimal = amount => {
  return amount.toFixed(2);
};

/** @TODO Delete me, I'm not being used */
const trimFileExtension = fileName => {
  return fileName.replace(/\.[^/.]+$/, '');
};

/** @TODO Delete me, I'm not being used */
const pluralize = (count, t) => {
  return `${count} ${+count !== 1 ? t('trucks') : t('truck')}`;
};

export { formatUSD, priceToDecimal, pluralize, trimFileExtension };

/**
 * @deprecated Use core/utils/date-fns#diffInDays instead
 */
export const differenceInDaysFromNow = date => {
  const today = new Date();
  const dateA = new Date(date);
  var diff = today.getTime() - dateA.getTime();
  return Math.floor(diff / (1000 * 3600 * 24));
};

/**
 * @TODO Seems it is not being used.
 * @deprecated Use CSS instead
 */
export function toTitleCase(str) {
  return str
    ? str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    : '';
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @TODO This is not being used, delete me.
 * @deprecated Use lodash instead. Duplicated in common/app/Shipments/utils
 */
export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * Debounces promises. Immediately returns a promise, and allows itself to keep
 * getting called. Once the timeout of the debounce clears, it resolves the
 * last promise to all the previous promise calls. Which makes it so that you
 * only call your async resource once.
 *
 * @param { Number } wait Time to wait in milliseconds.
 */
export const debouncePromise = (func, wait) => {
  let timeout;
  let calls = [];

  return function (...args) {
    return new Promise(resolve => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        timeout = null;

        const result = func.apply(this, args);

        calls.forEach(resolve => {
          resolve(result);
        });

        calls = [];
      }, wait);

      calls.push(resolve);
    });
  };
};

//Returns mexico or USA depending on the country name coming from the backend
export const getCountryName = countryName => {
  switch (countryName) {
    case 'United States':
    case 'USA':
    case 'usa':
    case 'us':
      return 'usa';
    case 'México':
    case 'Mexico':
    case 'mexico':
    case 'mx':
      return 'mexico';
    case 'Canada':
    case 'canada':
    case 'ca':
      return 'canada';
    default:
      return undefined;
  }
};

/**
 * Validates if the environment is 'development' in env variables
 */
export const isDevelopment = process.env.NODE_ENV === 'development';

/**
 * Capitalize first letter of the given string
 * @param { string } str
 * @deprecated Please, don't use it, instead use CSS text-transform or ::first-letter
 */
export const capitalize = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const countryNameToFlag = {
  USA: usa,
  MEXICO: mexico,
  CANADA: canada,
};

export const countryShortNameToFlag = {
  US: usa,
  MX: mexico,
  CA: canada,
};

export const mapLanguageToCountry = {
  en: usa,
  es: mexico,
};

/**
 * Validates if the given argument is a function or not
 * @param { any } f Object to verify if is a function
 * @deprecated Use lodash.isFunction instead
 */
export const isFunction = f => typeof f === 'function';

/**
 * Scrolls to the given position with a smooth vehabior
 * @param { number } offsetTop
 */
export function verticalSmoothScroll(offsetTop) {
  window.scroll({
    top: offsetTop,
    left: 0,
    behavior: 'smooth',
  });
}

/**
 * Function meant to be used in combination of `Array.prototype.sort`.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * @param { Object } a
 * @param { Object } b
 * @param { string } dateAttr Name of field holding an ISO date
 * @returns If > 0, sorts `b` before `a`; if < 0, sorts `a` before `b`, else keeps original order.
 */
export function compareObjectsByDate(a, b, dateAttr) {
  return new Date(b[dateAttr]) - new Date(a[dateAttr]);
}
