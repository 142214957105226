const API_VERSION = `${process.env.REACT_APP_API_VERSION || '/v0'}`;

export const SHIPPER_API_VERSION = `${API_VERSION}/shippers`;

export const ROUTES_API_ENDPOINT = `${API_VERSION}/shippers/routes`;

export const SHIPMENTS_API_ENDPOINT = `${API_VERSION}/shippers/shipments`;

export const Lang = {
  EN: 'en',
  ES: 'es',
};

export const ADDITIONAL_SERVICES = t => [
  {
    label: t('customs'),
    value: 'customs',
    description: t('customs-description'),
  },
  {
    label: t('cross-Border-insurance'),
    value: 'cross_border_insurance',
    description: t('cross-Border-insurance-description'),
  },
  {
    label: t('financing'),
    value: 'financing',
    description: t('trade-financing-description'),
  },
];
