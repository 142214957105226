import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CTAButton,
  Heading,
  Map,
  Text,
  Toast,
  ToastContainer,
  usePageHeader,
} from '@nuvocargo/nuvo-styleguide';
import React, { useState } from 'react';

import AuthenticatedLayout from 'components/common/layout/authenticated';
import { fetchRouteByID } from 'core/api/routes';
import { buildMapLegs, formatDetails, whenRouteIs } from 'core/utils/route';
import { app } from 'routes/paths';

import { useGetPlacesByCompanyAndZipCode } from '../../core/hooks/queries/useGetPlacesByCompanyAndZipCode';
import { useUser } from '../../context/user';

import { Detail } from './Detail';
import { PriceSection } from './PriceSection';
import { Facilities } from './Facilities';

import './print.css';

export const RouteDetails = () => {
  const { showSubnav, hideSubNav } = usePageHeader();

  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { currentUser } = useUser();
  const [toasts, setToasts] = useState([]);

  const addSuccessToast = () => {
    const newToast = (
      <Toast type="success">
        <p>{t('facility-confirmation-modal-toast-success')}</p>
      </Toast>
    );
    setToasts(prevState => [...prevState, newToast]);
  };

  const addErrorToast = () => {
    const newToast = (
      <Toast type="error">
        <p>{t('global-app-error-heading')}</p>
      </Toast>
    );
    setToasts(prevState => [...prevState, newToast]);
  };

  const { isLoading, data, error } = useQuery(['fetchRouteByID', id], () =>
    fetchRouteByID({ id })
  );

  const originPlaces = useGetPlacesByCompanyAndZipCode({
    geolocationId: data?.origin?.zipCode?.id,
    companyId: currentUser?.companyId,
  });
  const destinationPlaces = useGetPlacesByCompanyAndZipCode({
    geolocationId: data?.destination?.zipCode?.id,
    companyId: currentUser?.companyId,
  });

  useEffect(() => {
    showSubnav({
      text: t('back-to-routes'),
      onGoBackClick: () => {
        history.push(app.routes.root);
        hideSubNav();
      },
    });
  }, []);

  if (isLoading || originPlaces.isLoading || destinationPlaces.isLoading) {
    return <div>{t('loading')}</div>;
  }
  if (error || !data || !originPlaces.data || !destinationPlaces.data) {
    return <div>{t('error')}</div>;
  }

  const { origin, destination, timeInTransit } = data;

  const details = formatDetails(data, t);

  return (
    <AuthenticatedLayout paddingTop="0px" ignoreGrid>
      <div className="section-to-print mx-auto grid max-w-screen-2xl gap-x-6 px-6 2xl:px-0">
        <div className="col-span-4 mb-8 nuvo-sm:col-span-12">
          <div className="flex flex-row justify-between">
            <Heading variant="h3" color="green">
              {t('route-heading', {
                originCity: origin.zipCode.parents.city,
                destinationCity: destination.zipCode.parents.city,
              })}
            </Heading>

            <CTAButton
              onClick={() => {
                history.push(
                  generatePath(app.shipments.new.pickupDates, {
                    routeId: id,
                  })
                );
                hideSubNav();
              }}
              disabled={whenRouteIs(data, {
                missingDetails: true,
                unserviceable: true,
                expired: true,
                requested: true,
                fallback: false,
              })}>
              {t('new-shipment')}
            </CTAButton>
          </div>

          <Text as="p" color="steel">
            {`${details.routeId} · ${details.companyName}`}
          </Text>
        </div>

        <div className="col-span-4 min-h-[150px] bg-nuvo-green nuvo-sm:col-span-8">
          <Map
            destinationLabel={destination.zipCode.parents.city}
            originLabel={origin.zipCode.parents.city}
            fullRoute={{
              legs: buildMapLegs(origin, destination),
            }}
            mapToken={process.env.REACT_APP_MAPBOX_TOKEN}
            showEstimatedTravelTimeInDays={{
              lower: timeInTransit?.lower,
              upper: timeInTransit?.upper,
            }}
          />
        </div>
        <div className="col-span-4 mt-6 h-full bg-white nuvo-sm:col-span-8 nuvo-sm:col-end-8">
          <Facilities
            origin={origin}
            destination={destination}
            originPlaces={originPlaces.data}
            destinationPlaces={destinationPlaces.data}
            addSuccessToast={addSuccessToast}
            addErrorToast={addErrorToast}
          />
        </div>

        <div className="col-span-4 row-start-3 pl-6 nuvo-sm:col-end-12 nuvo-sm:row-start-2 nuvo-sm:row-end-4">
          <PriceSection data={data} lastUpdatedAt={details.lastUpdatedAt} />

          <div data-testId="details">
            <div className="mb-4 flex flex-row items-center justify-between border-b border-nuvo-gray-dark/10 pb-2">
              <Text as="p" color="green" size="large" fontWeight="bold">
                {t('route-details')}
              </Text>
            </div>

            <div className="mb-6 grid grid-cols-2 gap-y-6">
              {details.routeId && (
                <Detail label={t('route-details-id')} value={details.routeId} />
              )}

              {details.equipment && (
                <Detail
                  label={t('route-details-equipment')}
                  value={details.equipment}
                />
              )}

              {details.serviceType && (
                <Detail
                  label={t('route-details-serviceType')}
                  value={details.serviceType}
                />
              )}

              {details.includeBorderCrossing && (
                <Detail
                  label={t('route-details-includeBorderCrossing')}
                  value={details.includeBorderCrossing}
                />
              )}
            </div>

            <div className="mb-6 grid grid-cols-2 gap-y-6">
              {details.commodity && (
                <>
                  {details.commodity.category && (
                    <Detail
                      label={t('route-details-commodityType')}
                      value={details.commodity.category}
                    />
                  )}

                  {details.commodity.name && (
                    <Detail
                      label={t('route-details-commodityName')}
                      value={details.commodity.name}
                    />
                  )}

                  {details.commodity.weight && (
                    <Detail
                      label={t('route-details-commodityWeight')}
                      value={details.commodity.weight}
                    />
                  )}

                  {details.commodity.value && (
                    <Detail
                      label={t('route-details-commodityValue')}
                      value={details.commodity.value}
                    />
                  )}
                </>
              )}
            </div>

            {details.specialHandling && (
              <div
                data-testid="special-handling"
                className="flex flex-col gap-1">
                <Text as="p" size="small" color="steel" fontWeight="bold">
                  {t('route-details-specialHandling')}
                </Text>

                <div className="flex flex-row flex-wrap gap-y-1 ">
                  {details.specialHandling.map(value => (
                    <div
                      className="mr-2 rounded border px-2 py-1 text-nuvo-green"
                      key={`route-requirement-${value}`}>
                      {value}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        style={{
          position: 'fixed',
          right: '40px',
          bottom: '40px',
          display: 'flex',
          flexDirection: 'column',
        }}>
        {toasts}
      </ToastContainer>
    </AuthenticatedLayout>
  );
};
