import React from 'react';
import * as FullStory from '@fullstory/browser';
import { useMutation } from 'react-query';

import { updateUser } from 'core/api/user';

import { constructInitialValues, constructValidationSchema } from './data';

export const useModal = ({ initialState }) => {
  const [isModalOpen, setModalOpen] = React.useState(initialState);
  const closeModal = () => setModalOpen(false);

  const [isSuccessful, setSuccessState] = React.useState(false);
  const showSuccess = () => setSuccessState(true);

  React.useEffect(() => {
    if (isSuccessful) {
      FullStory.event('Complete Profile Success');
      closeModal();
    }
  }, [isSuccessful]);

  if (isModalOpen) {
    FullStory.event('Complete Profile Opened');
  }

  return { isModalOpen, isSuccessful, closeModal, showSuccess };
};

export const useAdditionalAppInvite = ({
  t,
  validatePassword,
  neededFields,
  showSuccess,
}) => {
  const initialValues = constructInitialValues(neededFields);

  const validations = constructValidationSchema({
    t,
    neededFields,
    validatePassword,
  });

  const { mutate, isLoading, error } = useMutation(updateUser, {
    onSuccess: () => {
      showSuccess();
    },
  });

  const completeProfile = async ({
    id,
    password,
    roles,
    acceptTerms,
    title,
  }) => {
    const requestObj = {};
    if (neededFields.password) requestObj.password = password;
    if (neededFields.roles)
      requestObj.roles = roles.map(role => role.toUpperCase());
    if (neededFields.acceptTerms) requestObj.acceptTerms = acceptTerms;
    if (neededFields.title) requestObj.title = title;

    await mutate({ id, ...requestObj });
  };

  return { initialValues, completeProfile, validations, isLoading, error };
};
