import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

import { Box, TwoRowDetails } from 'components/generic/kit';
import { JourneyCard } from 'components/common/app';

import { SpecialHandlingTagList } from '.';

/**
 *
 * @param { Object } params
 * @param { Object } params.route See Nuvocargo's GraphQL API schema for route type
 */
export function RouteSummary({ route }) {
  const { t } = useTranslation();

  let translatedShipmentRequirements =
    route?.shipmentRequirements?.slice() ?? [];

  if (translatedShipmentRequirements.length === 0) {
    translatedShipmentRequirements.push('unspecified');
  }

  translatedShipmentRequirements = translatedShipmentRequirements.map(req =>
    t(req)
  );

  return (
    <>
      <Heading variant="h5">{t('route-summary')}</Heading>

      <Box styles={{ root: { marginTop: 50 } }}>
        <JourneyCard origin={route?.origin} destination={route?.destination} />
      </Box>

      <Heading
        styles={{
          root: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
            marginTop: 50,
            paddingBottom: 8,
          },
        }}
        variant="h6">
        {t('route-details')}
      </Heading>

      <DetailsGrid>
        <DetailCell
          label={t('equipment-label')}
          value={t(route?.truckType || 'unknown')}
        />
        <DetailCell
          label={t('service-label')}
          value={t(route?.serviceType || 'unknown')}
        />
        <DetailCell
          label={t('commodity-type-label')}
          value={
            route?.commodities?.nodes?.[0]?.category
              ? t(`commodity-${route?.commodities?.nodes?.[0]?.category}`)
              : t('unknown')
          }
        />
        <DetailCell
          label={t('commodity-name-label')}
          value={route?.commodities?.nodes?.[0]?.name || t('unknown')}
        />
        <TwoRowDetails
          top={
            <Text color="darkGray" size="small">
              {t('special-handling-label')}
            </Text>
          }
          bottom={
            <SpecialHandlingTagList tags={translatedShipmentRequirements} />
          }
        />
      </DetailsGrid>
    </>
  );
}

const DetailsGrid = styled.div`
  margin: 36px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 24px;
`;
DetailsGrid.displayName = 'DetailsGrid';

function DetailCell({ label, value }) {
  return (
    <TwoRowDetails
      top={
        <Text color="darkGray" size="small">
          {label}
        </Text>
      }
      bottom={<Text>{value}</Text>}
    />
  );
}
