import React from 'react';
import styled, { css } from 'styled-components/macro';

const Root = styled.div`
  box-sizing: border-box;
  display: flex;
`;

/**
 * Extensible component with display flex style
 * @param { Object } props
 * @param { React.ReactNode } props.children
 * @param { any } props.styles Styles Object
 */
export function Flex({ children, styles = { root: {} }, ...props }) {
  return (
    <Root css={css(styles.root)} {...props}>
      {children}
    </Root>
  );
}
