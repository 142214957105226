import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Layout from 'components/common/layout/authenticated';
import { useUser } from 'context/user';
import { assertGraphQLError, graphQLRequest } from 'core/utils/request';

import { SHIPMENT_QUERY } from './graphql/queries/shipments';
import {
  RouteDetails,
  Documents,
  ShipmentEvents,
  Map,
  RouteOverview,
} from './components';
import { extractDocumentsFromShipment } from './utils/documents';

function ShipmentDetails() {
  const { id: shipmentId } = useParams();
  const { currentUser } = useUser();
  const companyId = String(currentUser?.companyId);

  const { isLoading, data: shipment } = useQuery(
    ['customerui_getShipment', companyId, shipmentId],
    () =>
      graphQLRequest({
        body: {
          query: SHIPMENT_QUERY,
          variables: {
            companyId,
            shipmentId,
          },
        },
      })
        .then(assertGraphQLError)
        .then(
          response => response?.data?.data?.shipments?.edges?.[0]?.node ?? null
        ),
    {
      select: shipment => {
        return {
          ...shipment,
          pickup: {
            ...shipment?.shipmentEvents.nodes.find(
              ({ type }) => type === 'PICKED_UP_FROM_ORIGIN'
            ),
          },
          delivery: {
            ...shipment?.shipmentEvents.nodes.find(
              ({ type }) => type === 'ARRIVED_TO_DESTINATION'
            ),
          },
          documents: extractDocumentsFromShipment(shipment),
        };
      },
    }
  );

  return (
    <Layout paddingTop="16px" ignoreGrid>
      <div className="container mx-auto grid grid-cols-1 gap-6 px-2 font-value-sans-regular text-base lg:grid-cols-12 lg:px-0 ">
        <RouteOverview isLoading={isLoading} shipment={shipment} />

        <Map isLoading={isLoading} shipment={shipment} />

        <RouteDetails isLoading={isLoading} shipment={shipment} />

        <ShipmentEvents
          isLoading={isLoading}
          isShipmentCompleted={shipment?.state === 'COMPLETED'}
          currentShipmentEvent={shipment?.currentShipmentEvent}
          events={shipment?.shipmentEvents?.nodes ?? []}
        />

        <Documents isLoading={isLoading} shipment={shipment} />
      </div>
    </Layout>
  );
}

export default ShipmentDetails;
