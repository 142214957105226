import React, { useMemo } from 'react';
import { TextCell, StatusFilledCell } from '@nuvocargo/nuvo-styleguide';

import { formatShortDate } from 'core/utils/date-fns';

import { INVOICE_STATUSES } from './hooks';

const CreateColumns = ({ t, invoiceCompanyOptions = [] }) =>
  useMemo(
    () =>
      [
        // ID
        {
          Header: () => t('invoice-column-id'),
          accessor: 'fileName',
          Cell: row => <TextCell textColor="green" text={row.cell.value} />,
          disableSortBy: true,
          autoFilter: {
            type: 'search',
            placeholder: t('invoice-filter-shipment-id-reference-number'),
            name: 'searchFor',
          },
        },
        // Issued
        {
          Header: () => t('invoice-column-issued'),
          accessor: 'createdAt',
          Cell: row => (
            <TextCell
              textColor="steel"
              text={formatShortDate(row.cell.value)}
            />
          ),
          disableSortBy: true,
        },
        // Reference Number
        {
          Header: () => t('invoice-column-reference-number'),
          accessor: 'customerReference',
          Cell: row => <TextCell textColor="steel" text={row.cell.value} />,
          disableSortBy: true,
        },
        // Total
        {
          Header: () => t('invoice-column-total'),
          accessor: 'total',
          Cell: row => <TextCell text={row.cell.value} />,
          disableSortBy: true,
        },
        // Balance Due
        {
          Header: () => t('invoice-column-balance-due'),
          accessor: 'due',
          Cell: row => <TextCell text={row.cell.value} />,
          disableSortBy: true,
        },
        invoiceCompanyOptions.length > 1 && {
          Header: () => t('invoice-column-invoice-company'),
          accessor: 'invoiceCompany',
          Cell: row => <TextCell text={row.cell.value?.legalName || ''} />,
          autoFilter: {
            type: 'filter',
            label: 'Invoice Company',
            options: invoiceCompanyOptions,
          },
          disableSortBy: true,
        },
        // Currency
        {
          Header: () => t('invoice-column-currency'),
          accessor: 'currency',
          Cell: row => <TextCell textColor="steel" text={row.cell.value} />,
          autoFilter: {
            type: 'filter',
            label: t('invoice-filter-currency'),
            options: [
              {
                label: t('invoice-filter-usd-label'),
                value: 'USD',
              },
              {
                label: t('invoice-filter-mxn-label'),
                value: 'MXN',
              },
            ],
          },
          disableSortBy: true,
        },
        // Status
        {
          Header: () => t('invoice-column-status'),
          accessor: 'status',
          Cell: ({ cell, row }) => (
            <StatusFilledCell
              text={t(`invoice-status-${cell.value}`, {
                count:
                  cell.value === INVOICE_STATUSES.due
                    ? row.original.dueInDays
                    : row.original.overdueByDays,
              })}
              status={row.original.state}
            />
          ),
          autoFilter: {
            type: 'filter',
            label: t('invoice-filter-status'),
            options: [
              {
                label: t('invoice-filter-outstanding-label'),
                value: 'outstanding',
              },
              {
                label: t('invoice-filter-paid-label'),
                value: 'paid',
              },
            ],
          },
          disableSortBy: true,
        },
      ].filter(Boolean),
    [t, invoiceCompanyOptions]
  );

export default CreateColumns;
