import { useMemo, useState } from 'react';
import * as FullStory from '@fullstory/browser';
import { CTAButton, Text, Icon } from '@nuvocargo/nuvo-styleguide';
import { Dropdown } from '@nuvocargo/nuvo-styleguide/forms/native';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SpinnerIcon } from 'assets/images/icons/spinner.svg';

import FilesList from './List';
import NoDocuments from './Empty';
import { useShipmentAttachments } from './hooks';

const DOCUMENT_TYPES = {
  pedimento: 'pedimento',
  customs_form_7501: 'customs_form_7501',
  customs_form_3461: 'customs_form_3461',
  tsca: 'tsca',
  in_bond_document: 'in_bond_document',
  bill_of_lading: 'bill_of_lading',
  air_waybill: 'air_waybill',
  fda_release: 'fda_release',
  add_cvd_non_reimbursement: 'add_cvd_non_reimbursement',
  rated_commercial_invoice: 'rated_commercial_invoice',
  commercial_invoice: 'commercial_invoice',
  packing_list: 'packing_list',
  certificate: 'certificate',
  permit: 'permit',
  cites: 'cites',
  non_cites: 'non_cites',
  steel_license: 'steel_license',
  other_customs_paperwork: 'other_customs_paperwork',
  uncategorized: 'uncategorized',
};

const transformFiles = files =>
  files.reduce((acc, file) => {
    const key = Math.random().toString(16).slice(2);
    acc[`file-${key}`] = {
      name: file.name,
      category: null,
      blob: file,
    };
    return acc;
  }, {});

export const Documents = props => {
  const { shipment, isLoading } = props;
  const [files, setFiles] = useState({});
  const { t } = useTranslation();
  const attacher = useShipmentAttachments();
  const dropdownOptions = useMemo(
    () =>
      Object.entries(DOCUMENT_TYPES)
        .slice(0, -2) // removing 'other_customs_paperwork' and 'uncategorized' so they can be added in last
        .map(([key, value]) => ({
          value,
          label: t(`doc_${key}`),
        }))
        .sort((a, b) => a['label'].localeCompare(b['label'])) // Sort alphabetically by label
        .concat([
          // adding 'other_customs_paperwork' and 'uncategorized' so they can be added in last
          {
            value: 'other_customs_paperwork',
            label: t('doc_other_customs_paperwork'),
          },
          { value: 'uncategorized', label: t('doc_uncategorized') },
        ]),
    [t]
  );

  if (isLoading) {
    return (
      <div className="col-span-1 h-52 animate-pulse rounded bg-nuvo-gray-dark/25 p-8 lg:col-span-7" />
    );
  }

  const { id: shipmentId, documents = [] } = shipment;

  const handleSelectFiles = e => {
    const newFiles = transformFiles([...e.target.files]);
    setFiles(prevFiles => ({ ...prevFiles, ...newFiles }));
  };

  const handleRemoveFile = fileKey => e => {
    setFiles(prevFiles => {
      const newFiles = Object.assign({}, prevFiles);
      delete newFiles[fileKey];
      return newFiles;
    });
  };

  const handleCategoryChange = fileKey => category => {
    setFiles(prevFiles => {
      const newFiles = Object.assign({}, prevFiles);
      newFiles[fileKey].category = category.value;
      return newFiles;
    });
  };

  const parseFiles = () => {
    const body = new FormData();
    Object.values(files).forEach(file => {
      body.append(`files[]name`, file.name);
      body.append(`files[]category`, file.category);
      body.append(`files[]file`, file.blob);
    });
    return body;
  };

  const handleFormSubmit = e =>
    attacher.mutate(
      { shipmentId, body: parseFiles() },
      {
        onSuccess: () => {
          FullStory.event('[SHIPMENT] Uploaded Documents', {
            shipmentId,
            documents: Object.values(files).map(({ name, category }) => ({
              name,
              category,
            })),
          });
          setFiles({});
        },
      }
    );

  const filesToUpload = Object.entries(files).map(([key, file]) => (
    <li
      key={key}
      className="flex items-center justify-between gap-2 py-5 px-1 transition-colors hover:bg-nuvo-gray/5 sm:gap-5">
      <Icon name="insertDriveFile" size="medium" color="steel" />
      <div className="flex-1 break-all">
        <Text>{file.name}</Text>
      </div>
      <div className="flex-1">
        <Dropdown
          options={dropdownOptions}
          name={`${key}-category`}
          placeholder={t('choose-document-type')}
          onChange={handleCategoryChange(key)}
        />
      </div>
      <button
        className="rounded px-4 py-2 font-value-sans-medium text-nuvo-forest transition hover:bg-nuvo-forest/10  focus:ring focus:ring-nuvo-forest sm:px-2 sm:py-1"
        type="button"
        onClick={handleRemoveFile(key)}>
        {t('button-remove')}
      </button>
    </li>
  ));

  const hasShipmentDocuments = !!documents.length;
  const hasFilesToUpload = !!Object.keys(files).length;
  const showNoDocuments = !hasShipmentDocuments && !hasFilesToUpload;
  const isSubmitButtonDisabled =
    attacher.isLoading ||
    !hasFilesToUpload ||
    Object.values(files).some(file => file.category === null);

  return (
    <div className="col-span-1 rounded   bg-nuvo-white p-8 lg:col-span-7 ">
      {showNoDocuments ? (
        <NoDocuments>
          <FileUploadButton
            label={t('upload-documents')}
            onSelectFiles={handleSelectFiles}
          />
        </NoDocuments>
      ) : (
        <div>
          <h4
            className=" mb-8 font-value-sans-regular  text-3xl text-nuvo-green"
            data-testid="shipment-details-documents-section-title">
            {t('documents')}
          </h4>

          {hasShipmentDocuments && <FilesList t={t} documents={documents} />}

          {hasFilesToUpload && (
            <ul className="divide-y divide-nuvo-gray/10">{filesToUpload}</ul>
          )}

          <div className="mt-8 flex flex-row justify-end gap-4">
            <FileUploadButton
              type="secondary"
              label={t('upload-more-files')}
              onSelectFiles={handleSelectFiles}
            />

            <CTAButton
              type="button"
              disabled={isSubmitButtonDisabled}
              styles={{
                common: {
                  minWidth: '128px',
                },
              }}
              onClick={handleFormSubmit}>
              {attacher.isLoading ? (
                <SpinnerIcon className="h-5 w-5 animate-spin text-nuvo-white" />
              ) : (
                t('save-and-send')
              )}
            </CTAButton>
          </div>
        </div>
      )}
    </div>
  );
};

const FileUploadButton = ({ type = 'primary', label, onSelectFiles }) => {
  const baseClasses =
    'rounded-full px-4 font-value-sans-regular flex items-center cursor-pointer';
  const typeClasses = {
    primary: 'bg-nuvo-forest text-white hover:bg-nuvo-green py-2 ',
    secondary:
      'bg-nuvo-steel-soft/20 text-nuvo-forest hover:bg-nuvo-steel-soft/50 focus:bg-nuvo-steel-soft/50 ',
  };

  return (
    <label
      htmlFor="upload-files"
      className={`${baseClasses} ${typeClasses[type]} `}>
      {label}
      <input
        id="upload-files"
        type="file"
        multiple
        onChange={onSelectFiles}
        hidden
      />
    </label>
  );
};
