import styled from 'styled-components';

export const JourneyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
JourneyCardWrapper.displayName = 'JourneyCardWrapper';

export const JourneyCardItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
JourneyCardItemWrapper.displayName = 'JourneyCardItemWrapper';

export const JourneyCardItemWrapperHeader = styled.div`
  display: flex;
  align-items: center;
`;
JourneyCardItemWrapperHeader.displayName = 'JourneyCardItemWrapperHeader';

export const JourneyCardItemWrapperBody = styled.div`
  display: flex;
`;
JourneyCardItemWrapperBody.displayName = 'JourneyCardItemWrapperBody';

export const JourneyCardItemWrapperBodyDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 2px;
`;
JourneyCardItemWrapperBodyDetails.displayName =
  'JourneyCardItemWrapperBodyDetails';

export const JourneyCardItemVerticalRule = styled.div`
  width: 2px;
  background-color: ${({ theme }) => theme.colors.softSteel};
`;
JourneyCardItemVerticalRule.displayName = 'JourneyCardItemVerticalRule';

export const JourneyCardLeftItemWrapper = styled.div`
  width: ${({ width }) => width};
  margin-right: ${({ marginRight }) => marginRight};
  display: flex;
  flex: none;
  justify-content: center;
`;
JourneyCardLeftItemWrapper.displayName = 'JourneyCardLeftItemWrapper';
