import React from 'react';
import {
  Grid,
  GridColumn,
  Heading,
  Text,
  CTAButton,
} from '@nuvocargo/nuvo-styleguide';
import {
  TextField,
  UnitField,
  Dropdown,
  Textarea,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import { Form } from 'formik';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';

import { useWindowSize } from 'core/hooks';
import { Switch } from 'components/generic/form';
import { Stagger, Stack } from 'components/generic/kit';
import { buildStrapsOptions, yesNoDropdownOptions } from 'core/utils';

import { isMobile as checkIsMobile } from '../../utils';

import { createOptions } from './data';
import { useSpecialHandlingTable } from './hooks';

export default function NewRoutesCommodityForm(props) {
  const { accessorials, values } = props;
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = checkIsMobile(width);
  const { columns } = useSpecialHandlingTable({ accessorials, t });

  return (
    <Stagger
      style={{
        marginTop: `${isMobile ? '32px' : '48px'}`,
      }}>
      <Form>
        <Grid>
          <GridColumn startMd={1} md={12} sm={4} startSm={1}>
            <StaggerChild notMargin>
              <Dropdown
                id="onboarding-category-input"
                label={t('onboarding-category-label')}
                placeholder={t('commodity-placeholder')}
                name="category"
                autocomplete
                options={createOptions(t)}
                size={isMobile ? 'small' : 'medium'}
              />
            </StaggerChild>
            <StaggerChild isSpaced>
              <TextField
                name="name"
                label={t('onboarding-item-description-label')}
                type="text"
                placeholder={t('onboarding-item-description-placeholder')}
                size={isMobile ? 'small' : 'medium'}
              />
            </StaggerChild>

            <StaggerChild isSpaced>
              <UnitField
                name="weight"
                label={t('onboarding-item-weight-label')}
                type="number"
                placeholder={t('onboarding-item-weight-placeholder')}
                variant="weight"
                size={isMobile ? 'small' : 'medium'}
              />
            </StaggerChild>

            <StaggerChild
              isSpaced
              style={{
                marginTop: '36px',
              }}>
              <Stack>
                <Stack
                  axis="horizontal"
                  style={{ justifyContent: 'space-between' }}>
                  <Heading
                    color="green"
                    variant="h6"
                    styles={{
                      root: {
                        fontSize: isMobile ? '16px' : '18px',
                        paddingTop: isMobile ? '2px' : '4px',
                        paddingLeft: '2px',
                        paddingRight: isMobile ? '' : '16px',
                      },
                    }}>
                    {t('onboarding-is-shipment-value-over-100k')}
                  </Heading>
                  <Switch id="toggle-shipment-value" name="showShipmentValue" />
                </Stack>

                {values.showShipmentValue && (
                  <div style={{ marginTop: '16px' }}>
                    <UnitField
                      name="valuePerLoad"
                      label={t('onboarding-value-per-load-label')}
                      type="number"
                      placeholder={t('onboarding-value-per-load-placeholder')}
                      staticValue={t('usd-abbreviation')}
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </div>
                )}
              </Stack>
            </StaggerChild>

            <StaggerChild isSpaced>
              <Heading
                color="green"
                variant="h6"
                styles={{
                  root: {
                    lineHeight: '16px',
                    fontSize: isMobile ? '12px' : '16px',
                    margin: isMobile ? '8px 0 24px 0' : '48px 0 24px 0',
                  },
                }}>
                {t('special-handling-required')}
              </Heading>
              {isMobile ? (
                <>
                  <StaggerChild isSpaced>
                    <Dropdown
                      id="fugimation"
                      name="fumigation"
                      label={t('fumigation')}
                      autocomplete
                      options={yesNoDropdownOptions(t)}
                    />
                  </StaggerChild>
                  <StaggerChild isSpaced>
                    <Dropdown
                      id="straps-needed"
                      name="straps"
                      label={t('straps_needed')}
                      autocomplete
                      options={buildStrapsOptions(t)}
                    />
                  </StaggerChild>
                </>
              ) : (
                accessorials?.length > 0 && (
                  <InputTable
                    hideActions
                    name="specialHandling"
                    columnIdentifier="id"
                    columns={columns}
                  />
                )
              )}
            </StaggerChild>

            <StaggerChild>
              <Textarea
                label={t('commodity-additional-notes-label')}
                name="notes"
                placeholder={t('commodity-additional-requirements-placeholder')}
                styles={{
                  root: {
                    marginTop: '20px',
                  },
                }}
              />
            </StaggerChild>

            <BtnContainer
              topSpacing="32px"
              bottomSpacing={isMobile ? '60px' : '100px'}>
              <CTAButton
                type="submit"
                styles={{
                  primary: {
                    width: '212px',
                  },
                }}>
                <Text color="white">{t('next')}</Text>
              </CTAButton>
            </BtnContainer>
          </GridColumn>
        </Grid>
      </Form>
    </Stagger>
  );
}

const StaggerChild = styled(Stagger.Child)`
  ${({ isSpaced }) => (isSpaced ? 'margin-top: 32px' : '')};
  text-align: left;
  ${({ theme }) =>
    css`
      @media (max-width: ${theme.mediaSizes.mobileL}) {
        ${({ notMargin }) => (notMargin ? '' : 'margin-top: 24px')}
      }
    `};
`;

const BtnContainer = styled.div`
  margin-top: ${({ topSpacing }) => (topSpacing ? topSpacing : '40px')};
  margin-bottom: ${({ bottomSpacing }) =>
    bottomSpacing ? bottomSpacing : '40px'};
  display: flex;
  justify-content: center;
`;
