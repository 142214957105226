import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useNuvoTable } from '@nuvocargo/nuvo-styleguide';

import { fetchPricedRoutes, fetchRoute, fetchRoutes } from 'core/api/routes';
import { useUser } from 'context/user';
import {
  getCursorFromOffset,
  getOffsetFromPageNumber,
} from 'core/utils/pagination';

/** Routes to fetch per page */
const PAGE_SIZE = 25;

/**
 * Custom hook to retrieve routes by the current user's company id
 */
export function useRoutes() {
  const { currentUser } = useUser();

  const [page, setPage] = useState(1);

  const companyId = String(currentUser?.companyId);

  const { data, error, isLoading, isFetching } = useQuery(
    ['ql_routes', companyId, { page }],
    ({ signal }) =>
      fetchRoutes({
        companyId,
        cursor: getCursorFromOffset(
          getOffsetFromPageNumber(PAGE_SIZE, page - 1)
        ),
        pageSize: PAGE_SIZE,
        signal,
      }),
    {
      enabled: !!currentUser?.id,
      keepPreviousData: true,
    }
  );

  const gotoPage = page => setPage(page + 1);
  const nextPage = () => setPage(page => page + 1);
  const previousPage = () => setPage(page => page - 1);

  const pagination = {
    pageCount: Math.ceil((data?.totalCount ?? 1) / PAGE_SIZE),
    pageIndex: page - 1,
    gotoPage,
    nextPage,
    previousPage,
  };

  return {
    error,
    isFetching,
    isLoading,
    pagination,
    routes: data?.nodes,
  };
}

/**
 * Custom hook to retrieve a route from the current user based on routeId in URL
 */
export function useRoute() {
  const { routeId } = useParams();
  const { currentUser } = useUser();

  const companyId = String(currentUser?.companyId);

  const {
    data: route,
    error,
    isError,
    isLoading,
    refetch,
  } = useQuery(
    ['ql_route', companyId, routeId],
    ({ signal }) => fetchRoute({ companyId, routeId, signal }),
    {
      enabled: Boolean(currentUser?.id),
    }
  );

  return {
    error,
    isError,
    isLoading,
    refetch,
    route,
  };
}

const usePricedRoutes = ({ companyId, size, after, currentUser }) =>
  useQuery(
    ['getPricedRoutes', companyId, size, after],
    () =>
      fetchPricedRoutes({
        companyId,
        pageSize: size,
        after,
      }),
    {
      enabled: !!currentUser?.id,
      keepPreviousData: true,
    }
  );
/**
 * Custom hook to retrieve routes by the current user's company id
 */
export function useRoutesIndexTable() {
  const { currentUser } = useUser();
  const {
    contextState: {
      page: { number, size },
    },
  } = useNuvoTable();

  const companyId = String(currentUser?.companyId);

  const { data, error, isLoading, isFetching } = usePricedRoutes({
    companyId,
    size,
    after: getCursorFromOffset(getOffsetFromPageNumber(size, number)),
    currentUser,
  });

  return {
    error,
    isFetching,
    isLoading,
    routes: data?.nodes ?? [],
    totalItems: data?.totalCount || 0,
  };
}
