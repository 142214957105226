import React, { useCallback } from 'react';
import { Table } from '@nuvocargo/nuvo-styleguide';
import { generatePath, useHistory } from 'react-router-dom';

import { app } from 'routes/paths';

import { useRoutesIndexTable } from '../../routes-index/hooks';

import useRouteTable from './hook';

export default function RoutesTable() {
  const { columns } = useRouteTable();
  const history = useHistory();
  const { isLoading, routes, totalItems } = useRoutesIndexTable();

  const onClickHandler = useCallback(
    row => {
      history.push(
        generatePath(app.routes.details, {
          id: row.original.id,
        })
      );
    },
    [generatePath, app, history]
  );

  return (
    <Table
      autoFilters={{ enabled: false }}
      columns={columns}
      data={routes}
      onRowClick={onClickHandler}
      empty={{}}
      isLoading={isLoading}
      pagination={{
        totalItems,
      }}
    />
  );
}
