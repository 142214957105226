import { useQuery } from 'react-query';

import { getInvoices } from 'core/api/billing';

export const useInvoices = (
  { currency, page = 1, perPage = 50, status, invoiceCompany, searchFor } = {},
  queryOptions
) =>
  useQuery(
    ['invoices', page, perPage, status, currency, invoiceCompany, searchFor],
    ({ signal }) =>
      getInvoices({
        params: {
          page,
          items: perPage,
          'filter[currency]': currency,
          'filter[status]': status,
          'filter[invoiceCompany]': invoiceCompany,
          'filter[searchFor]': searchFor,
        },
        signal,
      }),
    queryOptions
  );
