import React from 'react';

import { theme } from 'styles';

export default function useMediaQueries() {
  const doesItMatch = query => window.matchMedia(query)?.matches;

  const [queries, setQueries] = React.useState({
    isSm: doesItMatch(theme.device.sm),
    isTablet: doesItMatch(theme.device.tablet),
  });

  React.useLayoutEffect(() => {
    const handleResize = () =>
      setQueries({
        isSm: doesItMatch(theme.device.sm),
        isTablet: doesItMatch(theme.device.tablet),
      });

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return queries;
}
