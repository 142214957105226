import React from 'react';
import { Formik } from 'formik';
import { Link, CTAButton, Text } from '@nuvocargo/nuvo-styleguide';
import { useTranslation, Trans } from 'react-i18next';
import {
  Checkbox,
  PhoneNumberField,
  TextField,
  Password,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import {
  MIN_LENGTH,
  SPECIAL_CHARACTERS,
  NUMBERS,
  validatePassword as initValidatePassword,
} from '@nuvocargo/nuvo-styleguide/forms/native/Password/validations';

import { ErrorMessage } from 'components/generic/form';
import { useWindowSize } from 'core/hooks';
import { useOnboarding } from 'context/onboarding/index';
import { Stagger, Box } from 'components/generic/kit';
import OnBoarding from 'components/common/layout/unauthenticated/onboarding';

import { isMobile as checkIsMobile } from '../utils';
import { BtnContainer } from '../styles';

import {
  TEXT_INPUT_STYLES,
  FormWrapper,
  FlexGroup,
  FlexSpacer,
} from './styles';
import { useSubmitOnboarding } from './hooks';
import { initialValues, validationSchema } from './data';

const validatePassword = initValidatePassword({ minLength: 8 });

export default function CreateAccount() {
  const { width } = useWindowSize();
  const { answers } = useOnboarding();
  const { t, i18n } = useTranslation();
  const isMobile = checkIsMobile(width);

  const { isLoading, error, validateUserAndCreateLaneRequest } =
    useSubmitOnboarding({ language: i18n.language });

  return (
    <OnBoarding
      title={t('onboarding-almost-done-one-more-step')}
      subtitle={t('onboarding-user-sign-up-explanation')}>
      <Stagger>
        <Formik
          initialValues={{ ...initialValues, ...answers }}
          validationSchema={validationSchema({ t, validatePassword })}
          onSubmit={validateUserAndCreateLaneRequest}>
          {({ values }) => (
            <FormWrapper>
              <FlexGroup>
                <FlexSpacer isFirst>
                  <Stagger.Child>
                    <TextField
                      styles={TEXT_INPUT_STYLES}
                      name="firstName"
                      label={t('first-name')}
                      type="text"
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </Stagger.Child>
                </FlexSpacer>
                <FlexSpacer isFirst>
                  <Stagger.Child>
                    <TextField
                      styles={TEXT_INPUT_STYLES}
                      name="lastName"
                      label={t('last-name')}
                      type="text"
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </Stagger.Child>
                </FlexSpacer>
              </FlexGroup>
              <FlexGroup>
                <FlexSpacer>
                  <Stagger.Child>
                    <TextField
                      styles={TEXT_INPUT_STYLES}
                      name="companyName"
                      label={t('company-name')}
                      type="text"
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </Stagger.Child>
                </FlexSpacer>
                <FlexSpacer>
                  <Stagger.Child>
                    <PhoneNumberField
                      styles={TEXT_INPUT_STYLES}
                      name="phoneNumber"
                      label={t('phone-number')}
                      buttonPlaceholder={t(
                        'phone-number-country-code-placeholder'
                      )}
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </Stagger.Child>
                </FlexSpacer>
              </FlexGroup>
              <FlexGroup>
                <FlexSpacer>
                  <Stagger.Child>
                    <TextField
                      styles={TEXT_INPUT_STYLES}
                      name="email"
                      label={t('email')}
                      type="email"
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </Stagger.Child>
                </FlexSpacer>
                <FlexSpacer>
                  <Stagger.Child>
                    <Password
                      styles={TEXT_INPUT_STYLES}
                      name="password"
                      label={t('password')}
                      useInternalValidation={true}
                      overrideValidationMessages={{
                        [MIN_LENGTH]: t('password-validation-min-length'),
                        [SPECIAL_CHARACTERS]: t(
                          'password-validation-special-characters'
                        ),
                        [NUMBERS]: t('password-validation-number'),
                      }}
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </Stagger.Child>
                </FlexSpacer>
              </FlexGroup>
              <Stagger.Child>
                <Box
                  styles={{
                    root: {
                      marginTop: isMobile ? '2.25em' : '3em',
                    },
                  }}>
                  <Checkbox
                    style={{
                      justifyContent: 'flex-start',
                      left: 0,
                    }}
                    name="acceptTerms"
                    styles={{
                      label: {
                        marginLeft: '1em',
                        maxWidth: isMobile ? '230px' : '',
                        textAlign: 'left',
                      },
                      root: {
                        marginBottom: isMobile ? '20px' : '',
                      },
                    }}
                    label={
                      <Trans i18nKey="onbaording-terms-agreement">
                        I agree to the Nuvocargo{' '}
                        <Link
                          href={`${process.env.REACT_APP_LANDING_PAGE_URL}/${i18n.language}/terms-and-conditions#terms-of-use`}
                          type="inline"
                          color="steel"
                          target="_blank">
                          Terms of Use
                        </Link>{' '}
                        and{' '}
                        <Link
                          href={`${process.env.REACT_APP_LANDING_PAGE_URL}/${i18n.language}/terms-and-conditions#shipper-terms`}
                          type="inline"
                          color="steel"
                          target="_blank">
                          Shipper Terms
                        </Link>
                      </Trans>
                    }
                  />
                </Box>
                {error?.data?.message && (
                  <ErrorMessage backgroundColor="taupe">
                    {error.data.message}
                  </ErrorMessage>
                )}
                <BtnContainer
                  topSpacing="8px"
                  bottomSpacing={isMobile ? '60px' : '100px'}>
                  <CTAButton
                    type="submit"
                    disabled={!values?.acceptTerms || isLoading}
                    styles={{
                      primary: {
                        width: '212px',
                      },
                    }}>
                    <Text color="white">{t('request-quote-cta')}</Text>
                  </CTAButton>
                </BtnContainer>
              </Stagger.Child>
            </FormWrapper>
          )}
        </Formik>
      </Stagger>
    </OnBoarding>
  );
}
