import { gql } from 'graphql-request';

export const CREATE_INVITE_MUTATION = gql`
  mutation customerui_createInvite($emails: String!, $language: String) {
    createInvite(input: { emails: $emails, language: $language }) {
      success
      ignoredExistingEmails
      failedExistingCompanyEmails
    }
  }
`;
