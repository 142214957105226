import React, { useMemo } from 'react';

import { MapStaticImage } from 'screens/routes/components';

const LAREDO = {
  longitude: -99.484199,
  latitude: 27.611218,
};

export function RouteMap({ origin, destination }) {
  if (
    !origin?.zipCode?.parents?.country ||
    !destination?.zipCode?.parents?.country
  ) {
    return null;
  }

  const createPoint = point => ({
    lng: Number(point.longitude),
    lat: Number(point.latitude),
  });

  /**
   * Hack here.
   * Legs are not available at route level, we use the origin & destination coords
   * to virtually create the legs.
   */
  const legs = useMemo(() => {
    // If it is domestic we only need two legs
    if (
      origin.zipCode.parents.country === destination.zipCode.parents.country
    ) {
      return [createPoint(origin.zipCode), createPoint(destination.zipCode)];
    }

    // Otherwise, we hardcode Laredo, since it is the only entrypoint (ATM) between countries
    return [
      createPoint(origin.zipCode),
      createPoint(LAREDO),
      createPoint(destination.zipCode),
    ];
  }, [origin, destination]);

  return <MapStaticImage coordinates={legs} />;
}
