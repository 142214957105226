import React from 'react';
import { Tag } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

export function SpecialHandlingTagList({ tags }) {
  return (
    <TagList>
      {tags.map(tag => (
        <SpecialHandlingTag key={tag} tag={tag} />
      ))}
    </TagList>
  );
}

function SpecialHandlingTag({ tag }) {
  const styles = {
    root: {
      backgroundColor: 'rgba(64, 64, 64, 0.05)',
    },
  };

  return <Tag styles={styles}>{tag}</Tag>;
}

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  margin-top: 8px;
`;
TagList.displayName = 'TagList';
