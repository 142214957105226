import { gql } from 'graphql-request';

export const SHIPMENT_REQUESTS_MUTATION = gql`
  mutation customerui_createShipmentRequest(
    $requests: [ShipmentRequestInput!]!
  ) {
    createShipmentRequest(input: { attributes: $requests }) {
      success
    }
  }
`;
