import { Icon, Text } from '@nuvocargo/nuvo-styleguide';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export function RouteDetails(props) {
  const { isLoading, shipment } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="col-span-1 h-52 animate-pulse rounded bg-nuvo-gray-dark/25 lg:col-span-5 lg:col-start-8 lg:row-span-2 lg:row-start-3 lg:px-0" />
    );
  }

  const price = (shipment.priceInCents / 100).toFixed(2);

  return (
    <div className="col-span-1 flex flex-col gap-10 px-8 font-value-sans-regular lg:col-span-5 lg:col-start-8 lg:row-span-2 lg:row-start-3 lg:px-0">
      <div className="flex flex-col gap-1">
        <p className="mb-2 border-b border-nuvo-gray/10 pb-3 text-lg font-medium leading-6 text-nuvo-green ">
          {t('single-shipment-route-details-title')}
        </p>
        <Text as="p" size="extraLarge" color="blue">
          ${price} {shipment.currency}
        </Text>
        <Text as="p" color="darkGrey">
          {t('single-shipment-route-details-billed-to', {
            name: shipment.company.name,
          })}
        </Text>
      </div>

      <div className="flex flex-col gap-5">
        {shipment.legs
          .filter(({ crossBorder }) => !crossBorder)
          .map((leg, index) => (
            <Leg key={leg.id} leg={leg} index={index} />
          ))}
      </div>
    </div>
  );
}

function Leg(props) {
  const { index, leg } = props;
  const { t } = useTranslation();
  const pendingText = t('single-shipment-route-leg-pending');
  const location =
    index === 0
      ? t('single-shipment-route-leg-subtitle-origin', {
          location: leg.destination.zipCode.parents.city,
        })
      : t('single-shipment-route-leg-subtitle-destination', {
          location: leg.destination.zipCode.parents.city,
        });

  return (
    <div className="font-value-sans-regular">
      <div className="border-b border-nuvo-gray/10 py-2 ">
        <Text as="p" color="green" size="large">
          {t('single-shipment-route-leg-title', { index: index + 1 })}
        </Text>
        <Text as="p" color="steel">
          {location}
        </Text>
      </div>
      <dl className="mt-4 grid grid-cols-2 gap-8">
        <div className="flex flex-col gap-1">
          <Text as="dt" color="steel" size="small" fontWeight="500">
            {t('single-shipment-route-leg-label-carrier-name')}
          </Text>
          <Text as="dd">{leg?.carrier?.name || pendingText}</Text>
        </div>
        <div className="flex flex-col gap-1">
          <Text as="dt" color="steel" size="small" fontWeight="500">
            {t('single-shipment-route-leg-label-driver-name')}
          </Text>
          <Text as="dd" color="softGreen">
            {leg.driverName || pendingText}
          </Text>
        </div>
        <div className="flex flex-col gap-1">
          <Text as="dt" color="steel" size="small" fontWeight="500">
            {t('single-shipment-route-leg-label-trailer-info')}
          </Text>
          <Text
            as="dd"
            color={leg.trailerNumber ? 'darkGrey' : 'softGreen'}
            styles={{
              regular: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
              },
            }}>
            <Icon name="box" className="h-6 w-6" />
            {leg.trailerNumber ? `#${leg.trailerNumber}` : pendingText}
          </Text>
          <Text
            as="dd"
            color={leg.trailerPlateNumber ? 'darkGrey' : 'softGreen'}
            styles={{
              regular: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
              },
            }}>
            <Icon name="plate" className="h-6 w-6" />
            {leg.trailerPlateNumber || pendingText}
          </Text>
          <Text
            as="dd"
            size="extraSmall"
            color="steel"
            styles={{
              paddingLeft: '32px',
            }}>
            {leg.trailerPlateState}
          </Text>
        </div>
        <div className="flex flex-col gap-1">
          <Text as="dt" color="steel" size="small" fontWeight="500">
            {t('single-shipment-route-leg-label-truck-info')}
          </Text>
          <Text
            as="dd"
            color={leg.truckNumber ? 'darkGrey' : 'softGreen'}
            styles={{
              regular: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
              },
            }}>
            <Icon name="truck" className="h-6 w-6" />
            {leg.truckNumber ? `#${leg.truckNumber}` : pendingText}
          </Text>
          <Text
            as="dd"
            color={leg.truckPlateNumber ? 'darkGrey' : 'softGreen'}
            styles={{
              regular: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
              },
            }}>
            <Icon name="plate" className="h-6 w-6" />
            {leg.truckPlateNumber || pendingText}
          </Text>
          <Text
            as="dd"
            size="extraSmall"
            color="steel"
            styles={{
              paddingLeft: '32px',
            }}>
            {leg.truckPlateState}
          </Text>
        </div>
      </dl>
    </div>
  );
}
