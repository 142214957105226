const validAttachment = record => (record?.url ? true : false);

const extractDocument = (
  array,
  propName,
  categoryPrefix = '',
  initValue = []
) =>
  array.reduce((acc, rec) => {
    if (validAttachment(rec[propName])) {
      acc.push({
        category: categoryPrefix ? `${categoryPrefix}_${propName}` : propName,
        name: rec[propName].fileName,
        createdAt: rec[propName].createdAt,
        document: {
          ...rec[propName],
        },
      });
    }
    return acc;
  }, initValue);

const objectDocument = (doc, category) => ({
  category,
  name: doc.fileName,
  createdAt: doc.createdAt,
  document: {
    ...doc,
  },
});

const mapDocument = (documents, category) =>
  documents.map(doc => objectDocument(doc, category));

export const extractDocumentsFromShipment = shipment => {
  const {
    attachments = [],
    legs = [],
    documents,
    customerInvoice,
    customerInvoiceXml,
  } = shipment;
  const cartaPortes =
    legs.filter(leg => leg.cartaPorte).map(leg => leg.cartaPorte) || [];
  const receivableLineItems =
    legs
      .filter(leg => leg.receivableLineItems?.edges.length > 0)
      .map(leg => leg.receivableLineItems)
      .flatMap(receivables => receivables.edges) || [];
  const receivableInvoices =
    receivableLineItems.map(receivableLineItem => receivableLineItem.node) ||
    [];

  const dodas = extractDocument(legs, 'doda');
  const pitas = extractDocument(legs, 'pita');
  const manifests = extractDocument(legs, 'manifest');
  const pods = extractDocument(legs, 'pod');
  const cartaPortesXml = extractDocument(
    cartaPortes,
    'xmlDocument',
    'cartaporte'
  );
  const cartaPortesFromCarrier = extractDocument(
    cartaPortes,
    'fromClient',
    'cartaporte'
  );
  const cartaPortesFromClient = extractDocument(
    cartaPortes,
    'fromCarrier',
    'cartaporte'
  );
  const invoices = extractDocument(receivableInvoices, 'invoice', 'receivable');
  const invoicesXml = extractDocument(
    receivableInvoices,
    'invoiceXml',
    'receivable'
  );
  const otherDocuments = mapDocument(documents || [], 'uncategorized');
  const customerInvoices = customerInvoice
    ? [objectDocument(customerInvoice, 'customer_invoice')]
    : [];
  const customerInvoicesXml = customerInvoiceXml
    ? [objectDocument(customerInvoice, 'customer_invoice_xml')]
    : [];

  return [
    ...attachments,
    ...dodas,
    ...pitas,
    ...manifests,
    ...pods,
    ...cartaPortesFromClient,
    ...cartaPortesXml,
    ...cartaPortesFromCarrier,
    ...invoices,
    ...invoicesXml,
    ...customerInvoices,
    ...customerInvoicesXml,
    ...otherDocuments,
  ];
};
