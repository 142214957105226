import * as yup from 'yup';

const fallbackInitialValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  phoneNumber: '',
  email: '',
  password: '',
  role: 'Logistics / Operations',
  title: '',
  acceptTerms: false,
};

export const constructInitialValues = asyncInitialValues => {
  const initialValues = {};
  Object.keys(fallbackInitialValues).forEach(field => {
    initialValues[field] =
      asyncInitialValues[field] || fallbackInitialValues[field];

    initialValues['role'] =
      internalRolesToLegacyCustomerRoles[asyncInitialValues['role']] ||
      fallbackInitialValues['role'];
  });
  return initialValues;
};

export const createRoleOptions = t => [
  {
    value: 'Logistics / Operations',
    label: t('role-logistics-operations'),
  },
  { value: 'Admin', label: t('role-admin') },
  { value: 'Owner / Executive', label: t('role-owner-excutive') },
  { value: 'Sales / Marketing', label: t('role-sales-marketing') },
  {
    value: 'Accounting / Finance / Billing',
    label: t('role-accounting-finance-billing'),
  },
  { value: 'Warehouse', label: t('role-warehouse') },
  { value: 'Customer Support', label: t('role-customer-support') },
  { value: 'Business Development', label: t('role-business-development') },
  {
    value: 'Director / Manager / Supervisor',
    label: t('role-director-manager-supervisor'),
  },
];

const internalRolesToLegacyCustomerRoles = {
  main: 'Main',
  ops: 'Logistics / Operations',
  money: 'Accounting / Finance / Billing',
  executive: 'Owner / Executive',
  admin: 'Admin',
  sales: 'Sales / Marketing',
  warehouse: 'Warehouse',
  'customer-support': 'Customer Support',
  'business-development': 'Business Development',
  director: 'Director / Manager / Supervisor',
  other: 'Other',
};

export const validationSchema = ({ t, validatePassword }) =>
  yup.object().shape({
    firstName: yup
      .string()
      .min(2, '')
      .required(t('validation-firstname-required')),
    lastName: yup
      .string()
      .min(2, '')
      .required(t('validation-lastname-required')),
    companyName: yup
      .string()
      .min(2, '')
      .required(t('validation-companyname-required')),
    phoneNumber: yup.string().required(t('validation-phonenumber-required')),
    email: yup
      .string()
      .email(t('validation-invalid-email'))
      .required(t('validation-email-required')),
    password: yup
      .string()
      .required(t('valdiation-password-required'))
      .test('password-valdiation', true, validatePassword),
    title: yup.string().required(t('validation-title-required')),
    acceptTerms: yup.bool().oneOf([true], t('valdiation-acceptTerms-required')),
  });
