import * as yup from 'yup';

export const initialValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  password: '',
  acceptTerms: false,
  phoneNumber: '',
};

export const validationSchema = ({ t, validatePassword }) =>
  yup.object().shape({
    firstName: yup
      .string()
      .min(2, '')
      .required(t('validation-firstname-required')),
    lastName: yup
      .string()
      .min(2, '')
      .required(t('validation-lastname-required')),
    companyName: yup
      .string()
      .min(2, '')
      .required(t('validation-companyname-required')),
    phoneNumber: yup.string().required(t('validation-phonenumber-required')),
    email: yup
      .string()
      .email(t('validation-invalid-email'))
      .required(t('validation-email-required')),
    password: yup
      .string()
      .required(t('valdiation-password-required'))
      .test('password-valdiation', true, validatePassword),
    acceptTerms: yup.bool().oneOf([true], t('valdiation-acceptTerms-required')),
  });
