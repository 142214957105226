import { gql } from 'graphql-request';

export const NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation customerui_createNotificationSettings(
    $notificationSettings: [UserNotificationSettingsInput!]!
  ) {
    updateNotificationSettings(input: { attributes: $notificationSettings }) {
      success
    }
  }
`;
