import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@nuvocargo/nuvo-styleguide';
import { Link } from 'react-router-dom';

const FinanceHoldBanner = () => {
  const { t } = useTranslation();

  return (
    <Heading
      variant="h6"
      styles={{
        root: { margin: '4px 0 0 10px', fontSize: '16px', lineHeight: '24px' },
      }}
      color="white">
      <span>
        {`${t('finance-hold-message')}`}.{' '}
        <Link
          to="/billing"
          style={{ fontWeight: '200', textDecoration: 'underline' }}>
          {t('more-details')}
        </Link>
      </span>
    </Heading>
  );
};

export default FinanceHoldBanner;
