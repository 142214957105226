import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  TableProvider,
  TableWrapper,
} from '@nuvocargo/nuvo-styleguide';

import Layout from 'components/common/layout/authenticated';

import { RoutesTable } from '../components';

export default function RoutesIndex() {
  const { t } = useTranslation();

  return (
    <Layout paddingTop="0px" ignoreGrid ignoreBanner>
      <TableWrapper>
        <Heading variant="h3">{t('routes')}</Heading>
        <div className=" h-4 " />
        <TableProvider>
          <RoutesTable />
        </TableProvider>
      </TableWrapper>
    </Layout>
  );
}
