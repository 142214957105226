import { Iconography } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

const NoDocuments = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="shipment-details-documents-no-data"
      className="flex min-h-[240px] flex-col items-center justify-center gap-2 bg-nuvo-gray-dark/5  sm:min-h-[320px] ">
      <div>
        <Iconography
          name="file_notfound"
          styles={{ width: '112px', height: '112px' }}
        />
      </div>

      <h5
        data-testid="shipment-details-documents-no-data-title"
        className="font-value-sans-regular text-xl text-nuvo-green sm:text-2xl">
        {t('no-documents-available')}
      </h5>

      <p
        data-testid="shipment-details-documents-no-data-details"
        className="mb-8 font-value-sans-regular text-sm text-nuvo-steel sm:text-base">
        {t('no-documents-available-details')}
      </p>

      {children}
    </div>
  );
};

export default NoDocuments;
