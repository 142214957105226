import { useMutation, useQuery } from 'react-query';

import { useUser } from 'context/user';
import {
  createNotificationSettingsQL,
  fetchNotificationSettings,
} from 'core/api/notificationSettings';

export function useNotificationSettings({ category } = {}, queryConfig = {}) {
  const { currentUser } = useUser();
  const { data, error, isLoading, isFetching } = useQuery(
    ['notification_settings', category],
    () =>
      fetchNotificationSettings({
        userId: String(currentUser.id),
        category,
      }),
    queryConfig
  );

  return {
    error,
    isFetching,
    isLoadingNotificationSettings: isLoading,
    notificationSettings: data,
  };
}

export const useCreateNotificationSettings = queryConfig => {
  return useMutation(createNotificationSettingsQL, queryConfig);
};
