/**
 * FulLStory wrapper helper which adds constant events to the FullStory API
 */

import * as FullStory from '@fullstory/browser';

const events = {
  SHIPMENT_REQUESTED: 'New Shipment Requested',
};

export default {
  ...FullStory,
  events,
};
