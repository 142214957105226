import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useField } from 'formik';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';

import { Box } from 'components/generic/kit';
import { useWindowSize } from 'core/hooks';

import { isMobile as checkIsMobile } from '../../../../screens/onboarding/utils';

export default function QuoteCheckBox({ label, icon, description, ...props }) {
  const [field] = useField(props);
  const isSelected = field.value;
  const { width } = useWindowSize();
  const isMobile = checkIsMobile(width);
  return (
    <Wrapper>
      <Placeholder isSelected={isSelected}>
        <SvgWrapper>
          <Iconography name={icon} />
        </SvgWrapper>
        <Box styles={{ root: { textAlign: 'left' } }}>
          <Header>
            <Heading
              component="div"
              variant="h6"
              color="green"
              styles={{
                root: {
                  lineHeight: 1.33,
                  marginBottom: '0px',
                  fontSize: isMobile ? '14px' : '16px',
                  paddingTop: isMobile ? '' : '0.5em',
                },
              }}>
              {label}
            </Heading>
          </Header>
          <Text color="steel" size={isMobile ? 'extraSmall' : 'regular'}>
            {description}
          </Text>
        </Box>
      </Placeholder>

      <Checkbox type="checkbox" {...field} {...props} />
    </Wrapper>
  );
}

const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  svg {
    width: 100px;
    height: unset;
  }

  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.mobileL}) {
        margin-right: 12px;
        svg {
          width: 52px;
          height: unset;
        }
      }
    `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
`;

const Checkbox = styled.input`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
`;

const Placeholder = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  width: 100%;
  border: solid 1px #e4e2df;
  border-radius: 8px;
  text-align: center;
  padding: 22px 32px 22px 32px;
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      box-shadow: inset 0 0 0 3px ${theme.colors.forest};
      @media only screen and (max-width: ${theme.mediaSizes.tablet}) {
        padding: 16px 12px 22px;
      }
      @media only screen and (max-width: ${theme.mediaSizes.mobileL}) {
        padding: 16px 12px 22px;
        border: solid 2px ${theme.colors.forest};
      }
    `}

  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.tablet}) {
        padding: 16px 12px 22px;
      }
    `}
`;

const Header = styled.div`
  display: flex;
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.tablet}) {
        justify-content: space-between;
        align-items: center;
      }
    `}
`;
