import { isEmpty } from 'ramda';

import { theme } from 'styles';

export const isMobile = width => {
  return width <= theme.sizes.mobileL;
};

export const getHeadingVariant = width => {
  if (width <= theme.sizes.mobileL) {
    return 'h4';
  }

  if (width <= theme.sizes.sm) {
    return 'h2';
  }

  return 'h3';
};

export const isValidRoute = ({ origin, destination, loadsPerMonth }) =>
  !isEmpty(destination) && !isEmpty(origin) && !isEmpty(loadsPerMonth);
