import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import { GridColumn } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

import { StateCard } from 'components/common/app/StateCard';
import Layout from 'components/common/layout/authenticated';
import { useUser } from 'context/user';
import { app } from 'routes/paths';

export function ShipmentRequestSuccessScreen() {
  const { t } = useTranslation();
  const { currentUser } = useUser();

  return (
    <Layout>
      <GridColumn md={12}>
        <StateCard
          icon={
            <IconWrapper>
              <Iconography
                alt="truck icon"
                name="truck"
                styles={{ root: { width: '220px', height: '120px' } }}
              />
            </IconWrapper>
          }
          header={t('shipment-request-success-header', {
            firstName: currentUser.firstName,
          })}
          isHeaderOutstanding
          description={t('shipment-request-success-description')}
          primaryAction={{
            label: t('return-to-shipments'),
            props: {
              as: RouterLink,
              isLink: true,
              to: {
                pathname: app.shipments.root,
              },
            },
          }}
        />
      </GridColumn>
    </Layout>
  );
}

const IconWrapper = styled.div`
  > svg {
    width: 120px;
    height: 80px;
  }
`;
IconWrapper.displayName = 'IconWrapper';
