import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import NewRoute from 'components/common/layout/authenticated/new-route';
import { useNewRoutes } from 'context/routes';
import { app } from 'routes/paths';

import NewRoutesRequest from '../newRouteForm/newRoutesRequest';
import {
  validateLocations,
  initialValues,
} from '../newRouteForm/newRoutesRequest/data';

export default function NewRoutes() {
  const { t } = useTranslation();

  const { goTo, answers } = useNewRoutes();

  return (
    <NewRoute
      title={t('onboarding-routes-title')}
      subtitle={t('onboarding-we-send-quote-for-each-route')}>
      <Formik
        onSubmit={goTo(app.routes.new.commodity)}
        initialValues={{
          ...initialValues,
          routes: (answers.routes || []).concat(initialValues.routes),
          ...answers,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={validateLocations(t)}>
        {({ values, errors, setErrors }) => (
          <NewRoutesRequest
            values={values}
            errors={errors}
            setErrors={setErrors}
          />
        )}
      </Formik>
    </NewRoute>
  );
}
