import { useState } from 'react';
import polyline from '@mapbox/polyline';
import { useQuery } from 'react-query';

import * as directionsApi from 'core/api/directions';

const validCoordinates = coordinates =>
  Array.isArray(coordinates) && !!coordinates && coordinates.length >= 2;

const formattedCoords = coordinates => {
  return coordinates
    .map(coords => String(`${coords.lng},${coords.lat}`))
    .join(';');
};

const toGeoData = coords => {
  return {
    type: 'Feature',
    geometry: coords,
  };
};

const DESTINATION_ICON_URL = encodeURIComponent(
  `https://app.nuvocargo.com/BX0M-destination.png`
);

const CURRENT_ICON_URL = encodeURIComponent(
  `https://app.nuvocargo.com/XvD7-Current.png`
);

// const DESTINATION_ICON_URL = encodeURIComponent(
//   `https://uploads.codesandbox.io/uploads/user/b8446c41-ea2d-4b7a-941b-53e281560872/BX0M-destination.png`
// );

// const CURRENT_ICON_URL = encodeURIComponent(
//   `https://uploads.codesandbox.io/uploads/user/b8446c41-ea2d-4b7a-941b-53e281560872/XvD7-Current.png`
// );

export const useMapImage = ({ coordinates }) => {
  const [mapUrl, setMapUrl] = useState('');
  const origin = coordinates[0];
  const destination = coordinates[coordinates.length - 1];

  const { isLoading, error } = useQuery(
    ['fetchDirections', coordinates],
    () => {
      if (validCoordinates(coordinates)) {
        return directionsApi.getOptimizedTrips(formattedCoords(coordinates));
      } else {
        return Promise.reject('');
      }
    },
    {
      onSuccess: async ({ data }) => {
        // Precision is necessary for paths to work.
        data.trips[0].geometry.coordinates =
          await data.trips[0].geometry.coordinates.map(([item1, item2]) => [
            +item1.toPrecision(5),
            +item2.toPrecision(5),
          ]);
        const layerRoute = toGeoData(data.trips[0].geometry);
        const layerPath = await encodeURIComponent(
          polyline.fromGeoJSON(layerRoute)
        );
        const style = 'styles/nuvosam/ckud15d187ve818odkxq7lk4t';
        const size = '475x810';
        const markers = [
          `path-3+ddf2ff-1(${layerPath})`,
          `url-${CURRENT_ICON_URL}(${origin.lng},${origin.lat})`,
          `url-${DESTINATION_ICON_URL}(${destination.lng},${destination.lat})`,
        ];

        await setMapUrl(
          `https://api.mapbox.com/${style}/static/${markers.join(
            ','
          )}/auto/${size}?access_token=${
            directionsApi.TOKEN
          }&padding=475,100,100,100`
        );
      },
    }
  );

  return {
    staticMapUrl: mapUrl,
    isMapLoading: isLoading,
    mapError: error,
  };
};
