import { formatISODateTime, formatShortDateTime } from 'core/utils/date-fns';
import { centsToDollars, formatMoney } from 'core/utils/lib';

import { getShipmentEventByType } from './utils';

export const formatInsights = insights =>
  insights.map(insight => {
    const crossedBorderDate = getShipmentEventByType(
      insight.shipmentEvents,
      'CROSSED_BORDER'
    )?.node?.actualDatetime;

    const deliveredToDestinationDate = getShipmentEventByType(
      insight.shipmentEvents,
      'DELIVERED_TO_DESTINATION'
    )?.node?.actualDatetime;

    const pickedUpFromOriginDate = getShipmentEventByType(
      insight.shipmentEvents,
      'PICKED_UP_FROM_ORIGIN'
    )?.node?.actualDatetime;

    return {
      id: insight.id,
      folioNumber: insight.folioNumber,
      borderCrossingTime:
        insight.borderCrossingTime !== null && crossedBorderDate
          ? formatShortDateTime(crossedBorderDate)
          : '',
      deliveryTime:
        insight.deliveryTime !== null && deliveredToDestinationDate
          ? formatShortDateTime(deliveredToDestinationDate)
          : '',
      unformattedDeliveryTime: deliveredToDestinationDate,
      unformattedPickupTime: pickedUpFromOriginDate,
      pickUpTime:
        insight.pickUpTime !== null && pickedUpFromOriginDate
          ? formatShortDateTime(pickedUpFromOriginDate)
          : '',
      costDisplay:
        insight.currency === 'MXN'
          ? `MXN$${formatMoney(insight.priceInCents || 0)}`
          : formatMoney(insight.priceInCents || 0),
      shipmentEvents: insight.shipmentEvents,
      priceInCents: insight.priceInCents,
    };
  });

export const formatCSVModel = item => {
  const crossedBorderDate = getShipmentEventByType(
    item.shipmentEvents,
    'CROSSED_BORDER'
  )?.node?.actualDatetime;

  const deliveredToDestinationDate = getShipmentEventByType(
    item.shipmentEvents,
    'DELIVERED_TO_DESTINATION'
  )?.node?.actualDatetime;

  const pickedUpFromOriginDate = getShipmentEventByType(
    item.shipmentEvents,
    'PICKED_UP_FROM_ORIGIN'
  )?.node?.actualDatetime;

  return [
    `SH-${item.id}`,
    pickedUpFromOriginDate && pickedUpFromOriginDate !== null
      ? formatISODateTime(pickedUpFromOriginDate)
      : '',
    crossedBorderDate && crossedBorderDate !== null
      ? formatISODateTime(crossedBorderDate)
      : '',
    deliveredToDestinationDate && deliveredToDestinationDate !== null
      ? formatISODateTime(deliveredToDestinationDate)
      : '',
    item.currency === 'USD'
      ? formatMoney(centsToDollars(item.priceInCents))
      : '',
    item.currency === 'MXN'
      ? `MXN$${formatMoney(centsToDollars(item.priceInCents))}`
      : '',
    item.origin?.name ?? '',
    item.origin?.zipCode?.parents?.city ?? '',
    item.origin?.zipCode?.parents?.state ?? '',
    item.destination?.name ?? '',
    item.destination?.zipCode?.parents?.city ?? '',
    item.destination?.zipCode?.parents?.state ?? '',
  ];
};

export const csvColumns = [
  'Reference #',
  'Picked up',
  'Crossed border',
  'Delivered',
  'Costs USD',
  'Costs MXN',
  'Origin name',
  'Origin city',
  'Origin state',
  'Destination name',
  'Destination city',
  'Destination state',
];
