import React from 'react';
import styled, { css } from 'styled-components';

export default function Hidden({
  sm,
  md,
  position = 'relative',
  display = 'flex',
  width,
  top,
  bottom,
  right,
  children,
}) {
  return (
    <Container
      sm={sm}
      md={md}
      display={display}
      width={width}
      position={position}
      top={top}
      bottom={bottom}
      right={right}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  ${({ sm, theme, position }) =>
    sm &&
    position &&
    css`
      position: ${position};
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        display: none;
      }
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ top }) =>
    top &&
    css`
      top: ${top};
    `}
  ${({ right }) =>
    right &&
    css`
      right: ${right};
    `}
  ${({ bottom }) =>
    bottom &&
    css`
      bottom: ${bottom};
    `}
  ${({ md, theme, display, position }) =>
    md &&
    display &&
    position &&
    css`
      display: none;
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        display: ${display};
        position: ${position};
      }
    `}
`;
