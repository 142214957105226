import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import { CTAButton, Heading, Label } from '@nuvocargo/nuvo-styleguide';
import { Toggle as NativeToggle } from '@nuvocargo/nuvo-styleguide/forms/native';
import { Toggle } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { theme } from 'styles';
import { Spinner } from 'components/generic/kit';

import {
  useNotificationSettings,
  useCreateNotificationSettings,
} from './hooks';

const Notifications = () => {
  const { t } = useTranslation();
  const { mutate } = useCreateNotificationSettings();
  const queryClient = useQueryClient();
  const matchesSize = useMediaQuery(`(min-width: ${theme.mediaSizes.laptop})`);

  const [notificationsEnabled, setNotificationsEnabled] = useState(true);

  const { isLoadingNotificationSettings, notificationSettings } =
    useNotificationSettings(
      { category: 'shipment-events' },
      {
        select: data => data.sort((a, b) => parseInt(a.id) - parseInt(b.id)),
      }
    );

  if (isLoadingNotificationSettings) {
    return (
      <div className="flex h-96 flex-col rounded p-8">
        <Spinner />
      </div>
    );
  }

  const onChangeUpdateNotification = () =>
    setNotificationsEnabled(!notificationsEnabled);

  function handleOnSubmit(values) {
    mutate(
      {
        notificationSettings: values.map(({ id: notificationId, enabled }) => ({
          notificationId,
          enabled: notificationsEnabled && enabled,
        })),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'notification_settings',
            'shipment-events',
          ]);
          toast.success(t('accounts-settings-saved-successfully'));
        },
      }
    );
  }

  return (
    <Formik
      initialValues={notificationSettings.map(
        ({ enabled, id, notificationSettingForUser }) => ({
          id,
          enabled: notificationSettingForUser
            ? notificationSettingForUser.enabled
            : enabled,
        })
      )}
      onSubmit={handleOnSubmit}>
      {({ values, setValues, handleSubmit }) => (
        <form className="" onSubmit={handleSubmit}>
          <div className="flex flex-col rounded p-8">
            <div className="flex flex-col justify-start gap-10 nuvo-sm:flex-row nuvo-sm:gap-20">
              <div className="flex-2 max-w-sm gap-y-0.5 nuvo-sm:mr-32 nuvo-laptop:mr-0">
                <Heading variant="h5">{t('settings')}</Heading>
                <Label color="steel">{t('notifications-settings-label')}</Label>
              </div>

              <div className="mt-4 flex flex-1 flex-col items-start gap-4">
                <div className="flex w-full flex-1 flex-row justify-evenly pb-8 nuvo-sm:pb-10">
                  <div className="flex w-full flex-1 flex-col gap-2">
                    <Heading variant="h6" color="green">
                      {t('updates')}
                    </Heading>
                    <Label color="steel">
                      {t('general-updates-notification')}
                    </Label>
                  </div>
                  <NativeToggle
                    size="medium"
                    label=""
                    checked={notificationsEnabled}
                    onCheckedChange={onChangeUpdateNotification}
                  />
                </div>
                <div className="mb-4">
                  <Heading variant="h6" color="green">
                    {t('shipment-events-notifications')}
                  </Heading>
                  <Label color="steel">
                    {t('general-shipment-events-label-notification')}
                  </Label>
                </div>
                {notificationSettings.map((item, idx) => {
                  return (
                    <div
                      className="flex w-full flex-1 flex-row justify-evenly pb-2"
                      key={item.id}>
                      <div className="flex w-full flex-1 flex-col">
                        <Heading
                          variant="h6"
                          color="green"
                          styles={{ root: { fontSize: '16px' } }}>
                          {item.description}
                        </Heading>
                      </div>
                      <Toggle
                        size="small"
                        label=""
                        name={`${idx}.enabled`}
                        disabled={!notificationsEnabled}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="nuvo-sm:mt-12 nuvo-sm:w-full nuvo-sm:border-t"></div>
            <div className="mt-12 self-center nuvo-sm:mt-4 nuvo-sm:self-end">
              <CTAButton
                id="settings-save-button"
                disable={false}
                type="submit"
                styles={{
                  primary: {
                    width: matchesSize ? '' : '224px',
                  },
                }}>
                {t('save-settings-button')}
              </CTAButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Notifications;
