import React from 'react';
import { Formik, Form } from 'formik';
import { useMutation, useQuery } from 'react-query';
import {
  Grid,
  GridColumn,
  CTAButton,
  Heading,
  Text,
  usePageHeader,
} from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { Password } from '@nuvocargo/nuvo-styleguide/forms/formik';
import {
  MIN_LENGTH,
  SPECIAL_CHARACTERS,
  NUMBERS,
  validatePassword as initValidatePassword,
} from '@nuvocargo/nuvo-styleguide/forms/native/Password/validations';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { ErrorMessage } from 'components/generic/form';
import { Spinner } from 'components/generic/kit';
import * as api from 'core/api/user';

import { validationSchema, initialValues } from './data';

const validatePassword = initValidatePassword({ minLength: 8 });

export default function ResetPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { token } = qs.parse(search, { ignoreQueryPrefix: true });
  const verifyTokenQuery = useQuery(
    ['/password/token', token],
    () => api.verifyToken({ token }),
    {
      onError: () => {
        history.push('/password/forgot?invalidToken=true');
      },
      retry: false,
    }
  );
  const { showBanner } = usePageHeader();
  const resetPasswordMutation = useMutation(api.resetPassword, {
    onSuccess: () => {
      showBanner({
        children: t('password-reset-successful'),
        type: 'success',
        show: true,
      });

      setTimeout(() => {
        history.replace('/');
      }, 3000);
    },
  });

  const resetPasswordRequest = ({ password }) => {
    resetPasswordMutation.mutate({ token, password });
  };

  return verifyTokenQuery?.isLoading ? (
    <Spinner />
  ) : (
    <Grid
      styles={{
        root: {
          alignItems: 'center',
          gridTemplateRows: '100vh',
        },
      }}>
      <GridColumn startMd={5} md={4} sm={4}>
        <Header>
          <Heading variant="h3" color="green">
            {t('reset-password-title')}
          </Heading>
          <Text color="steel">{t('reset-password-subtitle')}</Text>
        </Header>
        <Formik
          validationSchema={validationSchema({ t, validatePassword })}
          initialValues={initialValues}
          onSubmit={resetPasswordRequest}>
          <Form>
            <Row>
              <Password
                label={t('password')}
                name="password"
                placeholder={t('placeholder-password')}
                useInternalValidation
                overrideValidationMessages={{
                  [MIN_LENGTH]: t('password-validation-min-length'),
                  [SPECIAL_CHARACTERS]: t(
                    'password-validation-special-characters'
                  ),
                  [NUMBERS]: t('password-validation-number'),
                }}
              />
            </Row>
            <Row>
              <Password
                label={t('confirm-password')}
                name="confirmedPassword"
                placeholder={t('placeholder-confirm-password')}
              />
            </Row>
            {resetPasswordMutation.error?.data?.error && (
              <Row>
                <ErrorMessage backgroundColor="taupe">
                  {resetPasswordMutation.error.data.error}
                </ErrorMessage>
              </Row>
            )}
            <Row alignCenter>
              <CTAButton
                type="submit"
                disabled={resetPasswordMutation.isLoading}>
                {t('reset-password-btn')}
              </CTAButton>
            </Row>
          </Form>
        </Formik>
      </GridColumn>
    </Grid>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  & > * + * {
    margin-top: 16px;
  }
`;

const Row = styled.div`
  margin: ${({ verticalSpacing }) =>
      verticalSpacing ? verticalSpacing : '40px'}
    0;
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`;
