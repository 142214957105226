import React from 'react';
import { CSVLink } from 'react-csv';
import {
  CTAButton,
  Heading,
  Eyebrow,
  Text,
  Icon,
  GridColumn,
  SelectBox,
} from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

import { centsToDollars, formatMoney } from 'core/utils/lib';
import { InsightsBlock } from 'components/generic/kit';
import Layout from 'components/common/layout/authenticated';

import { useInsights } from './hooks';
import { durationFilters } from './utils';
import { links } from './data';
import Table from './table';
import * as styles from './styles';

export default function Insights() {
  const {
    filteredInsights,
    analytics,
    selectedDate,
    onFilterChange,
    percentages,
    csvData,
    isLoading,
  } = useInsights();
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('reference-#-column')}
              size="small"
              color="grey"></Eyebrow>
          );
        },
        accessor: 'folioNumber',
        Cell: row => <Text size="large">{row.cell.value}</Text>,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('cost-column')}
              size="small"
              color="grey"></Eyebrow>
          );
        },
        accessor: 'priceInCents',
        Cell: ({ value }) => (
          <Text size="large">
            {value !== 0
              ? formatMoney(centsToDollars(value), { minimumFractionDigits: 2 })
              : t('insights-table-cell-cost-n/a')}
          </Text>
        ),
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('picked-up-column')}
              size="small"
              color="grey"></Eyebrow>
          );
        },
        accessor: 'pickUpTime',
        Cell: row => <Text size="large">{row.cell.value}</Text>,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('border-crossed-column')}
              size="small"
              color="grey"></Eyebrow>
          );
        },
        accessor: 'borderCrossingTime',
        Cell: row => <Text size="large">{row.cell.value}</Text>,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('delivered-column')}
              size="small"
              color="grey"></Eyebrow>
          );
        },
        accessor: 'deliveryTime',
        Cell: row => <Text size="large">{row.cell.value}</Text>,
      },
      {
        accessor: 'shipmentEvents',
        Cell: () => <Icon name="arrowForward" color="darkGrey"></Icon>,
      },
    ],
    [t]
  );

  return (
    <Layout links={links}>
      <GridColumn md={12} sm={4} styles={{ root: { width: '-moz-available' } }}>
        <styles.FilterBar>
          <styles.Right>
            <Heading variant="h3" color="green">
              {t('insights')}
            </Heading>
            <span data-testid="insights-range-selector">
              <SelectBox
                value={Object.values(durationFilters(t)).find(
                  ({ value }) => value === selectedDate
                )}
                options={Object.values(durationFilters(t))}
                onChange={x => onFilterChange(x.value)}
                size="small"
              />
            </span>
          </styles.Right>
          {csvData && (
            <CSVLink
              data={csvData}
              filename={`shipments-${new Date().toISOString()}.csv`}
              target="_blank">
              <CTAButton
                mode="light"
                colorType="secondary"
                size="small"
                icon="fileDownload"
                shape="block">
                CSV
              </CTAButton>
            </CSVLink>
          )}
        </styles.FilterBar>
        <styles.StyledBlocks>
          <InsightsBlock
            displayCondition={selectedDate === 'oneMonth'}
            percentageChange={percentages.loadsChange}
            title={t('shipments-counts-title')}
            result={`${analytics.totalShipments} ${t('loads')}`}
          />
          <InsightsBlock
            displayCondition={selectedDate === 'oneMonth'}
            percentageChange={percentages.avgTimeChange}
            title={t('avg-time-in-transit-title')}
            result={`${analytics.averageTimeTransit.toFixed(2)} ${t('days')}`}
          />
          <InsightsBlock
            displayCondition={selectedDate === 'oneMonth'}
            percentageChange={percentages.avgCostChange}
            title={t('avg-cost-per-shipment-title')}
            result={formatMoney(centsToDollars(analytics.averageCost), {
              minimumFractionDigits: 2,
            })}
          />
        </styles.StyledBlocks>
        <styles.StyledTable>
          <Table
            columns={columns}
            data={filteredInsights}
            isLoading={isLoading}
          />
        </styles.StyledTable>
      </GridColumn>
    </Layout>
  );
}
