import styled from 'styled-components';
import { Form } from 'formik';

import { theme } from 'styles';
import { MovingTruck } from 'components/generic/kit';

const LAYOUT_SHIFT_MEDIA_QUERY = `@media only screen and ${theme.device.tablet}`;
const LAYOUT_MOBILE_MEDIA_QUERY = `@media only screen and (max-width: ${theme.mediaSizes.mobileL})`;
export const TEXT_INPUT_STYLES = {
  root: {
    width: '316px',
  },
  input: {
    [LAYOUT_MOBILE_MEDIA_QUERY]: {
      height: '36px',
      width: '100%',
    },
  },
};

export const FormWrapper = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 3em;
  ${LAYOUT_MOBILE_MEDIA_QUERY} {
    margin-top: 2.25em;
  }
`;

export const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-width: 650px;

  ${LAYOUT_SHIFT_MEDIA_QUERY} {
    justify-content: space-evenly;
  }

  ${LAYOUT_MOBILE_MEDIA_QUERY} {
    flex-direction: column;
    align-items: stretch;
    min-width: 300px;
  }
`;

export const FlexSpacer = styled.div`
  width: 100%;
  margin-top: ${({ isFirst }) => (isFirst ? '0px' : '24px')};
  text-align: left;

  ${LAYOUT_MOBILE_MEDIA_QUERY} {
    ${({ isFirst }) => {
      if (isFirst) {
        return `
          margin-top: 0px;

          // The second entry of first child should still have its margin when
          // we go into the tablet layout
          &:nth-child(even) {
            margin-top: 1em;
          }
        `;
      }
      return `margin-top: 1em`;
    }}
  }

  &:nth-child(odd) {
    margin-right: 24px;

    ${LAYOUT_MOBILE_MEDIA_QUERY} {
      margin-right: 0px;
    }
  }
`;

export const Truck = styled(MovingTruck)`
  position: absolute;
  top: -10px;
`;
