import { curry, mergeDeepLeft } from 'ramda';
import React, { createContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useCheckInvite } from 'core/hooks';

export const Context = createContext();

export const Provider = ({ children }) => {
  const [answers, setAnswers] = React.useState({});
  // OnBoardingInvite is called in the context once, to remove unnecessary rerenders
  const { error, senderInfo, isLoading, type } = useCheckInvite();
  const history = useHistory();
  const value = React.useMemo(() => {
    const goTo = curry((route, newAnswers) => {
      setAnswers(mergeDeepLeft(newAnswers));
      history.push({
        pathname: route,
        search: history.location.search,
      });
    });
    return {
      answers,
      inviteResponse: {
        isLoading,
        error,
        senderInfo,
        isTokenExpired:
          (type && type !== 'company_onboarding') || error?.data?.error,
      },
      goTo,
      resetOnboardingProcess: () => setAnswers({}),
    };
  }, [answers, isLoading, error, senderInfo, type, history]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useOnboarding() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(`useOnboarding must be used within a OnboardingProvider`);
  }
  return context;
}
